import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PortalTooltip from '../../components/portal-tooltip';
import {
  ChartLabelLargeWrp,
  ChartLabelSmallWrp,
} from '../../constants/graph-colors/index.sc';
import { addCountPrefix } from '../../constants/utils';

const Tooltip = styled.div`
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 8px;
  pointer-events: none;
  z-index: 10;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
`;

const MIN_BOX_SIZE = 40;

const TreemapCampaign = ({
  data: widget,
  handleOnClick,
  onResetSelection,
  config,
  chartId,
}) => {
  const data = Array.isArray(widget)
    ? widget
    : Array.isArray(widget?.data)
    ? widget?.data
    : [];
  const [selected, setSelected] = useState(null);
  const [tooltip, setTooltip] = useState({
    visible: false,
    x: 0,
    y: 0,
    content: '',
    data: null,
  });
  const svgRef = useRef();
  const containerRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoveredLabel, setHoveredLabel] = useState(null);
  const [adjustedX, setAdjustedX] = useState(tooltip.x);
  const tooltipRef = useRef(null);

  // Get configuration values with defaults
  const showShadows = false;
  const showCornerRadius = false;

  useEffect(() => {
    if (tooltipRef.current && showTooltip && tooltip.visible) {
      setTimeout(() => {
        const tooltipWidth = tooltipRef?.current?.offsetWidth;
        const containerRect = containerRef?.current?.getBoundingClientRect();

        const newX =
          tooltip.x + tooltipWidth > containerRect?.right
            ? containerRect.right - (tooltipWidth + 50)
            : tooltip.x;

        setAdjustedX(newX);
      }, 0);
    }
  }, [tooltip, showTooltip, dimensions.width]);

  useEffect(() => {
    const handleResize = () => {
      if (!containerRef?.current) return;

      const width = containerRef.current.clientWidth;
      const containerHeight = 336;
      const summaryHeight = Number(config?.summaryContainerHeight) || 0;

      const height = containerHeight - summaryHeight;

      setDimensions({ width, height });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [config?.summaryContainerHeight]);

  useEffect(() => {
    const { width, height } = dimensions;

    if (width === 0 || height === 0) return;

    const limitedData = data?.slice(0, 10);

    const padding = showShadows ? 14 : 4;
    const treemap = d3
      .treemap()
      .size([width - 10, height - padding * 0.6])
      .padding(3)
      .paddingLeft(padding)
      .round(true);

    const root = d3.hierarchy({ children: limitedData }).sum((d) => d.value);

    treemap(root);

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    svg
      .attr('width', width)
      .attr('height', height)
      .style('font', '10px sans-serif');

    const cell = svg
      .selectAll('g')
      .data(root.leaves())
      .join('g')
      .attr('transform', (d) => `translate(${d.x0},${d.y0})`)
      .attr('class', 'node')
      .on('click', (event, d, i) => {
        setShowTooltip(false);
        setSelected(d.data.label);
        setHoveredLabel(d.data.label);
        handleOnClick(data[i], d, chartId);
      })
      .on('mousemove', (event, d) => {
        setShowTooltip(true);
        setHoveredLabel(d.data.label);
        setTooltip({
          visible: true,
          x: event.pageX + 5,
          y: event.pageY - 10,
          content: `${d.data.label}: ${d.data.value}`,
          data: d?.data,
        });
      })
      .on('mouseout', () => {
        setShowTooltip(false);
        setHoveredLabel(null);
        setTooltip({ visible: false, x: 0, y: 0, content: '', data: null });
      });

    cell
      .append('rect')
      .attr('data-id', (d) => d.data.label)
      .attr('id', (d) => d.data.label?.split(' ').join(''))
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0)
      .attr('fill', (d) => d.data.color)
      .attr('rx', 0)
      .attr('ry', 0)
      .style('cursor', 'pointer')
      .attr('class', 'treemap-rect');

    cell
      .append('clipPath')
      .attr('id', (d) => `clip-${d.data.label?.split(' ').join('')}`)
      .append('use')
      .attr('xlink:href', (d) => `#${d.data.label?.split(' ').join('')}`);

    cell
      .append('text')
      .attr('clip-path', (d) => `url(#clip-${d.data.label})`)
      .attr('fill', 'white')
      .style('font-family', 'Inter')
      .style('font-size', '12px')
      .style('font-weight', 600)
      .style('line-height', '14px')
      .style('letter-spacing', '-0.01em')
      .style('text-anchor', 'middle') // Center text horizontally
      .each(function (d) {
        const boxWidth = d.x1 - d.x0;
        const boxHeight = d.y1 - d.y0;
        // Check if box is too small to display text
        if (boxWidth < MIN_BOX_SIZE || boxHeight < MIN_BOX_SIZE) {
          return;
        }
        const centerX = boxWidth / 2;
        const centerY = boxHeight / 2;

        // Calculate available space
        const rectWidth = boxWidth - 16;
        const fullText = d.data.label;
        const ellipsis = '...';
        let truncatedText = fullText;

        const tempText = d3
          .select(svgRef.current)
          .append('text')
          .attr('x', -9999)
          .attr('y', -9999)
          .style('font-family', 'Inter')
          .style('font-size', '12px')
          .text(fullText);

        // Truncate text if necessary
        if (tempText.node().getComputedTextLength() > rectWidth) {
          for (let i = fullText.length - 1; i > 0; i--) {
            truncatedText = fullText.slice(0, i) + ellipsis;
            tempText.text(truncatedText);
            if (tempText.node().getComputedTextLength() <= rectWidth) {
              break;
            }
          }
        }

        tempText.remove();

        // Add label text
        const textElement = d3.select(this);
        textElement
          .append('tspan')
          .attr('x', centerX)
          .attr('y', centerY - 10) // Offset up from center
          .text(capitalizeWords(truncatedText));

        // Add value below label
        const articleCount = d?.data?.value?.toLocaleString();
        textElement
          .append('tspan')
          .attr('x', centerX)
          .attr('y', centerY + 0) // Offset down from center
          .attr('dy', '1.2em')
          .style('font-size', '13px')
          .text(articleCount);

        // Check if text fits in box height
        const textHeight = this.getBBox().height;
        if (textHeight > boxHeight - 20) {
          // If text is too tall, remove second line and adjust single line position
          textElement.selectAll('tspan').remove();
          textElement
            .append('tspan')
            .attr('x', centerX)
            .attr('y', centerY)
            .style('font-size', '12px')
            .style('fill', 'white')
            .text(capitalizeWords(truncatedText));
        }
      });

    const container = containerRef.current;
    container.style.height = `${height}px`;
  }, [
    data,
    handleOnClick,
    dimensions,
    onResetSelection,
    showShadows,
    showCornerRadius,
  ]);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    if (hoveredLabel) {
      svg
        .selectAll('.treemap-rect')
        .classed(
          'faded',
          (d) => d.data.label !== hoveredLabel && d.data.label !== hoveredLabel
        );
    } else {
      svg.selectAll('.treemap-rect').classed('faded', false);
    }
  }, [hoveredLabel]);

  function capitalizeWords(text) {
    if (text == null) {
      return '';
    }
    return text
      .split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  return (
    <div ref={containerRef} style={{ width: '100%', height: '95%' }}>
      <svg ref={svgRef} style={{ overflow: 'visible !important' }}></svg>
      {showTooltip && tooltip.visible && (
        <PortalTooltip
          isOpen={true}
          pos={{ left: adjustedX, top: tooltip.y }}
          align={'right'}
          vAlign={'bottom'}
          isAlign={true}
          style={{ overflow: 'visible' }}
          boxShadow="0px 8px 20px 0px rgba(0, 0, 0, 0.12)"
          ref={tooltipRef}
        >
          <Tooltip>
            <ChartLabelSmallWrp
              style={{
                color: tooltip?.data?.labelColor,
                paddingBottom: '0.3rem',
              }}
            >
              {capitalizeWords(tooltip?.data?.label)}
            </ChartLabelSmallWrp>
            <ChartLabelLargeWrp style={{ color: 'black' }}>
              {tooltip?.data?.value?.toLocaleString('en-US')}
            </ChartLabelLargeWrp>
          </Tooltip>
        </PortalTooltip>
      )}
    </div>
  );
};

TreemapCampaign.propTypes = {
  data: PropTypes.array,
  handleOnClick: PropTypes.func,
  onResetSelection: PropTypes.bool,
  config: PropTypes.object,
  chartId: PropTypes.string,
};

export default TreemapCampaign;

// import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
// import Spinner from '../spinner';
import PropTypes from 'prop-types';
import DashboardSectionComponent from '../search-result/dashboard-section';
import ArticleSectionComponent from '../search-result/article-section';
import {
  ArticleSectionComponentWrp,
  Button,
  IconWrapper,
  Label,
  NotificationContainer,
  Overlay,
  ProgressBar,
  SearchMainWrp,
  SearchResultWrp,
  Switch,
  UserStoryLoaderWrp,
} from './index.sc';
import XCirlcle from '../../assets/icons/XCirlcle';
import { useNavigate, useParams } from 'react-router-dom';
import AdvancedDashboard from '../advanced-dashboard';
import { useMutation } from '@tanstack/react-query';
import {
  // handleSearchPost,
  handleSearchPostV1,
  useSearchFilterData,
  handleSearchSummary,
  downloadGraphData,
  handlePeopleSummary,
  handleIndustrySummary,
  handleBrandSummary,
  handleBrandCompetitionSummary,
  handleCustomSummary,
} from '../../hooks/useSearch';
import { useSelector, useDispatch } from 'react-redux';
import { getTokenData } from '../../constants/validateToken';
import { getPayloadForSearch } from '../../constants/dashboards/dashboardUtils';
import {
  convertTimeHours,
  getDashboardSearchQuery,
  getMediaCountDataUTIL,
  getStartAndEndDate,
  wrapInDoubleQuotes,
} from '../../constants/utils';
import { setInput } from '../../redux/slices/searchInputSlice';
import {
  dashboardCharts,
  allDashboards,
  authorSummaryKey,
  convertToBody,
} from '../../pages/new-dashboard/utils';
import { encloseWordsInDoubleQuotes } from '../../hooks/usePeopleCharts';
import DashboardPopup from '../dasboard-popup';
import DownloadArticlePopup from '../download-articles-popup';
import MultiTagPopup from '../multi-tag-popup';
import Timer from '../timer';
import { useGetSavedDashboardQueryData } from '../../hooks/useSaveDashboard';
import toast from 'react-hot-toast';
import ChartToolTip from '../chart-tool-tip';
import { theme } from '../../constants/theme';
import RefreshIcon from '../../assets/icons/RefreshIcon';
import { formatDate, formatTimeFromDate, getSearchParams } from '../../utils';
import CustomizeParameters from '../customize-parameters';
import { format, parse } from 'date-fns';
import { trackEvent } from '../../utils/mixPanel';
import { Loader } from '../search-result/dashboard-section/dashboard-header-v2/index.sc';
// import {convertTimeHours} from '../../../'

const articleTypeDefault = {
  widget: undefined,
  graphSelection: undefined,
  rawData: null,
};

export const dashboardConfig = {
  standard: ['brand', 'industry', 'people'],
  advanced: [
    'campaign',
    'authorimpact',
    'sentiments',
    'congruence',
    'primpact',
    'socialCampaign',
    'contentDashboard',
    'storyAnalysis',
  ],
};

const ArticleGraph = ({
  callAIInsight,
  setSummaryData,
  setSummaryLoading,
  summaryData,
  summaryLoading,
  handleSelectedChartDropdown,
  dashboardKeywords,
  selectedChartDropdown,
  totalCount,
  resultsShown,
  resetTimer,
  recentSearchId,
  tileDetails,
  dashboardDetails,
  filterType,
  advancedWidgetDetails,
  customCanvas,
  accessTypeViewUser = false,
  volumeAnalysisWidgetDetails,
  brandEngagementMetrics,
  sentimentAnalysisWidgetDetails,
  sentimeOverTimeWidgetDetails,
  seasonalTrendsWidgetDetails,
  topJournalistSentimentAnalysisWidgetDetails,
  coverageOverTimeBrandWidgetDetails,
  coverageOverTimeBrandSocialWidgetDetails,
  reachOvertimeBrandWidgetDetails,
  topPerformingPostsBrandWidgetDetails,
  reachOvertimeBrandSocialWidgetDetails,
  SOVWidgetDetails,
  mediaContributorsCompWidgetDetails,
  coverageByWebsiteCompWidgetDetails,
  peopleCoverageChartDataWidgetDetails,
  peopleCoverageSocialChartDataWidgetDetails,
  peopleThemeWidgetDetails,
  peopleMediaTypeWidgetDetails,
  peopleCoverageOnKeyTopicsChartDetails,
  peopleTopScoreDetails,
  coverageOverTimeCompWidgetDetails,
  coverageOverTimeSocialCompWidgetDetails,
  mediaTypeWidgetDetails,
  peopleVolumeAnalysisWidgetDetails,
  peopleVolumeComparisonData,
  peopleImpactWidgetDetails,
  peopleReachOverTimeComparisonData,
  peopleSentimentAnalysisWidgetDetails,
  compMentionedWidgetDetails,
  brandCompMentionedWidgetDetails,
  brandSocialWordCloudWidgetDetails,
  brandResultOverTimeWidgetDetails,
  sentimentCompWidgetDetails,
  reachOvertimeCompWidgetDetails,
  reachOvertimeSocialCompWidgetDetails,
  mediaBreakdownWidgetDetails,
  journalistWidgetDetails,
  sourceCompWidgetDetails,
  placeholderGraphs,
  searchedQuery,
  handleGraphTitleUpdate,
  searchFilters,
  customData,
  storeSelectedItems,
  industryVolumeAnalysisWidgetDetails,
  industrySentimentWidgetDetails,
  industryCoverageOverTimeWidgetDetails,
  industryCoverageSocialOverTimeWidgetDetails,
  industryCoverageBySourceWidgetDetails,
  industryCompaniesWidgetDetails,
  industryCoverageByJournalistsWidgetDetails,
  industryTopInfluencerWidgetData,
  industryPublicationsWidgetDetails,
  storyAnalysisResultOverTimeWidgetDetails,
  storyAnalysisMediaTypeWidgetDetails,
  sentimeWidgetDetails,
  topThemeWidgetDetails,
  wordCloudWidgetDetails,
  topSourceWidgetDetails,
  topInfluencerWidgetDetails,
  outletMediaTypeWidgetDetails,
  geographicalWidgetDetails,
  isSavePopup,
  setNotifyOnceComplete,
  notifyOnceComplete,
  onDownloadChartData,
  lastUpdateStripFlag,
  setLastUpdateStripFlag,
  stripText,
  storyPercentage = '',
  setStripText,
  isDocDownloading = false,
  sentimeOverTimeSocialWidgetDetails,
  dashboardDataById = {},
  showSaved = false,
  savedSelectedChart,
  peopleTopThemesWidgetDetails,
}) => {
  const dispatch = useDispatch();
  // const [activeScreen, setActiveScreen] = useState('dashboard');
  const authInfo = getTokenData();
  // const [query, setQuery] = useState(searchedQuery || '');
  const [floatingPagination, setfloatingPagination] = useState(false);
  const [articlePosition, setArticlePosition] = useState('');
  const [resetSelection, setResetSelection] = useState(true);
  const [selected, setSelected] = useState(null);
  const [bookmarked, setBookmarked] = useState(false);
  const [inSearchArticles, setInSearchArticles] = useState(null);
  const [inSearchTotalData, setInSearchTotalData] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [inSearchSortOrder, setInsearchSortOrder] = useState('');
  const [inSearchPageNum, setInsearchPageNum] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [isCustomPagiNationFlag, setIsCustomPagiNationFlag] = useState(false);
  const [inSearchPaging, setInSearchPaging] = useState({
    pageNumber: 1,
    pageSize: 50,
    total: 0,
  });

  const [resetSelected, setResetSelected] = useState(false);
  const [checked, setChecked] = useState([]);
  const [downloadSelected, setDownloadSelected] = useState('');
  const [downloadArticleFlag, setDownLoadArticleFlag] = useState(false);
  const [fetchAiSummary, setFetchAiSummary] = useState(false);
  const [hiddenArticleSwitch, setHiddenArticleSwitch] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  // const [loader, setLoader] = useState(false);
  // const [guidedSearch, setGuidedSearch] = useState({
  //   all: '',
  //   none: '',
  //   any: '',
  // });
  // const [value, setValue] = useState('');
  const activeScreen = 'dashboard';
  const loader = false;
  // const queryClient = useQueryClient();
  const [type, setType] = useState('totalArticles');
  const navigate = useNavigate();
  const [downloadFilterData, setDownloadFilterData] = useState();

  // const onBtnClick = (screen) => {
  //   setLoader(true);
  //   setActiveScreen(activeScreen === '' ? screen : '');
  //   setTimeout(() => {
  //     setLoader(false);
  //   }, 500);
  // };
  const x = dashboardDetails.length;
  const [selectGraph, setSelectedGraph] = useState(new Array(x).fill(true));
  const [triggerInSearchFetchData, setTriggerInSearchFetchData] =
    useState(false);

  useEffect(() => {
    if (selected == null) {
      setSelectedGraph(new Array(x).fill(true));
    }
  }, [x, selected]);
  // const [page, setPage] = useState(0);
  const paginationWidth = '45vw';
  const [articleType, setArticleType] = useState(articleTypeDefault);
  const [syndicationType, setSyndicationType] = useState({
    ...articleTypeDefault,
  });
  const [storeComments, setStoreComments] = useState([]);
  const [storeTags, setStoreTags] = useState([]);
  const [hiddenArticlesLocal, setHiddenArticlesLocal] = useState([]);
  const [bookmarksLocal, setBookmarksLocal] = useState([]);

  const [multipleTagFlag, setMultipleTagFlag] = useState(false);
  const [multiTags, setMultiTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [tagActionType, setTagActionType] = useState('');
  const [emailNotify, setEmailNotify] = useState(false);
  const [stripFlag, setStripFlag] = useState(true);
  const [tooltipFlag, setTooltipFlag] = useState(true);
  const [iconPosition, setIconPosition] = useState({});
  const [toolTipText, setToolTipText] = useState(
    notifyOnceComplete
      ? 'You will receive an email notification once the analysis is complete.'
      : 'Turn on to enable email notification once the analysis is complete.'
    // ? 'You will receive an email and desktop notification once the analysis is complete.'
    // : 'Turn on to enable email and desktop notification once the analysis is complete.'
  );
  // show hide AI insights
  const [showAIInsight, setShowAIInsight] = useState(false);

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  const [oldFilters, setOldFilters] = useState(null);
  const { searchId, dashboardType, dashboardId } = useParams();
  const { tabsData, activeTab, comparisonFilter } = useSelector((state) => {
    if (dashboardType === 'primpact') {
      return state.prImpact;
    } else if (dashboardType === 'campaign') {
      return state.campaignMonitor;
    } else if (dashboardType === 'people') {
      return state.people;
    } else if (dashboardType === 'storyAnalysis') {
      return state.storyAnalysis;
    } else {
      return state.advancedDashboardTab;
    }
  });

  const notifyRef = useRef(null);

  useEffect(() => {
    if (notifyRef.current) {
      const notificationMsgWidth = notifyRef.current.offsetWidth;
      setIconPosition({
        left: notificationMsgWidth + 24, // Adding width of icon and padding
      });
    }
  }, [tooltipFlag, notifyRef.current]);

  const setArticleTypeClose = () => {
    setArticleType(articleTypeDefault);
  };
  const handleClick = (index, uniqueId) => {
    let graphIndex = index;
    if (uniqueId) {
      const totalSelectedGraphs = Object.keys(
        storeSelectedItems?.[dashboardType] || {}
      ).filter((x) => storeSelectedItems?.[dashboardType]?.[x]);
      const index = totalSelectedGraphs.indexOf(uniqueId);
      if (index !== -1) {
        graphIndex = index;
      }
    }

    if (index === 'left' || index === 'right') {
      setArticlePosition(index);
    } else {
      setArticlePosition(
        articlePosition === 'left' || articlePosition === 'right'
          ? articlePosition
          : 'left'
      );
    }

    setfloatingPagination(true);
    // this is to remove eslint warning
  };

  // const handlePage = (pageNum) => {
  //   setPage(pageNum);
  //   queryClient.invalidateQueries(['articles', pageNum, type]);
  // };

  const updateBookmark = () => {
    setBookmarked(!bookmarked);
  };

  const {
    mutate: updateSearchInSearch,
    isSuccess: isInSearchSuccess,
    data: inSearchData,
    isLoading: inSearchDataLoading,
  } = useMutation({
    mutationFn: handleSearchPostV1,
  });

  const {
    mutate: updateSearchSummaryPopup,
    isSuccess: isSearchSummaryPopupSuccess,
    data: searchSummaryPopupData,
    isLoading: isSearchSummaryPopupLoading,
  } = useMutation({
    mutationFn: handleSearchSummary,
  });

  // AI insights API for people dashboard
  const {
    mutate: updatePeopleSummaryPopup,
    isSuccess: isPeopleSummaryPopupSuccess,
    data: PeopleSummaryPopupData,
    isLoading: isPeopleSummaryPopupLoading,
  } = useMutation({
    mutationFn: handlePeopleSummary,
  });

  // AI insights API for Industry dashboard
  const {
    mutate: updateIndustrySummaryPopup,
    isSuccess: isIndustrySummaryPopupSuccess,
    data: IndustrySummaryPopupData,
    isLoading: isIndustrySummaryPopupLoading,
  } = useMutation({
    mutationFn: handleIndustrySummary,
  });

  // AI Insights API for Brand dashboard
  const {
    mutate: updateBrandSummaryPopup,
    isSuccess: isBrandSummaryPopupSuccess,
    data: BrandSummaryPopupData,
    isLoading: isBrandSummaryPopupLoading,
  } = useMutation({
    mutationFn: handleBrandSummary,
  });

  // AI insights for Brand & Competition Dashboard
  const {
    mutate: updateBrandCompetitionSummaryPopup,
    isSuccess: isBrandCompetitonSummaryPopupSuccess,
    data: BrandCompetitionSummaryPopupData,
    isLoading: isBrandCompetitionSummaryPopupLoading,
  } = useMutation({
    mutationFn: handleBrandCompetitionSummary,
  });

  // AI insights for custom dashboard
  const {
    mutate: updateCustomSummaryPopup,
    isSuccess: isCustomSummaryPopupSuccess,
    data: CustomSummaryPopupData,
    isLoading: isCustomSummaryPopupLoading,
  } = useMutation({
    mutationFn: handleCustomSummary,
  });

  React.useEffect(() => {
    if (isInSearchSuccess) {
      if (
        hiddenArticlesLocal?.length > 0 ||
        bookmarksLocal?.length > 0 ||
        storeComments?.length > 0 ||
        storeTags?.length > 0
      ) {
        setInSearchArticles((prev) => {
          if (inSearchData?.data?.paged?.pageNumber === 1) {
            setHiddenArticlesLocal([]);
            return [...inSearchData?.data?.data];
          }
          if (Array.isArray(prev)) {
            return [...prev, ...inSearchData?.data?.data];
          }
          return [...inSearchData?.data?.data];
        });
      } else {
        inSearchData?.data?.data &&
          setInSearchArticles((prev) => {
            if (inSearchData?.data?.paged?.pageNumber === 1) {
              setHiddenArticlesLocal([]);
              return [...inSearchData?.data?.data];
            }
            if (Array.isArray(prev)) {
              return [...prev, ...inSearchData?.data?.data];
            }
            return [...inSearchData?.data?.data];
          });
      }
      // setHiddenArticlesLocal([]);
      // setBookmarksLocal([]);
      // setStoreComments([]);
      // setStoreTags([]);
      setInSearchPaging(
        inSearchData?.data?.paged || {
          pageNumber: 1,
          pageSize: 50,
          total: 0,
        }
      );
    }
  }, [isInSearchSuccess, inSearchData]);

  const onClickComment = (ele) => {
    setSelectedArticle(ele);
    setMultipleTagFlag(true);
  };

  const storeArticleCommentsTags = (data, text, actionType, elementData) => {
    if (actionType === 'bookmarks') {
      if (text === 'add') {
        const filteredBookMarks = bookmarksLocal.filter(
          (bookMark) => bookMark?.article_id !== data?.article_id
        );
        setBookmarksLocal([...filteredBookMarks, { ...data }]);
      } else {
        setBookmarksLocal((prev) => {
          const filteredBookMarks = prev.filter(
            (bookmark) => bookmark?.article_id !== data?.article_id
          );
          return [...filteredBookMarks];
        });
      }
    } else if (actionType === 'hidden-articles') {
      if (text === 'add') {
        setHiddenArticlesLocal((prev) => {
          const articleExists = prev.some(
            (hiddenArticle) => hiddenArticle?.article_id === data?.article_id
          );
          if (articleExists) {
            return prev.map((hiddenArticle) =>
              hiddenArticle?.article_id === data?.article_id
                ? { ...data }
                : hiddenArticle
            );
          } else {
            return [...prev, { ...data }];
          }
        });
      } else {
        setHiddenArticlesLocal((prev) => {
          return prev.filter(
            (hiddenArticle) => hiddenArticle?.article_id !== data?.article_id
          );
        });
      }
    } else if (actionType === 'comments') {
      if (text === 'add') {
        const filteredComment = storeComments.filter(
          (comment) => comment?.article_id !== data?.article_id
        );
        setStoreComments([...filteredComment, { ...data }]);
      } else {
        setStoreComments((prev) => {
          const deleteFilterComment = prev.filter(
            (comment) => comment?.article_id !== data?.article_id
          );
          return [...deleteFilterComment];
        });
      }
    } else {
      if (text === 'AddOrDelete') {
        setTagActionType(text);
        const tagObjectValue = [];
        if (data?.length > 0) {
          data?.forEach((element) => {
            const newSetFilteredData = [...new Set(element?.tags)];
            tagObjectValue.push({
              // tagObjectValue.push( {
              article_id: element?.articleId,
              recent_search_id: data?.recent_search_id,
              tags: newSetFilteredData,
            });
          });
        }
        setStoreTags([
          ...storeTags?.filter(
            (x) =>
              !data?.some(
                (dt) => String(dt?.articleId) === String(x?.article_id)
              )
          ),
          ...tagObjectValue,
        ]);
      } else if (text === 'deleteAll') {
        setTagActionType(text);
        setStoreTags((prev) => [
          ...prev?.filter((x) => x?.article_id !== data?.articleId),
          {
            article_id: data?.articleId,
            recent_searchId: null,
            tags: [],
          },
        ]);
      } else {
        setTagActionType(text);
        setStoreTags((prev) => {
          const deleteFilterTags = prev.filter(
            (comment) => comment?.article_id !== data?.article_id
          );
          return [...deleteFilterTags];
        });
      }
    }
  };

  useEffect(() => {
    if (type === 'totalArticles') {
      setInSearchTotalData(inSearchData?.data?.media_types || []);
    }
  }, [inSearchData, type]);

  const {
    // isLoading,
    // error,
    data,
  } = useSearchFilterData(authInfo?.user_id);
  const searchFilterOptions = data?.data || [];
  const checkClickedChart = () => {
    const foundChart = Object.entries(dashboardCharts)
      .flatMap(([category, charts]) =>
        charts.map((chart) => ({ category, chart }))
      )
      .find(
        ({ chart }) =>
          articleType?.rawData?.uniqueId === chart?.chartId ||
          articleType?.otherInfo?.uniqueId === chart?.chartId
      );

    if (foundChart) {
      return foundChart.category;
    }

    return null;
  };

  const userInput = useSelector((state) => state.searchInput.searchKeyword);

  function extractObjectByLabel(articleType) {
    const rawData = articleType.otherInfo.d;
    const xLabel = rawData.xLabel;

    // Extract the matching object based on the 'label' property
    const matchingObject = rawData.brandData?.data?.find(
      (item) => item.label === xLabel
    );
    return matchingObject || null; // Return null if no match is found
  }

  // function to get chart data to request payload
  const getChartResponse = (chartName, articleType) => {
    switch (chartName) {
      case 'sov':
        return SOVWidgetDetails?.data?.originalData;
      case 'competitive_coverage_over_time':
        return coverageOverTimeCompWidgetDetails?.data?.originalData;
      case 'article_sentiment':
        return sentimentCompWidgetDetails?.data?.originalData;
      case 'competitive_reach_over_time':
        return reachOvertimeCompWidgetDetails?.data?.originalData;
      case 'coverage_by_journalist':
        return journalistWidgetDetails?.data?.originalData;
      case 'coverage_by_source':
        return sourceCompWidgetDetails?.data?.originalData;
      case 'breakdown_by_media_type':
        return mediaBreakdownWidgetDetails?.data?.originalData;
      case 'sentiment_analysis':
        return sentimentAnalysisWidgetDetails?.data?.originalData;
      case 'sentiment_over_time':
        return sentimeOverTimeWidgetDetails?.data?.originalData;
      case 'coverage_over_time':
        return coverageOverTimeBrandWidgetDetails?.data?.originalData;
      case 'reach_over_time':
        return reachOvertimeBrandWidgetDetails?.data?.originalData;
      case 'media_type':
        return mediaTypeWidgetDetails?.data?.originalData;
      case 'competition_companies_mentioned':
        return compMentionedWidgetDetails?.data?.originalData;
      case 'brand_companies_mentioned':
        return brandCompMentionedWidgetDetails?.data?.originalData;
      case 'brand_social_word_cloud':
        return brandSocialWordCloudWidgetDetails?.data?.originalData;
      case 'brand_result_over_time':
        return brandResultOverTimeWidgetDetails?.data?.originalData;
      case 'brand_top_performing_posts':
        return topPerformingPostsBrandWidgetDetails?.data?.data;
      case 'people_top_source_by_sentiment':
        return peopleSentimentAnalysisWidgetDetails?.data?.originalData;
      case 'people_coverage_over_time':
        return peopleCoverageChartDataWidgetDetails?.data?.originalData;
      case 'people_top_journalist_by_sentiment':
        return topJournalistSentimentAnalysisWidgetDetails?.data?.originalData;
      case 'people_popular_topics':
        return peopleThemeWidgetDetails?.data?.originalData;
      case 'people_media_type':
        return peopleMediaTypeWidgetDetails?.data?.originalData;
      case 'industry_sentiment_analysis':
        return industrySentimentWidgetDetails?.data?.originalData;
      case 'industry_coverage_over_time':
        return industryCoverageOverTimeWidgetDetails?.data?.originalData;
      case 'industry_coverage_by_source':
        return industryCoverageBySourceWidgetDetails?.data?.originalData;
      case 'industry_companies_mentioned':
        return industryCompaniesWidgetDetails?.data?.originalData;
      case 'industry_coverage_by_journalist':
        return industryCoverageByJournalistsWidgetDetails?.data?.originalData;
      case 'industry_coverage_by_top_publications':
        return industryPublicationsWidgetDetails?.data?.originalData;
      case 'campaign_analysis':
        return advancedWidgetDetails?.campaign?.data?.originalData;
      case 'authorimpact':
        return advancedWidgetDetails?.authorimpact?.data?.originalData;
      case 'sentimentByTheme':
        return advancedWidgetDetails?.sentiments?.data?.originalData;
      case 'congruence':
        return advancedWidgetDetails?.congruence?.data?.originalData;
      case 'primpact':
        return (
          articleType.otherInfo.d.rawData || extractObjectByLabel(articleType)
        );
      case 'advanced_campaign_comparison_campaign_analysis':
        return articleType.otherInfo.d.rawdata;
      case 'advanced_result_over_time':
        return advancedWidgetDetails?.socialCampaign?.advanced_result_over_time
          ?.data?.originalData;
      case 'advanced_campaign_sentiment_over_time':
        return advancedWidgetDetails?.socialCampaign
          ?.advanced_campaign_sentiment_over_time?.data?.originalData;
      case 'advanced_campaign_engagement':
        return advancedWidgetDetails?.socialCampaign
          ?.advanced_campaign_engagement?.data?.originalData;
      case 'advanced_campaign_content_type':
        return advancedWidgetDetails?.socialCampaign
          ?.advanced_campaign_content_type?.data?.originalData;
      case 'detractors':
        return advancedWidgetDetails?.socialCampaign?.detractors?.data
          ?.originalData;
      case 'advocates':
        return advancedWidgetDetails?.socialCampaign?.advocates?.data
          ?.originalData;
      case 'advanced_top_themes':
        return advancedWidgetDetails?.socialCampaign?.advanced_top_themes?.data
          ?.originalData;
      case 'advanced_top_themes_pre':
        return advancedWidgetDetails?.socialCampaign?.advanced_top_themes_pre
          ?.data?.originalData;
      case 'advanced_top_themes_during':
        return advancedWidgetDetails?.socialCampaign?.advanced_top_themes_during
          ?.data?.originalData;
      case 'advanced_top_themes_post':
        return advancedWidgetDetails?.socialCampaign?.advanced_top_themes_post
          ?.data?.originalData;
      default:
        return null;
    }
  };

  // returns a comma seprated string of chart IDs
  const getChatIdString = () => {
    const allCustomChartIds = [
      ...allDashboards.map((x) => x.chartId),
      'competitive_coverage_over_time_social',
      'competitive_reach_over_time_social',
      'sentiment_over_time_social',
      'coverage_over_time_social',
      'reach_over_time_social',
      'industry_coverage_over_time_social',
      'people_coverage_over_time_social',
      'people_popular_topic_themes',
    ];
    let chartIdAvailable = [];
    for (const chartIDVisible of allCustomChartIds) {
      const elements = document.getElementsByClassName(`${chartIDVisible}`);
      if (elements.length === 0) {
        console.warn(
          `No elements found with chartIDVisible: ${chartIDVisible}`
        );
        continue;
      }

      chartIdAvailable = [...chartIdAvailable, chartIDVisible];
    }

    const chartdata =
      chartIdAvailable || JSON?.parse(dashboardDataById?.selectedItems);

    const keys =
      chartdata ||
      Object?.values(chartdata)?.flatMap((subObj) =>
        Object?.keys(subObj)?.filter((key) => subObj[key] === true)
      );

    return keys.join(',');
  };

  useEffect(() => {
    if (
      searchFilterOptions?.length > 0 &&
      (articleType?.widget || syndicationType?.widget || articleType?.isSearch)
    ) {
      let searchFilters;
      if (activeTab === 999) {
        const index = tabsData?.findIndex(
          (x) =>
            articleType?.rawData?.label === x?.name ||
            articleType?.otherInfo?.d?.label === x?.name
        );

        const selectedPersonFilters =
          customData?.filters?.[index < 0 ? 0 : index];

        searchFilters = getPayloadForSearch(
          selectedPersonFilters?.query,
          selectedPersonFilters,
          searchFilterOptions,
          selectedPersonFilters?.guidedSection,
          selectedPersonFilters?.recent_search_id ||
            articleType?.rawData?.recentSearchId ||
            recentSearchId
        );
        if (
          articleType?.otherInfo?.uniqueId ===
            'people_volume_comparison_analysis' ||
          articleType?.otherInfo?.uniqueId === 'people-impact' ||
          articleType?.otherInfo?.uniqueId === 'people_top_score_comparison'
        ) {
          searchFilters = {
            ...searchFilters,
            person_name: [
              tabsData[index]?.keywords ||
                encloseWordsInDoubleQuotes(customData?.peopleData)?.join(
                  ' OR '
                ),
            ],
          };
        }
        if (
          articleType?.rawData?.uniqueId === 'people_coverage_on_key_topics'
        ) {
          const clickedIdx = articleType?.otherInfo?.d?.index;
          searchFilters = {
            ...searchFilters,
            wordcloud: (
              articleType?.otherInfo?.d?.data?.[`keywords${clickedIdx}`] || []
            ).map((keyword) => keyword.toUpperCase()),
            person_name: [
              tabsData[index]?.keywords ||
                encloseWordsInDoubleQuotes(customData?.peopleData)?.join(
                  ' OR '
                ),
            ],
            type: 'WORDCLOUD',
          };
        }
        if (
          articleType?.rawData?.uniqueId === 'people-reach-over-time-comparison'
        ) {
          searchFilters = {
            ...searchFilters,
            person_name:
              tabsData?.map((personName) => personName?.keywords) ||
              articleType?.otherInfo?.d?.data?.value?.map(
                (item) => item?.label
              ),
            ...getStartAndEndDate(
              articleType?.otherInfo?.d?.data?.date,
              searchFilters
            ),
          };
        }
        if (articleType?.otherInfo?.uniqueId === 'primpact') {
          const inputDate = articleType?.otherInfo?.d?.xLabel;
          searchFilters = {
            ...searchFilters,
            brand_keywords: [tabsData[index]?.keywords],
            start_date: format(
              parse(inputDate, 'dd MMM yy', new Date()),
              'yyyy-MM-dd'
            ),
            end_date: format(
              parse(inputDate, 'dd MMM yy', new Date()),
              'yyyy-MM-dd'
            ),
          };
        }
      } else {
        searchFilters = getPayloadForSearch(
          customData?.tabFilters?.query,
          customData?.tabFilters || customData?.filters,
          searchFilterOptions,
          customData?.tabFilters?.guidedSection,
          customData?.tabFilters?.recent_search_id || recentSearchId
        );
      }

      if (
        checkClickedChart() === 'brand' ||
        [
          'sentiment_over_time_social',
          'coverage_over_time_social',
          'reach_over_time_social',
          'brand_seasonal_trends',
        ]?.includes(
          articleType?.rawData?.uniqueId || articleType?.otherInfo?.uniqueId
        )
      ) {
        searchFilters = {
          ...searchFilters,
          graph_type: 'brand',
          brand_keywords: customData?.brandData || [],
        };
      }

      if (
        checkClickedChart() === 'brand' ||
        ['brand_seasonal_trends']?.includes(
          articleType?.rawData?.uniqueId || articleType?.otherInfo?.uniqueId
        )
      ) {
        searchFilters = {
          ...searchFilters,
          graph_type: 'brand',
          ...getStartAndEndDate(
            articleType?.otherInfo?.d?.data?.date,
            searchFilters
          ),
          brand_keywords: customData?.brandData || [],
        };
      }

      if (
        checkClickedChart() === 'brand' &&
        articleType?.otherInfo?.uniqueId === 'brand_top_performing_posts'
      ) {
        searchFilters = {
          ...searchFilters,
          graph_type: 'brand',
          article_id: articleType?.otherInfo?.article_id,
        };
      }

      if (
        checkClickedChart() === 'competition' ||
        [
          'competitive_reach_over_time_social',
          'competitive_coverage_over_time_social',
        ]?.includes(articleType?.rawData?.uniqueId)
      ) {
        searchFilters = {
          ...searchFilters,
          competition_keywords: customData?.competitionData || [],
          brand_keywords: customData?.brandData || [],
        };
      }
      if (
        checkClickedChart() === 'competition' &&
        ['competition_media_contributors']?.includes(
          articleType?.rawData?.uniqueId
        )
      ) {
        searchFilters = {
          ...searchFilters,
          competition_keywords: [articleType?.otherInfo?.d?.labelText] || [],
          brand_keywords: [articleType?.otherInfo?.d?.labelText] || [],
          author_id:
            articleType?.otherInfo?.d?.rawData?.author_id ||
            articleType?.otherInfo?.d?.data?.author_id,
          author: articleType?.otherInfo?.d?.label,
        };
      }
      if (
        checkClickedChart() === 'competition' &&
        articleType?.rawData?.uniqueId === 'competition_coverage_by_websites'
      ) {
        searchFilters = {
          ...searchFilters,
          competition_keywords: [articleType?.otherInfo?.d?.labelText] || [],
          brand_keywords: [articleType?.otherInfo?.d?.labelText] || [],
          source_includes: articleType?.otherInfo?.d.label,
        };
      }
      // if (graphType === 'brand') {
      //   searchFilters = {
      //     ...searchFilters,
      //     graph_type: graphType,
      //   };
      // }
      if (
        (checkClickedChart() === 'people' ||
          [
            'people_coverage_over_time_social',
            'people_popular_topic_themes',
          ].includes(articleType?.rawData?.uniqueId)) &&
        ![
          'people_volume_comparison_analysis',
          'people-reach-over-time-comparison',
          'people-impact',
          'people_top_score_comparison',
          'people_coverage_on_key_topics',
        ].includes(articleType?.otherInfo?.uniqueId)
      ) {
        searchFilters = {
          ...searchFilters,
          person_name:
            [
              tabsData[activeTab]?.keywords ||
                encloseWordsInDoubleQuotes(customData?.peopleData)?.join(
                  ' OR '
                ),
            ] || [],
        };
      }
      // handles campaign monitor filters
      if (articleType?.widget === 'Campaign Monitor') {
        searchFilters = {
          ...searchFilters,
          keyword: articleType?.otherInfo?.d?.keyword,
        };
      }
      // handles author impact filters without sentiments
      if (
        articleType?.widget === 'Author Impact' &&
        (articleType?.otherInfo?.d?.label !== 'Positive' ||
          articleType?.otherInfo?.d?.label !== 'Negative')
      ) {
        searchFilters = {
          ...searchFilters,
          author_id:
            articleType?.otherInfo?.d?.rawData?.author_id ||
            articleType?.otherInfo?.d?.data?.author_id,
          author: articleType?.otherInfo?.d?.label,
        };
      }
      // handles author impact filters with sentiments
      if (
        articleType?.widget === 'Author Impact' &&
        (articleType?.otherInfo?.d?.label === 'Positive' ||
          articleType?.otherInfo?.d?.label === 'Negative')
      ) {
        searchFilters = {
          ...searchFilters,
          author_id:
            articleType?.otherInfo?.d?.rawData?.author_id ||
            articleType?.otherInfo?.d?.data?.author_id,
          author: articleType?.otherInfo?.d?.data?.label,
          // sentiments:
          //   articleType?.otherInfo?.d?.label === 'Positive' ? ['POS'] : ['NEG'],
        };
      }
      // handles Sentiment By Themes filters
      if (articleType?.widget === 'Sentiment By Themes') {
        searchFilters = {
          ...searchFilters,
          theme: articleType?.otherInfo?.d?.data?.label?.toUpperCase(),
          sentiments:
            articleType?.otherInfo?.d?.label === 'Positive' ? ['POS'] : ['NEG'],
        };
      }
      // handles Message Congruence filters
      if (articleType?.widget === 'Message Congruence') {
        delete searchFilters.brand_keywords;
        searchFilters = {
          ...searchFilters,
          keyword: articleType?.otherInfo?.d?.keyword,
          media_types: articleType?.otherInfo?.d?.labelText?.toLowerCase(),
        };
      }

      if (articleType?.widget === 'PR Impact Scale' && activeTab !== 999) {
        searchFilters = {
          ...searchFilters,
          start_date: articleType?.otherInfo?.d?.date,
          end_date: articleType?.otherInfo?.d?.date,
          // brand_keywords: [...customData?.brandData],
          brand_keywords: [tabsData[activeTab]?.keywords],
        };
      }

      if (articleType?.widget === 'advanced_result_over_time') {
        searchFilters = {
          ...searchFilters,
          start_date: articleType?.otherInfo?.d?.data?.date,
          end_date: articleType?.otherInfo?.d?.data?.date,
          keyword: tabsData[activeTab].keywords,
        };
      }
      if (articleType?.widget === 'advanced_campaign_sentiment_over_time') {
        searchFilters = {
          ...searchFilters,
          start_date: articleType?.otherInfo?.d?.data?.date,
          end_date: articleType?.otherInfo?.d?.data?.date,
          keyword: tabsData[activeTab].keywords,
        };
      }
      if (
        articleType?.widget === 'advanced_top_themes' ||
        articleType?.widget === 'advanced_top_themes_pre' ||
        articleType?.widget === 'advanced_top_themes_during' ||
        articleType?.widget === 'advanced_top_themes_post'
      ) {
        const themeKeywords = articleType?.otherInfo?.d?.keyword?.map(
          (item) => item.keyword
        );
        searchFilters = {
          ...searchFilters,
          keyword: tabsData[activeTab].keywords,
          theme: themeKeywords,
        };
      }
      if (articleType?.widget === 'advanced_campaign_monitor_themes') {
        let themeKeywords;
        if (articleType?.otherInfo?.d?.isKeyword) {
          themeKeywords = [articleType?.otherInfo?.d?.label];
        } else {
          themeKeywords = themeKeywords =
            articleType?.otherInfo?.d?.keyword?.map((item) => item.keyword);
        }
        searchFilters = {
          ...searchFilters,
          keyword: tabsData[activeTab].keywords,
          theme: themeKeywords,
        };
      }
      if (articleType?.widget === 'advanced_campaign_content_type') {
        const youTubeMediaType =
          articleType?.otherInfo?.d?.label === 'Video' ? 'YouTube' : '';
        searchFilters = {
          ...searchFilters,
          keyword: tabsData[activeTab].keywords,
          content_type:
            articleType?.otherInfo?.d?.label === 'Photos'
              ? 'images'
              : articleType?.otherInfo?.d?.label.toLowerCase(),
          ...(youTubeMediaType ? { media_types: youTubeMediaType } : {}),
        };
      }

      if (articleType?.otherInfo?.widget?.component === 'detractors') {
        searchFilters = {
          ...searchFilters,
          keyword: tabsData[activeTab].keywords,
          author_id: articleType?.otherInfo?.author_id,
          author: articleType?.otherInfo?.name,
          sentiments: ['NEG'],
        };
      }
      if (articleType?.otherInfo?.widget?.component === 'advocates') {
        searchFilters = {
          ...searchFilters,
          keyword: tabsData[activeTab].keywords,
          author_id: articleType?.otherInfo?.author_id,
          author: articleType?.otherInfo?.name,
          sentiments: ['POS'],
        };
      }
      if (
        articleType?.widget === 'advanced_campaign_monitor_reach' ||
        articleType?.widget === 'advanced_campaign_monitor_sentiment_over_time'
      ) {
        searchFilters = {
          ...searchFilters,
          ...getStartAndEndDate(
            articleType?.otherInfo?.d?.data?.date,
            searchFilters
          ),
          keyword: tabsData[activeTab]?.keywords,
        };
      }

      if (articleType?.widget === 'advanced_content_type') {
        const youTubeMediaType =
          articleType?.otherInfo?.d?.label === 'Video' ? 'YouTube' : '';
        searchFilters = {
          ...searchFilters,
          content_type:
            articleType?.otherInfo?.d?.label === 'Photos'
              ? 'images'
              : articleType?.otherInfo?.d?.label.toLowerCase(),
          ...(youTubeMediaType ? { media_types: youTubeMediaType } : {}),
          dashboardType,
        };
      }

      if (
        articleType?.widget === 'advanced_content_engagement' ||
        articleType?.widget === 'advanced_campaign_engagement'
      ) {
        const daysOfWeek = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ];

        const weekdayToNumber =
          articleType?.otherInfo?.d?.label === 'Sunday'
            ? 7
            : daysOfWeek?.indexOf(articleType?.otherInfo?.d?.label);

        searchFilters = {
          ...searchFilters,
          weekday: weekdayToNumber,
          hours: convertTimeHours(articleType?.otherInfo?.d?.labelText)?.slice(
            0,
            2
          ),
          [articleType?.widget === 'advanced_content_engagement' &&
          'dashboardType']: dashboardType,
        };

        if (articleType?.widget === 'advanced_campaign_engagement') {
          searchFilters = {
            ...searchFilters,
            keyword: articleType?.otherInfo?.d?.campaignKeywords,
          };
        }
      }
      if (articleType?.widget === 'Top Engaging Posts') {
        searchFilters = {
          ...searchFilters,
          channel: articleType?.otherInfo?.channel,
          title: articleType?.otherInfo?.postTitle,
          author_id: articleType?.otherInfo?.author_id,
          dashboardType,
        };
      }

      if (dashboardType === 'socialCampaign') {
        searchFilters = {
          ...searchFilters,
          dashboardType: 'socialCampaign',
        };
      }
      if (
        articleType?.widget === 'advanced_campaign_comparison_reach_by_campaign'
      ) {
        // Remove the `label` field
        const { label, ...campaigns } = articleType?.otherInfo?.d?.data;

        // Find the campaign with the maximum value
        const maxCampaign = Object.keys(campaigns).reduce((max, campaign) => {
          return Number(campaigns[campaign]) > Number(campaigns[max])
            ? campaign
            : max;
        }, Object.keys(campaigns)[0]);

        let filterIdx = 0;
        for (let idx = 0; idx < tabsData?.length; idx++) {
          if (tabsData[idx].name === maxCampaign) {
            filterIdx = idx;
            break;
          }
        }

        const filterWithDatetime = {
          ...customData?.allFilters[filterIdx],
          ...comparisonFilter,
        };
        searchFilters = getPayloadForSearch(
          customData?.allFilters[filterIdx]?.query,
          filterWithDatetime,
          searchFilterOptions,
          customData?.allFilters[filterIdx]?.guidedSection,
          customData?.allFilters[filterIdx]?.recent_searchId
        );

        searchFilters = {
          ...searchFilters,
          start_date: articleType?.otherInfo?.d?.data?.label,
          end_date: articleType?.otherInfo?.d?.data?.label,
          keyword: tabsData[filterIdx]?.keywords,
        };
      }

      if (
        articleType?.widget === 'advanced_campaign_comparison_campaign_analysis'
      ) {
        const clickedCampaign = articleType?.otherInfo?.d?.label;

        let filterIdx = 0;
        for (let idx = 0; idx < tabsData?.length; idx++) {
          if (
            tabsData[idx].name.toLowerCase() === clickedCampaign.toLowerCase()
          ) {
            filterIdx = idx;
            break;
          }
        }

        const filterWithDatetime = {
          ...customData?.allFilters[filterIdx],
          ...comparisonFilter,
        };

        searchFilters = getPayloadForSearch(
          customData?.allFilters[filterIdx]?.query,
          filterWithDatetime,
          searchFilterOptions,
          customData?.allFilters[filterIdx]?.guidedSection,
          customData?.allFilters[filterIdx]?.recent_searchId
        );

        searchFilters = {
          ...searchFilters,
          keyword: tabsData[filterIdx]?.keywords,
        };
      }

      if (articleType?.widget === 'advanced_campaign_monitor_sentiment') {
        const clickedCampaign = articleType?.otherInfo?.d?.label;

        let filterIdx = 0;
        for (let idx = 0; idx < tabsData?.length; idx++) {
          if (
            tabsData[idx].name.toLowerCase() === clickedCampaign.toLowerCase()
          ) {
            filterIdx = idx;
            break;
          }
        }

        const filterWithDatetime = {
          ...customData?.allFilters[filterIdx],
          ...comparisonFilter,
        };

        searchFilters = getPayloadForSearch(
          customData?.allFilters[filterIdx]?.query,
          filterWithDatetime,
          searchFilterOptions,
          customData?.allFilters[filterIdx]?.guidedSection,
          customData?.allFilters[filterIdx]?.recent_searchId
        );

        const getSentiment = () => {
          const s = articleType?.otherInfo?.d?.labelText;
          if (s === 'Neutral') {
            return 'NEU';
          } else if (s === 'Positive') {
            return 'POS';
          }
          return 'NEG';
        };

        searchFilters = {
          ...searchFilters,
          sentiments: getSentiment(),
          keyword: tabsData[filterIdx]?.keywords,
        };
      }

      if (articleType?.widget === 'advanced_campaign_monitor_wordcloud') {
        searchFilters = {
          ...searchFilters,
          wordcloud: articleType?.otherInfo?.d?.label,
          keyword: tabsData[activeTab]?.keywords,
          type: articleType?.otherInfo?.d?.type,
        };
      }

      if (articleType?.widget === 'advanced_campaign_monitor_top_author') {
        searchFilters = {
          ...searchFilters,
          author: articleType?.otherInfo?.d?.label,
          author_id: articleType?.otherInfo?.d?.rawData?.author_id,
          keyword: tabsData[activeTab]?.keywords,
        };
      }
      if (
        articleType?.widget === 'advanced_campaign_monitor_outlet_breakdown'
      ) {
        searchFilters = {
          ...searchFilters,
          media_types: articleType?.otherInfo?.d?.data?.label,
          keyword: tabsData[activeTab]?.keywords,
        };
      }

      if (
        articleType?.widget?.split('-')[0] ===
        'advanced_campaign_monitor_vol_distribution_by_channel'
      ) {
        const [chartId, idx] = articleType?.widget
          ? articleType.widget.split('-')
          : [];

        const filterWithDatetime = {
          ...customData?.allFilters[idx],
          ...comparisonFilter,
        };

        searchFilters = getPayloadForSearch(
          customData?.allFilters[idx]?.query,
          filterWithDatetime,
          searchFilterOptions,
          customData?.allFilters[idx]?.guidedSection,
          customData?.allFilters[idx]?.recent_searchId
        );

        searchFilters = {
          ...searchFilters,
          media_types: articleType?.otherInfo?.d?.data?.label,
          keyword: tabsData[idx]?.keywords,
        };
      }

      if (syndicationType?.name === 'DASHBOARD') {
        const graphFilter = getDashboardSearchQuery(
          articleType?.widget,
          articleType?.graphSelection,
          articleType?.rawData,
          articleType?.otherInfo,
          searchFilters
        );
        searchFilters = {
          ...searchFilters,
          ...graphFilter,
          syndication_article_title:
            syndicationType?.rawData?.syndication_title,
          syndication_article_id: syndicationType?.rawData?.articleId,
        };
      } else {
        const graphFilter = getDashboardSearchQuery(
          articleType?.widget,
          articleType?.graphSelection,
          articleType?.rawData,
          articleType?.otherInfo,
          searchFilters
        );
        searchFilters = {
          ...searchFilters,
          ...graphFilter,
        };
      }

      if (dashboardType === 'storyAnalysis') {
        searchFilters = {
          ...searchFilters,
          article_ids: tabsData?.[activeTab]?.analyzeArticleIds,
          simple_query:
            tabsData?.[activeTab]?.articleData?.length > 1
              ? tabsData?.[activeTab]?.articleData
                  ?.map((storyQuery) => `(${storyQuery?.keyword_query})`)
                  ?.join(' AND ')
              : tabsData?.[activeTab]?.articleData?.[0]?.keyword_query,
        };
      }

      if (inSearchSortOrder) {
        searchFilters = {
          ...searchFilters,
          sort: inSearchSortOrder,
        };
      }
      if (type !== 'totalArticles' && type) {
        searchFilters = {
          ...searchFilters,
          media_types: [type],
        };
      }
      // if RHS search result popup is open

      if (articleType.isSearch && searchKey) {
        searchFilters = {
          ...searchFilters,
          search_in_search: wrapInDoubleQuotes(searchKey),
        };
      }
      if (recentSearchId && activeTab !== 999) {
        searchFilters = {
          ...searchFilters,
          recent_search_id: recentSearchId,
        };
      }

      if (
        searchFilters?.person_name &&
        searchFilters?.person_name?.length > 0
      ) {
        searchFilters = {
          ...searchFilters,
          person_name: searchFilters?.person_name,
        };
      }

      if (
        searchFilters?.brand_keywords &&
        searchFilters?.brand_keywords?.length > 0 &&
        articleType?.widget !== 'PR Impact Scale'
      ) {
        searchFilters = {
          ...searchFilters,
          brand_keywords: encloseWordsInDoubleQuotes(
            searchFilters?.brand_keywords
          ),
        };
      }

      if (
        searchFilters?.competition_keywords &&
        searchFilters?.competition_keywords?.length > 0
      ) {
        searchFilters = {
          ...searchFilters,
          competition_keywords: encloseWordsInDoubleQuotes(
            searchFilters?.competition_keywords
          ),
        };
      }

      setDownloadFilterData({
        ...searchFilters,
        page_number: inSearchPageNum + 1,
        saved_search_id: searchId,
        save_recent_search: false,
      });

      trackEvent('Standard Dashboard Chart Interaction - Click', {
        chart_id: authorSummaryKey(articleType, activeTab),
        chart_name: articleType?.widget,
        interaction_type: 'click',
      });

      updateSearchInSearch({
        ...searchFilters,
        page_number: inSearchPageNum + 1,
        saved_search_id: searchId,
        save_recent_search: false,
      });
    }
    // clean up store
    return () => {
      dispatch(setInput(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inSearchPageNum,
    articleType,
    searchFilterOptions,
    inSearchSortOrder,
    type,
    bookmarked,
    customData?.filters,
    // triggerInSearchFetchData,
    syndicationType,
    hiddenArticleSwitch,
  ]);

  // fetch search summary for graphs
  useEffect(() => {
    const applySearchFilters = () => {
      let searchFilters = getPayloadForSearch(
        customData?.tabFilters?.query,
        customData?.tabFilters,
        searchFilterOptions,
        customData?.tabFilters?.guidedSection,
        customData?.tabFilters?.recent_search_id || recentSearchId
      );

      const graphFilter = getDashboardSearchQuery(
        articleType?.widget,
        articleType?.graphSelection,
        articleType?.rawData,
        articleType?.otherInfo,
        searchFilters
      );

      if (inSearchSortOrder) {
        searchFilters = {
          ...searchFilters,
          ...graphFilter,
          sort: inSearchSortOrder,
        };
      }

      if (type !== 'totalArticles' && type) {
        searchFilters = {
          ...searchFilters,
          media_types: [type],
        };
      }
      // handles campaign monitor filters
      if (articleType?.widget === 'Campaign Monitor') {
        searchFilters = {
          ...searchFilters,
          keyword: articleType?.otherInfo?.d?.keyword,
        };
      }
      // handles author impact filters without sentiments
      if (
        articleType?.widget === 'Author Impact' &&
        (articleType?.otherInfo?.d?.label !== 'Positive' ||
          articleType?.otherInfo?.d?.label !== 'Negative')
      ) {
        searchFilters = {
          ...searchFilters,
          author_id:
            articleType?.otherInfo?.d?.rawData?.author_id ||
            articleType?.otherInfo?.d?.data?.author_id,
          author: articleType?.otherInfo?.d?.label,
          author_impact_chart: articleType?.otherInfo?.d?.key,
        };
      }
      // handles author impact filters with sentiments
      if (
        articleType?.widget === 'Author Impact' &&
        (articleType?.otherInfo?.d?.label === 'Positive' ||
          articleType?.otherInfo?.d?.label === 'Negative')
      ) {
        searchFilters = {
          ...searchFilters,
          author_id:
            articleType?.otherInfo?.d?.rawData?.author_id ||
            articleType?.otherInfo?.d?.data?.author_id,
          author: articleType?.otherInfo?.d?.data?.label,
          author_impact_chart: articleType?.otherInfo?.d?.key,
          sentiments:
            articleType?.otherInfo?.d?.label === 'Positive' ? ['POS'] : ['NEG'],
        };
      }
      // handles Sentiment By Themes filters
      if (articleType?.widget === 'Sentiment By Themes') {
        searchFilters = {
          ...searchFilters,
          theme: articleType?.otherInfo?.d?.data?.label,
          sentiments:
            articleType?.otherInfo?.d?.label === 'Positive' ? ['POS'] : ['NEG'],
        };
      }
      // handles Message Congruence filters

      if (articleType?.widget === 'PR Impact Scale' && activeTab !== 999) {
        searchFilters = {
          ...searchFilters,
          start_date: articleType?.otherInfo?.d?.date,
          end_date: articleType?.otherInfo?.d?.date,
          brand_keywords: [tabsData[activeTab]?.keywords],
        };
      }

      if (articleType?.widget === 'Message Congruence') {
        delete searchFilters.brand_keywords;
        searchFilters = {
          ...searchFilters,
          keyword: articleType?.otherInfo?.d?.keyword,
          clicked_media_type: articleType?.otherInfo?.d?.labelText,
        };
      }
      if (
        articleType?.otherInfo?.uniqueId ||
        articleType?.widget ||
        articleType?.otherInfo?.widget?.component
      ) {
        const chartData = getChartResponse(
          articleType?.otherInfo?.uniqueId ||
            articleType?.widget ||
            articleType?.otherInfo?.widget?.component,
          articleType
        );

        if (chartData !== null) {
          searchFilters = {
            ...searchFilters,
            originalData: chartData,
          };
        }
      }

      if (
        articleType?.otherInfo?.uniqueId === 'people_top_source_by_sentiment'
      ) {
        searchFilters = {
          ...searchFilters,
          sources_count: articleType?.otherInfo?.d?.value,
          person_name:
            tabsData[activeTab]?.keywords ||
            encloseWordsInDoubleQuotes(customData?.peopleData)?.join(' OR '),
        };
      }
      if (
        articleType?.rawData?.uniqueId === 'people_coverage_over_time_social'
      ) {
        searchFilters = {
          ...searchFilters,
          person_name:
            tabsData[activeTab]?.keywords ||
            encloseWordsInDoubleQuotes(customData?.peopleData)?.join(' OR '),
          input_count: articleType?.otherInfo?.d?.value,
        };
      }

      if (articleType?.rawData?.uniqueId === 'people_coverage_over_time') {
        searchFilters = {
          ...searchFilters,
          person_name:
            tabsData[activeTab]?.keywords ||
            encloseWordsInDoubleQuotes(customData?.peopleData)?.join(' OR '),
          input_count: articleType?.otherInfo?.d?.value,
        };
      }

      if (
        articleType?.rawData?.uniqueId === 'people_top_journalist_by_sentiment'
      ) {
        searchFilters = {
          ...searchFilters,
          person_name:
            tabsData[activeTab]?.keywords ||
            encloseWordsInDoubleQuotes(customData?.peopleData)?.join(' OR '),
          author_articles_count: articleType?.otherInfo?.d?.value,
        };
      }

      if (articleType?.rawData?.uniqueId === 'people_media_type') {
        searchFilters = {
          ...searchFilters,
          person_name:
            tabsData[activeTab]?.keywords ||
            encloseWordsInDoubleQuotes(customData?.peopleData)?.join(' OR '),
          media_type_count: articleType?.otherInfo?.d?.value,
        };
      }

      if (
        articleType?.rawData?.uniqueId === 'people_popular_topic_themes' ||
        articleType?.otherInfo?.uniqueId === 'people_popular_topic_themes'
      ) {
        let themeKeywords;
        if (articleType?.otherInfo?.d?.isKeyword) {
          themeKeywords = [articleType?.otherInfo?.d?.label];
        } else {
          themeKeywords = themeKeywords =
            articleType?.otherInfo?.d?.keyword?.map((item) => item.keyword);
        }
        searchFilters = {
          ...searchFilters,
          person_name:
            tabsData[activeTab]?.keywords ||
            encloseWordsInDoubleQuotes(customData?.peopleData)?.join(' OR '),
          topic_count: articleType?.otherInfo?.d?.value,
          input_primary_topic:
            articleType?.otherInfo?.d?.primary_theme ||
            articleType?.otherInfo?.d?.data?.primary_theme,
          input_secondary_topic: themeKeywords,
        };
      }

      if (articleType?.widget === 'advanced_campaign_monitor_reach') {
        searchFilters = {
          ...searchFilters,
          sum_reach: articleType?.otherInfo?.d?.value,
          keyword: tabsData[activeTab]?.keywords,
          ...getStartAndEndDate(
            articleType?.otherInfo?.d?.data?.date,
            searchFilters
          ),
        };
      }
      if (
        articleType?.widget === 'advanced_campaign_monitor_sentiment_over_time'
      ) {
        searchFilters = {
          ...searchFilters,
          keyword: tabsData[activeTab]?.keywords,
          sentiments:
            articleType?.otherInfo?.d?.labelText === 'Positive'
              ? ['POS']
              : ['NEG'],
          ...getStartAndEndDate(
            articleType?.otherInfo?.d?.data?.date,
            searchFilters
          ),
        };
      }

      if (articleType?.widget === 'advanced_campaign_monitor_top_author') {
        searchFilters = {
          ...searchFilters,
          author: articleType?.otherInfo?.d?.label,
          author_id: articleType?.otherInfo?.d?.rawData?.author_id,
          keyword: tabsData[activeTab]?.keywords,
        };
      }
      if (
        articleType?.widget === 'advanced_campaign_monitor_outlet_breakdown'
      ) {
        searchFilters = {
          ...searchFilters,
          media_types: articleType?.otherInfo?.d?.data?.label,
          keyword: tabsData[activeTab]?.keywords,
        };
      }
      if (articleType?.widget === 'advanced_campaign_monitor_themes') {
        let themeKeywords;
        if (articleType?.otherInfo?.d?.isKeyword) {
          themeKeywords = [articleType?.otherInfo?.d?.label];
        } else {
          themeKeywords = themeKeywords =
            articleType?.otherInfo?.d?.keyword?.map((item) => item.keyword);
        }
        searchFilters = {
          ...searchFilters,
          theme: themeKeywords,
          keyword: tabsData[activeTab]?.keywords,
          input_primary_topic: articleType?.otherInfo?.d?.label,
        };
      }
      if (
        articleType?.widget === 'advanced_result_over_time' ||
        articleType?.widget === 'advanced_campaign_sentiment_over_time'
      ) {
        searchFilters = {
          ...searchFilters,
          keyword: tabsData[activeTab]?.keywords,
          start_date: articleType?.otherInfo?.d?.data?.date,
          end_date: articleType?.otherInfo?.d?.data?.date,
        };
      }
      if (
        articleType?.widget === 'advanced_top_themes' ||
        articleType?.widget === 'advanced_top_themes_pre' ||
        articleType?.widget === 'advanced_top_themes_during' ||
        articleType?.widget === 'advanced_top_themes_post'
      ) {
        searchFilters = {
          ...searchFilters,
          theme:
            articleType?.otherInfo?.d?.label ||
            articleType?.otherInfo?.d?.data?.label,
          keyword: tabsData[activeTab]?.keywords,
        };
      }

      if (articleType?.widget === 'Top Engaging Posts') {
        searchFilters = {
          ...searchFilters,
          channel: articleType?.otherInfo?.channel,
          title: articleType?.otherInfo?.postTitle,
          author_id: articleType?.otherInfo?.author_id,
          dashboardType,
        };
      }

      if (
        articleType?.widget === 'advanced_content_engagement' ||
        articleType?.widget === 'advanced_campaign_engagement'
      ) {
        const daysOfWeek = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ];

        const weekdayToNumber =
          articleType?.otherInfo?.d?.label === 'Sunday'
            ? 7
            : daysOfWeek?.indexOf(articleType?.otherInfo?.d?.label);

        searchFilters = {
          ...searchFilters,
          weekday: weekdayToNumber,
          hours: convertTimeHours(articleType?.otherInfo?.d?.labelText)?.slice(
            0,
            2
          ),
          [articleType?.widget === 'advanced_content_engagement' &&
          'dashboardType']: dashboardType,
        };
      }

      if (articleType?.widget === 'advanced_campaign_engagement') {
        searchFilters = {
          ...searchFilters,
          keyword: tabsData[activeTab]?.keywords,
        };
      }
      if (articleType?.otherInfo?.widget?.component === 'detractors') {
        searchFilters = {
          ...searchFilters,
          keyword: tabsData[activeTab].keywords,
          author_id: articleType?.otherInfo?.author_id,
          author: articleType?.otherInfo?.name,
          sentiments: ['NEG'],
        };
      }
      if (articleType?.otherInfo?.widget?.component === 'advocates') {
        searchFilters = {
          ...searchFilters,
          keyword: tabsData[activeTab].keywords,
          author_id: articleType?.otherInfo?.author_id,
          author: articleType?.otherInfo?.name,
          sentiments: ['POS'],
        };
      }
      if (articleType?.widget === 'advanced_campaign_content_type') {
        const youTubeMediaType =
          articleType?.otherInfo?.d?.label === 'Video' ? 'YouTube' : '';
        searchFilters = {
          ...searchFilters,
          keyword: tabsData[activeTab].keywords,
          content_type:
            articleType?.otherInfo?.d?.label === 'Photos'
              ? 'images'
              : articleType?.otherInfo?.d?.label.toLowerCase(),
          ...(youTubeMediaType ? { media_types: youTubeMediaType } : {}),
        };
      }
      if (articleType?.widget === 'advanced_content_type') {
        const youTubeMediaType =
          articleType?.otherInfo?.d?.label === 'Video' ? 'YouTube' : '';
        searchFilters = {
          ...searchFilters,
          content_type:
            articleType?.otherInfo?.d?.label === 'Photos'
              ? 'images'
              : articleType?.otherInfo?.d?.label.toLowerCase(),
          ...(youTubeMediaType ? { media_types: youTubeMediaType } : {}),
          dashboardType,
        };
      }
      if (
        checkClickedChart() === 'brand' &&
        articleType?.otherInfo?.uniqueId === 'brand_top_performing_posts'
      ) {
        searchFilters = {
          ...searchFilters,
          graph_type: 'brand',
          article_id: articleType?.otherInfo?.article_id,
          brand_keywords: customData?.brandData,
        };
      }
      if (checkClickedChart() === 'competition') {
        if (articleType?.otherInfo?.uniqueId === 'sov') {
          let selected_option = 'Volume';
          let selected_value = articleType?.otherInfo?.d?.data?.doc_count;
          let total_count =
            articleType?.otherInfo?.widget?.originalData?.total_count;
          const data = articleType?.otherInfo?.widget?.originalData?.data;
          if (articleType?.otherInfo?.d?.data?.engagement) {
            selected_option = 'Engagements';
            selected_value = articleType?.otherInfo?.d?.data?.engagement;
            total_count = data.reduce((sum, item) => sum + item.engagement, 0);
          } else if (articleType?.otherInfo?.d?.data?.reach) {
            selected_option = 'Reach';
            selected_value = articleType?.otherInfo?.d?.data?.reach;
            total_count = data.reduce((sum, item) => sum + item.reach, 0);
          } else if (articleType?.otherInfo?.d?.data?.impressions) {
            selected_option = 'Impressions';
            selected_value = articleType?.otherInfo?.d?.data?.impressions;
            total_count = data.reduce((sum, item) => sum + item.impressions, 0);
          }
          const percentage = Math.round((selected_value / total_count) * 100);
          searchFilters = {
            ...searchFilters,
            brand_keywords: customData?.brandData,
            competition_keywords: customData?.competitionData,
            selected_option,
            selected_value,
            percentage,
          };
        }
        if (
          articleType?.otherInfo?.uniqueId ===
          'competition_coverage_by_websites'
        ) {
          searchFilters = {
            ...searchFilters,
            brand_keywords: customData?.brandData,
            selected_count: articleType?.otherInfo?.d?.value,
          };
        }
        if (
          articleType?.otherInfo?.uniqueId === 'competition_media_contributors'
        ) {
          searchFilters = {
            ...searchFilters,
            brand_keywords: customData?.brandData,
            total_count: articleType?.otherInfo?.d?.value,
          };
        }
      }

      if (checkClickedChart() === 'brand') {
        if (articleType?.otherInfo?.uniqueId === 'sentiment_analysis') {
          searchFilters = {
            ...searchFilters,
            brand_keywords: customData?.brandData,
            total_count: articleType?.rawData?.value,
          };
        }
        if (articleType?.otherInfo?.uniqueId === 'brand_engagement_metrics') {
          searchFilters = {
            ...searchFilters,
            brand_keywords: customData?.brandData,
            total_count: articleType?.rawData?.value,
          };
        }
        if (articleType?.otherInfo?.uniqueId === 'brand_result_over_time') {
          let selected_value = 0;
          if (articleType?.otherInfo?.d?.trendLabel === 'Reach') {
            selected_value = articleType?.otherInfo?.d?.reach;
          } else if (articleType?.otherInfo?.d?.trendLabel === 'Engagement') {
            selected_value = articleType?.otherInfo?.d?.engagements;
          } else if (articleType?.otherInfo?.d?.trendLabel === 'Impression') {
            selected_value = articleType?.otherInfo?.d?.impressions;
          }
          searchFilters = {
            ...searchFilters,
            brand_keywords: customData?.brandData,
            total_count: articleType?.otherInfo?.d?.value,
            selected_date: articleType?.otherInfo?.d?.date,
            selected_option: articleType?.otherInfo?.d?.trendLabel,
            selected_value,
          };
        }
      }
      if (
        articleType?.otherInfo?.uniqueId === 'competitive_coverage_over_time' ||
        articleType?.otherInfo?.uniqueId ===
          'competitive_coverage_over_time_social' ||
        articleType?.otherInfo?.uniqueId === 'competitive_reach_over_time' ||
        articleType?.otherInfo?.uniqueId ===
          'competitive_reach_over_time_social'
      ) {
        searchFilters = {
          ...searchFilters,
          brand_keywords: customData?.brandData,
          competition_keywords: customData?.competitionData,
        };
      }

      if (dashboardType === 'storyAnalysis') {
        searchFilters = {
          ...searchFilters,
          article_ids: tabsData?.[activeTab]?.analyzeArticleIds,
          simple_query:
            tabsData?.[activeTab]?.articleData?.length > 1
              ? tabsData?.[activeTab]?.articleData
                  ?.map((storyQuery) => `(${storyQuery?.keyword_query})`)
                  ?.join(' AND ')
              : tabsData?.[activeTab]?.articleData?.[0]?.keyword_query,
        };
      }

      if (activeTab === 999) {
        const index = tabsData?.findIndex(
          (x) =>
            articleType?.rawData?.label === x?.name ||
            articleType?.otherInfo?.d?.label === x?.name ||
            articleType?.graphSelection === x?.name
        );

        const selectedPersonFilters =
          customData?.filters?.[index < 0 ? 0 : index];

        searchFilters = getPayloadForSearch(
          selectedPersonFilters?.query,
          selectedPersonFilters,
          searchFilterOptions,
          selectedPersonFilters?.guidedSection,
          selectedPersonFilters?.recent_search_id || recentSearchId
        );

        if (articleType?.otherInfo?.uniqueId === 'primpact') {
          const inputDate = articleType?.otherInfo?.d?.xLabel;

          searchFilters = {
            ...searchFilters,
            brand_keywords: [tabsData[index]?.keywords],
            start_date: format(
              parse(inputDate, 'dd MMM yy', new Date()),
              'yyyy-MM-dd'
            ),
            end_date: format(
              parse(inputDate, 'dd MMM yy', new Date()),
              'yyyy-MM-dd'
            ),
          };
        }
        if (
          articleType?.rawData?.uniqueId === 'people_volume_comparison_analysis'
        ) {
          searchFilters = {
            ...searchFilters,
            person_name: articleType?.otherInfo?.d?.label,
            person_count: articleType?.otherInfo?.d?.value,
          };
        }
        if (
          articleType?.otherInfo?.uniqueId === 'people_top_score_comparison'
        ) {
          searchFilters = {
            ...searchFilters,
            person_name: articleType?.otherInfo?.personInfo?.name_query,
          };
        }
        if (
          articleType?.rawData?.uniqueId === 'people_coverage_on_key_topics'
        ) {
          searchFilters = {
            ...searchFilters,
            input_persons:
              tabsData?.map((personName) => personName?.keywords) ||
              articleType?.otherInfo?.d?.data?.value?.map(
                (item) => item?.label
              ),
            input_topic: articleType?.otherInfo?.d?.label,
          };
        }

        if (
          articleType?.rawData?.uniqueId === 'people-reach-over-time-comparison'
        ) {
          searchFilters = {
            ...searchFilters,
            person_name:
              tabsData?.map((personName) => personName?.keywords) ||
              articleType?.otherInfo?.d?.data?.value?.map(
                (item) => item?.label
              ),
            input_reach:
              articleType?.otherInfo?.d?.data?.value?.map(
                (item) => item?.value
              ) || articleType?.otherInfo?.d?.value,
            ...getStartAndEndDate(
              articleType?.otherInfo?.d?.data?.date,
              searchFilters
            ),
          };
        }

        // if (articleType?.otherInfo?.uniqueId === 'people-impact') {
        //   searchFilters = {
        //     ...searchFilters,
        //     person_name: articleType?.otherInfo?.d?.label,
        //     input_reach: articleType?.otherInfo?.d?.yValue,
        //     person_count: articleType?.otherInfo?.d?.articleCount,
        //     net_sentiments: articleType?.otherInfo?.d?.value,
        //   };
        // }

        if (
          articleType?.widget ===
          'advanced_campaign_comparison_reach_by_campaign'
        ) {
          const { label, ...campaigns } = articleType?.otherInfo?.d?.data;

          // Find the campaign with the maximum value
          const maxCampaign = Object.keys(campaigns).reduce((max, campaign) => {
            return Number(campaigns[campaign]) > Number(campaigns[max])
              ? campaign
              : max;
          }, Object.keys(campaigns)[0]);

          let filterIdx = 0;
          for (let idx = 0; idx < tabsData?.length; idx++) {
            if (tabsData[idx].name === maxCampaign) {
              filterIdx = idx;
              break;
            }
          }

          const filterWithDatetime = {
            ...customData?.allFilters[filterIdx],
            ...comparisonFilter,
          };
          searchFilters = getPayloadForSearch(
            customData?.allFilters[filterIdx]?.query,
            filterWithDatetime,
            searchFilterOptions,
            customData?.allFilters[filterIdx]?.guidedSection,
            customData?.allFilters[filterIdx]?.recent_searchId
          );

          searchFilters = {
            ...searchFilters,
            start_date: articleType?.otherInfo?.d?.data?.label,
            end_date: articleType?.otherInfo?.d?.data?.label,
            keyword: tabsData[filterIdx]?.keywords,
            sum_reach: articleType?.otherInfo?.d?.value,
          };
        }
        if (
          articleType?.widget?.startsWith(
            'advanced_campaign_monitor_vol_distribution_by_channel'
          )
        ) {
          const tabIndex = parseInt(articleType?.widget?.split('-').pop(), 10);

          const selectedPersonFilters = customData?.filters?.[tabIndex];
          searchFilters = getPayloadForSearch(
            selectedPersonFilters?.query,
            selectedPersonFilters,
            searchFilterOptions,
            selectedPersonFilters?.guidedSection,
            selectedPersonFilters?.recent_search_id || recentSearchId
          );
          searchFilters = {
            ...searchFilters,
            keyword: tabsData[tabIndex]?.keywords,
            media_types: articleType?.otherInfo?.d?.data?.label,
          };
        }
        if (articleType?.widget === 'advanced_campaign_monitor_sentiment') {
          searchFilters = {
            ...searchFilters,
            keyword: tabsData[index]?.keywords,
            sentiments:
              articleType?.otherInfo?.d?.labelText === 'Positive'
                ? ['POS']
                : articleType?.otherInfo?.d?.labelText === 'Negative'
                ? ['NEG']
                : ['NEU'],
            total_count: articleType?.otherInfo?.d?.value,
          };
        }
        if (
          articleType?.widget ===
          'advanced_campaign_comparison_campaign_analysis'
        ) {
          const clickedCampaign = articleType?.otherInfo?.d?.label;

          let filterIdx = 0;
          for (let idx = 0; idx < tabsData?.length; idx++) {
            if (
              tabsData[idx].name.toLowerCase() === clickedCampaign.toLowerCase()
            ) {
              filterIdx = idx;
              break;
            }
          }

          const filterWithDatetime = {
            ...customData?.allFilters[filterIdx],
            ...comparisonFilter,
          };

          searchFilters = getPayloadForSearch(
            customData?.allFilters[filterIdx]?.query,
            filterWithDatetime,
            searchFilterOptions,
            customData?.allFilters[filterIdx]?.guidedSection,
            customData?.allFilters[filterIdx]?.recent_searchId
          );

          const chartData = getChartResponse(articleType?.widget, articleType);
          searchFilters = {
            ...searchFilters,
            keyword: tabsData[filterIdx]?.keywords,
            originalData: chartData,
          };
        }
        if (articleType?.otherInfo?.uniqueId) {
          const chartData = getChartResponse(
            articleType?.otherInfo?.uniqueId,
            articleType
          );

          if (chartData !== null) {
            searchFilters = {
              ...searchFilters,
              originalData: chartData,
            };
          }
        }
      }
      const bodyData = convertToBody(articleType);
      const searchSummaryData = {
        ...searchFilters,
        page_size: 50,
        chart_name: authorSummaryKey(articleType, activeTab),
        total_count: totalCount,
      };
      if (bodyData !== null) {
        searchSummaryData.searchbody = bodyData;
      }
      updateSearchSummaryPopup(searchSummaryData);
    };

    if (searchFilterOptions?.length > 0) {
      if (articleType?.otherInfo?.triggerAISummary) {
        applySearchFilters();
        setFetchAiSummary(true);
      } else if (fetchAiSummary) {
        applySearchFilters();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // triggerInSearchFetchData,
    searchFilterOptions,
    inSearchSortOrder,
    type,
    articleType,
    fetchAiSummary,
  ]);

  // Fetch Overall AI summary for People,Industry and Brand & Competition Dashboard
  useEffect(() => {
    if (callAIInsight || showAIInsight) {
      let searchFilters = getPayloadForSearch(
        customData?.tabFilters?.query,
        customData?.tabFilters,
        searchFilterOptions,
        customData?.tabFilters?.guidedSection,
        customData?.tabFilters?.recent_search_id || recentSearchId
      );

      if (dashboardType === 'people') {
        // People Dashboard
        searchFilters = {
          ...searchFilters,
          person_name: [tabsData[activeTab]?.keywords],
        };
        if (
          PeopleSummaryPopupData === undefined ||
          PeopleSummaryPopupData?.status === 'error' ||
          JSON.stringify(oldFilters) !== JSON.stringify(searchFilters)
        ) {
          setOldFilters(searchFilters);
          updatePeopleSummaryPopup({
            ...searchFilters,
            page_size: 50,
          });
        }
      } else if (dashboardType === 'brand') {
        // Brand
        searchFilters = {
          ...searchFilters,
          graph_type: 'brand',
          brand_keywords: customData?.brandData || [],
        };
        if ((customData?.competitionData || [])?.length > 0) {
          // competition dashboard
          searchFilters = {
            ...searchFilters,
            competition_keywords: customData?.competitionData || [],
          };
          if (
            BrandCompetitionSummaryPopupData === undefined ||
            BrandCompetitionSummaryPopupData?.status === 'error' ||
            JSON.stringify(oldFilters) !== JSON.stringify(searchFilters)
          ) {
            setOldFilters(searchFilters);
            updateBrandCompetitionSummaryPopup({
              ...searchFilters,
              page_size: 50,
            });
          }
        } else {
          // Brand Dashboard
          if (
            (BrandSummaryPopupData === undefined ||
              BrandSummaryPopupData?.status === 'error' ||
              JSON.stringify(oldFilters) !== JSON.stringify(searchFilters)) &&
            (customData?.brandData || [])?.length > 0
          ) {
            setOldFilters(searchFilters);
            updateBrandSummaryPopup({
              ...searchFilters,
              page_size: 50,
            });
          }
        }
      } else if (dashboardType === 'custom') {
        // Custom Dashboard
        searchFilters = {
          ...searchFilters,
          chart_names: getChatIdString(),
          brand_keywords: customData?.brandData || [],
          competition_keywords: customData?.competitionData || [],
          person_name:
            encloseWordsInDoubleQuotes(customData?.peopleData)?.join(' OR ') ||
            [],
        };
        if (
          CustomSummaryPopupData === undefined ||
          CustomSummaryPopupData?.status === 'error' ||
          JSON.stringify(oldFilters) !== JSON.stringify(searchFilters)
        ) {
          setOldFilters(searchFilters);
          updateCustomSummaryPopup({
            ...searchFilters,
            page_size: 50,
          });
        }
      } else {
        // Industry Dashboard
        if (
          IndustrySummaryPopupData === undefined ||
          IndustrySummaryPopupData?.status === 'error' ||
          JSON.stringify(oldFilters) !== JSON.stringify(searchFilters)
        ) {
          setOldFilters(searchFilters);
          updateIndustrySummaryPopup({
            ...searchFilters,
            page_size: 50,
          });
        }
      }
    }
  }, [showAIInsight, callAIInsight]);

  // Set data to state
  useEffect(() => {
    // Set summary data to a state
    if (
      isPeopleSummaryPopupSuccess &&
      PeopleSummaryPopupData &&
      dashboardType === 'people'
    ) {
      setSummaryData(PeopleSummaryPopupData);
    } else if (
      isIndustrySummaryPopupSuccess &&
      IndustrySummaryPopupData &&
      dashboardType === 'industry'
    ) {
      setSummaryData(IndustrySummaryPopupData);
    } else if (
      isBrandSummaryPopupSuccess &&
      BrandSummaryPopupData &&
      dashboardType === 'brand'
    ) {
      setSummaryData(BrandSummaryPopupData);
    } else if (
      isBrandCompetitonSummaryPopupSuccess &&
      BrandCompetitionSummaryPopupData &&
      dashboardType === 'brand'
    ) {
      setSummaryData(BrandCompetitionSummaryPopupData);
    } else if (
      isCustomSummaryPopupSuccess &&
      CustomSummaryPopupData &&
      dashboardType === 'custom'
    ) {
      setSummaryData(CustomSummaryPopupData);
    }

    // Handle loading state
    if (
      isPeopleSummaryPopupLoading ||
      isIndustrySummaryPopupLoading ||
      isBrandSummaryPopupLoading ||
      isBrandCompetitionSummaryPopupLoading ||
      isCustomSummaryPopupLoading
    ) {
      setSummaryLoading(true);
    } else {
      setSummaryLoading(false);
    }
  }, [
    isPeopleSummaryPopupSuccess,
    PeopleSummaryPopupData,
    isIndustrySummaryPopupSuccess,
    IndustrySummaryPopupData,
    isPeopleSummaryPopupLoading,
    isIndustrySummaryPopupLoading,
    isBrandSummaryPopupLoading,
    isBrandSummaryPopupSuccess,
    BrandSummaryPopupData,
    BrandCompetitionSummaryPopupData,
    isBrandCompetitionSummaryPopupLoading,
    isBrandCompetitonSummaryPopupSuccess,
    CustomSummaryPopupData,
    isCustomSummaryPopupLoading,
    isCustomSummaryPopupSuccess,
  ]);

  const getTabs = (data) => {
    const mediaCountData = getMediaCountDataUTIL(data);
    if (customData?.filters?.mediaTypes) {
      return mediaCountData?.filter(
        (x) =>
          customData?.filters?.mediaTypes?.some((y) => y.value === x.value) ||
          x.value === 'totalArticles'
      );
    } else {
      const mediaTypes = searchFilterOptions.find(
        (x) => x.value === 'mediaTypes'
      );
      if (mediaTypes) {
        const options = mediaTypes?.options;
        return mediaCountData?.filter(
          (x) =>
            options?.some((y) => y.value === x.value) ||
            x.value === 'totalArticles'
        );
      }
      return [];
    }
  };

  useEffect(() => {
    setInSearchArticles(null);
  }, [
    inSearchSortOrder,
    userInput,
    articleType,
    customData?.filters,
    syndicationType,
  ]);

  const { data: dashboardData } = useGetSavedDashboardQueryData(
    null,
    dashboardId
  );

  return (
    <SearchMainWrp className="p-relative">
      {/* <BtnWrp top="2rem" activeScreen={activeScreen}>
        <Btn
          className={activeScreen === 'dashboard' ? 'inactive' : ''}
          onClick={() => onBtnClick('article')}
        >
          <ChevronLeft color={activeScreen === '' ? '#675EF2' : '#FFFFFF'} />
        </Btn>
        <Btn
          className={activeScreen === 'article' ? 'inactive' : ''}
          onClick={() => onBtnClick('dashboard')}
        >
          <ChevronRight color={activeScreen === '' ? '#675EF2' : '#FFFFFF'} />
        </Btn>
      </BtnWrp> */}
      <SearchResultWrp activeScreen={activeScreen}>
        {dashboardConfig.advanced.includes(dashboardType) ? (
          <div>
            {dashboardType === 'primpact' &&
              advancedWidgetDetails &&
              advancedWidgetDetails?.primpact &&
              // advancedWidgetDetails?.primpact?.show &&
              dashboardId &&
              stripFlag &&
              !lastUpdateStripFlag &&
              !advancedWidgetDetails?.primpact?.isLoading && (
                <>
                  <ProgressBar style={{ marginBottom: '0.5rem' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ margin: 0 }}>
                        {' '}
                        {stripText}. This might take a few minutes.
                      </p>
                      {/* <Timer
                        onCountReached={
                          advancedWidgetDetails?.primpact?.refetchData
                        }
                        resetTimer={resetTimer}
                      /> */}
                      <Button
                        style={{
                          marginLeft: '0.5rem',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.2rem',
                        }}
                        onClick={() => {
                          // navigate('/dashboards');
                          setStripFlag(false);
                          toast.error('PR Impact Analysis Cancelled');
                        }}
                      >
                        {crossSVG} Cancel
                      </Button>
                    </div>
                    <NotificationContainer ref={notifyRef}>
                      <div style={{ marginRight: '0.5rem' }}>{mailSVG}</div>
                      <Label>Notify Once Complete</Label>
                      <Switch>
                        <input
                          type="checkbox"
                          checked={notifyOnceComplete}
                          onChange={() => {
                            setNotifyOnceComplete((old) => {
                              advancedWidgetDetails?.primpact?.handleSaveDashboard(
                                !old
                              );
                              if (!old === true) {
                                setTooltipFlag(true);
                                setToolTipText(
                                  // 'You will receive an email and desktop notification once the analysis is complete.'
                                  'You will receive an email notification once the analysis is complete.'
                                );
                              } else {
                                setTooltipFlag(true);
                                setToolTipText(
                                  // 'Turn on to enable email and desktop notification once the analysis is complete.'
                                  'Turn on to enable email notification once the analysis is complete.'
                                );
                              }
                              return !old;
                            });
                          }}
                        />
                        <span className="slider round"></span>
                      </Switch>
                      {tooltipFlag && (
                        <ChartToolTip
                          text={toolTipText}
                          crossIcon={true}
                          setTooltipFlag={setTooltipFlag}
                          componentLeft={iconPosition?.left}
                          componentTop={7}
                        />
                      )}
                    </NotificationContainer>
                  </ProgressBar>
                </>
              )}
            {dashboardType === 'primpact' &&
              advancedWidgetDetails &&
              advancedWidgetDetails?.primpact &&
              advancedWidgetDetails?.primpact?.show &&
              dashboardId &&
              lastUpdateStripFlag &&
              !advancedWidgetDetails?.primpact?.isLoading &&
              activeTab !== 999 && (
                <>
                  <ProgressBar
                    background={theme[selectedTheme]?.graphColors?.coolGray60}
                    style={{ marginBottom: '0.5rem' }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ margin: 0 }}>
                        {' '}
                        PR Impact Chart Last Updated on{' '}
                        {formatTimeFromDate(dashboardData?.data[0]?.updated_at)}
                        , {formatDate(dashboardData?.data[0]?.updated_at)}
                      </p>
                      {/* <Timer
                        onCountReached={
                          advancedWidgetDetails?.primpact?.refetchData
                        }
                        resetTimer={resetTimer}
                      /> */}
                      <Button
                        background={
                          theme[selectedTheme]?.graphColors?.coolGray50
                        }
                        style={{
                          marginLeft: '0.5rem',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.2rem',
                          padding: '0.25rem 0.5rem 0.25rem 0.25rem',
                        }}
                        onClick={() => {
                          // navigate('/dashboards');
                          setStripFlag(true);
                          // advancedWidgetDetails?.primpact?.refetchData();
                          advancedWidgetDetails?.primpact?.handleSaveDashboard(
                            true
                          );
                          setStripText('Updating Chart');
                          setLastUpdateStripFlag(false);
                          // toast.error('PR Impact Analysis Cancelled');
                        }}
                      >
                        <RefreshIcon width="12" color="#fff" /> Refresh Chart
                      </Button>
                    </div>
                    {/* <NotificationContainer ref={notifyRef}>
                      <div style={{ marginRight: '0.5rem' }}>{mailSVG}</div>
                      <Label>Notify Once Complete</Label>
                      <Switch>
                        <input
                          type="checkbox"
                          checked={notifyOnceComplete}
                          onChange={() => {
                            setNotifyOnceComplete((old) => {
                              advancedWidgetDetails?.primpact?.handleSaveDashboard(
                                !old
                              );
                              if (!old === true) {
                                setTooltipFlag(true);
                                setToolTipText(
                                  // 'You will receive an email and desktop notification once the analysis is complete.'
                                  'You will receive an email notification once the analysis is complete.'
                                );
                              } else {
                                setTooltipFlag(true);
                                setToolTipText(
                                  // 'Turn on to enable email and desktop notification once the analysis is complete.'
                                  'Turn on to enable email notification once the analysis is complete.'
                                );
                              }
                              return !old;
                            });
                          }}
                        />
                        <span className="slider round"></span>
                      </Switch>
                      {tooltipFlag && (
                        <ChartToolTip
                          text={toolTipText}
                          crossIcon={true}
                          setTooltipFlag={setTooltipFlag}
                          componentLeft={iconPosition?.left}
                          componentTop={7}
                        />
                      )}
                    </NotificationContainer> */}
                  </ProgressBar>
                </>
              )}
            {dashboardType === 'storyAnalysis' && storyPercentage !== '' && (
              <UserStoryLoaderWrp>
                <div>
                  <Loader size="0.25rem" width="1.4rem" height="1.4rem" />
                </div>
                <div
                  style={{
                    fontWeight: 'bold',
                    color: theme[selectedTheme].primary,
                    fontSize: '0.75rem',
                    marginLeft: '5px',
                  }}
                >
                  {storyPercentage}
                </div>
              </UserStoryLoaderWrp>
            )}
            <AdvancedDashboard
              resultsShown={resultsShown}
              resetData={() => {
                setSyndicationType({
                  widget: undefined,
                  graphSelection: undefined,
                  rawData: null,
                });
                setSearchKey('');
                setInsearchPageNum(0);
              }}
              activeScreen={activeScreen}
              loader={loader}
              articleType={articleType}
              setArticleType={setArticleType}
              advancedWidgetDetails={advancedWidgetDetails}
              filterType={filterType}
              overView={false}
              setClickedPosition={handleClick}
              articlePosition={articlePosition}
              resetSelection={resetSelection}
              setResetSelection={setResetSelection}
              isDocDownloading={isDocDownloading}
              savedSelectedChart={savedSelectedChart}
              handleGraphTitleUpdate={handleGraphTitleUpdate}
              onDownloadChartData={onDownloadChartData}
              customCanvas={customCanvas}
              filters={customData?.filters}
            />
          </div>
        ) : (
          <DashboardSectionComponent
            handleSelectedChartDropdown={handleSelectedChartDropdown}
            dashboardKeywords={dashboardKeywords}
            selectedChartDropdown={selectedChartDropdown}
            resultsShown={resultsShown}
            onDownloadChartData={onDownloadChartData}
            filters={customData?.filters}
            setSyndicationClick={() => {
              setSyndicationType({
                widget: undefined,
                graphSelection: undefined,
                rawData: null,
              });
              setSearchKey('');
              setInsearchPageNum(0);
            }}
            savedSelectedChart={savedSelectedChart}
            isSavePopup={isSavePopup}
            handleGraphTitleUpdate={handleGraphTitleUpdate}
            accessTypeViewUser={accessTypeViewUser}
            activeScreen={activeScreen}
            showSaved={showSaved}
            loader={loader}
            articleType={articleType}
            setArticleType={setArticleType}
            tileDetails={tileDetails}
            dashboardDetails={dashboardDetails}
            overView={false}
            setClickedPosition={handleClick}
            articlePosition={articlePosition}
            setResetSelection={setResetSelection}
            setSelected={setSelected}
            selected={selected}
            setArticlePosition={setArticlePosition}
            resetSelection={resetSelection}
            setArticleTypeClose={setArticleTypeClose}
            selectGraph={selectGraph}
            setSelectedGraph={setSelectedGraph}
            dashboardType={dashboardType}
            customCanvas={customCanvas}
            editOption={true}
            volumeAnalysisWidgetDetails={volumeAnalysisWidgetDetails}
            brandEngagementMetrics={brandEngagementMetrics}
            sentimentAnalysisWidgetDetails={sentimentAnalysisWidgetDetails}
            sentimeOverTimeWidgetDetails={sentimeOverTimeWidgetDetails}
            seasonalTrendsWidgetDetails={seasonalTrendsWidgetDetails}
            sentimeOverTimeSocialWidgetDetails={
              sentimeOverTimeSocialWidgetDetails
            }
            peopleTopThemesWidgetDetails={peopleTopThemesWidgetDetails}
            coverageOverTimeBrandWidgetDetails={
              coverageOverTimeBrandWidgetDetails
            }
            coverageOverTimeBrandSocialWidgetDetails={
              coverageOverTimeBrandSocialWidgetDetails
            }
            compMentionedWidgetDetails={compMentionedWidgetDetails}
            brandCompMentionedWidgetDetails={brandCompMentionedWidgetDetails}
            brandSocialWordCloudWidgetDetails={
              brandSocialWordCloudWidgetDetails
            }
            brandResultOverTimeWidgetDetails={brandResultOverTimeWidgetDetails}
            reachOvertimeBrandWidgetDetails={reachOvertimeBrandWidgetDetails}
            reachOvertimeBrandSocialWidgetDetails={
              reachOvertimeBrandSocialWidgetDetails
            }
            SOVWidgetDetails={SOVWidgetDetails}
            topJournalistSentimentAnalysisWidgetDetails={
              topJournalistSentimentAnalysisWidgetDetails
            }
            peopleCoverageChartDataWidgetDetails={
              peopleCoverageChartDataWidgetDetails
            }
            peopleCoverageSocialChartDataWidgetDetails={
              peopleCoverageSocialChartDataWidgetDetails
            }
            peopleThemeWidgetDetails={peopleThemeWidgetDetails}
            peopleMediaTypeWidgetDetails={peopleMediaTypeWidgetDetails}
            peopleCoverageOnKeyTopicsChartDetails={
              peopleCoverageOnKeyTopicsChartDetails
            }
            peopleTopScoreDetails={peopleTopScoreDetails}
            coverageOverTimeCompWidgetDetails={
              coverageOverTimeCompWidgetDetails
            }
            coverageOverTimeSocialCompWidgetDetails={
              coverageOverTimeSocialCompWidgetDetails
            }
            mediaTypeWidgetDetails={mediaTypeWidgetDetails}
            peopleVolumeAnalysisWidgetDetails={
              peopleVolumeAnalysisWidgetDetails
            }
            peopleVolumeComparisonData={peopleVolumeComparisonData}
            peopleImpactWidgetDetails={peopleImpactWidgetDetails}
            peopleReachOverTimeComparisonData={
              peopleReachOverTimeComparisonData
            }
            peopleSentimentAnalysisWidgetDetails={
              peopleSentimentAnalysisWidgetDetails
            }
            sentimentCompWidgetDetails={sentimentCompWidgetDetails}
            reachOvertimeCompWidgetDetails={reachOvertimeCompWidgetDetails}
            topPerformingPostsBrandWidgetDetails={
              topPerformingPostsBrandWidgetDetails
            }
            reachOvertimeSocialCompWidgetDetails={
              reachOvertimeSocialCompWidgetDetails
            }
            mediaContributorsCompWidgetDetails={
              mediaContributorsCompWidgetDetails
            }
            coverageByWebsiteCompWidgetDetails={
              coverageByWebsiteCompWidgetDetails
            }
            mediaBreakdownWidgetDetails={mediaBreakdownWidgetDetails}
            journalistWidgetDetails={journalistWidgetDetails}
            sourceCompWidgetDetails={sourceCompWidgetDetails}
            industryVolumeAnalysisWidgetDetails={
              industryVolumeAnalysisWidgetDetails
            }
            industrySentimentWidgetDetails={industrySentimentWidgetDetails}
            industryCoverageOverTimeWidgetDetails={
              industryCoverageOverTimeWidgetDetails
            }
            industryCoverageSocialOverTimeWidgetDetails={
              industryCoverageSocialOverTimeWidgetDetails
            }
            industryCoverageBySourceWidgetDetails={
              industryCoverageBySourceWidgetDetails
            }
            industryCompaniesWidgetDetails={industryCompaniesWidgetDetails}
            industryCoverageByJournalistsWidgetDetails={
              industryCoverageByJournalistsWidgetDetails
            }
            industryTopInfluencerWidgetData={industryTopInfluencerWidgetData}
            industryPublicationsWidgetDetails={
              industryPublicationsWidgetDetails
            }
            storyAnalysisResultOverTimeWidgetDetails={
              storyAnalysisResultOverTimeWidgetDetails
            }
            storyAnalysisMediaTypeWidgetDetails={
              storyAnalysisMediaTypeWidgetDetails
            }
            sentimeWidgetDetails={sentimeWidgetDetails}
            topThemeWidgetDetails={topThemeWidgetDetails}
            wordCloudWidgetDetails={wordCloudWidgetDetails}
            topInfluencerWidgetDetails={topInfluencerWidgetDetails}
            topSourceWidgetDetails={topSourceWidgetDetails}
            outletMediaTypeWidgetDetails={outletMediaTypeWidgetDetails}
            geographicalWidgetDetails={geographicalWidgetDetails}
            placeholderGraphs={placeholderGraphs}
            showAIInsight={showAIInsight}
            setShowAIInsight={setShowAIInsight}
            summaryData={summaryData}
            summaryLoading={summaryLoading}
          />
        )}
        {articlePosition && <Overlay />}
        <ArticleSectionComponentWrp articlePosition={articlePosition}>
          {/* <IconWrapper articlePosition={articlePosition}>
            <XCirlcle />
          </IconWrapper> */}
          <ArticleSectionComponent
            setSelectedArticle={onClickComment}
            name="DASHBOARD"
            // loader={true}
            loader={loader}
            onClick={() => {
              setArticlePosition('');
              setArticleTypeClose();
              setSelected(null);
              setResetSelection(false);
              setType('totalArticles');
              setInsearchPageNum(0);
              setResetSelected(true);
              setSearchKey('');
              setInSearchArticles(null);
              setFetchAiSummary(false);
              setResetSelection(true);
              setHiddenArticleSwitch(false);
            }}
            setArticles={setInSearchArticles}
            setHiddenArticleSwitch={setHiddenArticleSwitch}
            hiddenArticleSwitch={hiddenArticleSwitch}
            isPopup={true}
            syndicationClickData={syndicationType}
            resetAllChecked={resetSelected}
            toggleResetAllChecked={() => setResetSelected(false)}
            articlesRecentSearchId={
              recentSearchId || articleType?.rawData?.recentSearchId
            }
            articleType={articleType}
            activeScreen={activeScreen}
            setArticleTypeClose={setArticleTypeClose}
            type={type}
            setType={setType}
            floatingPagination={true}
            queryPayload={getPayloadForSearch(
              customData?.tabFilters?.query,
              customData?.tabFilters,
              searchFilterOptions,
              customData?.tabFilters?.guidedSection,
              customData?.tabFilters?.recent_search_id || recentSearchId
            )}
            articlePosition={articlePosition}
            id={searchId}
            setArticlePosition={setArticlePosition}
            setSelected={setSelected}
            pageWidth={paginationWidth}
            setClickedPosition={handleClick}
            setArticleType={setArticleType}
            page={inSearchPageNum}
            setPage={setInsearchPageNum}
            setSelectedGraph={setSelectedGraph}
            updateBookmark={updateBookmark}
            setResetSelection={setResetSelection}
            showFullTitle={
              syndicationType?.graphSelection === 'Syndication'
                ? syndicationType?.graphSelection
                : searchKey ||
                  syndicationType?.graphSelection ||
                  (articleType?.widget === 'Author Impact' &&
                  ['Positive', 'Negative', 'Neutral'].includes(
                    articleType?.graphSelection
                  )
                    ? articleType?.otherInfo?.d?.labelText
                    : articleType?.graphSelection)
            }
            articlePagingInfo={inSearchPaging}
            articlesInfo={inSearchArticles}
            isLoading={
              storeComments?.length > 0 ||
              storeTags?.length > 0 ||
              bookmarksLocal?.length > 0 ||
              hiddenArticlesLocal?.length > 0
                ? false
                : inSearchDataLoading
            }
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            sortOrder={inSearchSortOrder}
            setSortOrder={setInsearchSortOrder}
            titleTabs={getTabs(inSearchTotalData)}
            setIsCustomPagiNationFlag={setIsCustomPagiNationFlag}
            setTriggerFetchUseEffect={setTriggerInSearchFetchData}
            setSyndicationClick={setSyndicationType}
            storeArticleCommentsTags={storeArticleCommentsTags}
            storeComments={storeComments}
            storeTags={storeTags}
            setStoreComments={setStoreComments}
            setStoreTags={setStoreTags}
            bookmarksLocal={bookmarksLocal}
            hiddenArticlesLocal={hiddenArticlesLocal}
            checked={checked}
            setChecked={setChecked}
            setDownLoadArticleFlag={setDownLoadArticleFlag}
            setDownloadSelected={setDownloadSelected}
            summaryData={{
              isSuccess: isSearchSummaryPopupSuccess,
              data: searchSummaryPopupData,
              isLoading: isSearchSummaryPopupLoading,
            }}
            setFetchAiSummary={setFetchAiSummary}
            fetchAiSummary={fetchAiSummary}
            setMultipleTagFlag={(value) => {
              setSelectedArticle(null);
              setMultipleTagFlag(value);
            }}
            setAllTags={setAllTags}
            tagActionType={tagActionType}
          />
          <DashboardPopup
            open={downloadArticleFlag}
            toggler={setDownLoadArticleFlag}
            popContent={
              // <ContactUs
              //   toggler={setNewCanvasPopCont}
              //   updateParentStateCont={updateParentStateCont}
              // />
              <DownloadArticlePopup
                toggler={setDownLoadArticleFlag}
                checked={checked}
                totalArticles={inSearchPaging?.total}
                downloadType={downloadSelected}
                getPayloadForSearch={() => {
                  return {
                    ...getPayloadForSearch(
                      customData?.tabFilters?.query,
                      customData?.tabFilters,
                      searchFilterOptions,
                      customData?.tabFilters?.guidedSection,
                      customData?.tabFilters?.recent_search_id || recentSearchId
                    ),
                    recent_search_id: recentSearchId,
                  };
                }}
                query={customData?.query}
                downloadFilterData={downloadFilterData}
                articlesInfo={inSearchArticles}
                sortOrder={inSearchSortOrder}
              />
            }
            padding="0"
            Cross={true}
            borderRadius="0.75rem"
            width={'25rem'}
          />
          <DashboardPopup
            open={multipleTagFlag}
            toggler={setMultipleTagFlag}
            popContent={
              <MultiTagPopup
                allTheme={allTags}
                checked={
                  selectedArticle ? [selectedArticle?.articleId] : checked
                }
                setMultipleTagFlag={setMultipleTagFlag}
                setMultiTags={setMultiTags}
                multiTags={multiTags}
                articlesInfo={inSearchArticles}
                setTriggerFetchUseEffect={setTriggerInSearchFetchData}
                recentSearchArticlesId={
                  activeTab === 999 && dashboardType === 'people'
                    ? articleType?.rawData?.recentSearchId
                    : recentSearchId
                }
                storeTags={storeTags}
                storeArticleCommentsTags={storeArticleCommentsTags}
              />
            }
            padding="0"
            borderRadius="0.75rem"
            width="25rem"
            Cross={false}
          />
        </ArticleSectionComponentWrp>
      </SearchResultWrp>
    </SearchMainWrp>
  );
};

ArticleGraph.propTypes = {
  callAIInsight: PropTypes.bool,
  setSummaryData: PropTypes.func,
  setSummaryLoading: PropTypes.func,
  summaryData: PropTypes.string,
  summaryLoading: PropTypes.bool,
  recentSearchId: PropTypes.number,
  tileDetails: PropTypes.array,
  dashboardDetails: PropTypes.array,
  advancedWidgetDetails: PropTypes.object,
  customCanvas: PropTypes.object,
  volumeAnalysisWidgetDetails: PropTypes.object,
  brandEngagementMetrics: PropTypes.object,
  sentimentAnalysisWidgetDetails: PropTypes.object,
  sentimeOverTimeWidgetDetails: PropTypes.object,
  seasonalTrendsWidgetDetails: PropTypes.object,
  coverageOverTimeBrandWidgetDetails: PropTypes.object,
  coverageOverTimeBrandSocialWidgetDetails: PropTypes.object,
  reachOvertimeBrandWidgetDetails: PropTypes.object,
  reachOvertimeBrandSocialWidgetDetails: PropTypes.object,
  SOVWidgetDetails: PropTypes.object,
  mediaContributorsCompWidgetDetails: PropTypes.object,
  coverageByWebsiteCompWidgetDetails: PropTypes.object,
  topJournalistSentimentAnalysisWidgetDetails: PropTypes.object,
  peopleCoverageChartDataWidgetDetails: PropTypes.object,
  peopleCoverageSocialChartDataWidgetDetails: PropTypes.object,
  peopleThemeWidgetDetails: PropTypes.object,
  peopleMediaTypeWidgetDetails: PropTypes.object,
  peopleCoverageOnKeyTopicsChartDetails: PropTypes.object,
  peopleTopScoreDetails: PropTypes.object,
  coverageOverTimeCompWidgetDetails: PropTypes.object,
  coverageOverTimeSocialCompWidgetDetails: PropTypes.object,
  mediaTypeWidgetDetails: PropTypes.object,
  peopleVolumeAnalysisWidgetDetails: PropTypes.object,
  peopleVolumeComparisonData: PropTypes.object,
  peopleReachOverTimeComparisonData: PropTypes.object,
  peopleImpactWidgetDetails: PropTypes.object,
  peopleSentimentAnalysisWidgetDetails: PropTypes.object,
  sentimentCompWidgetDetails: PropTypes.object,
  reachOvertimeCompWidgetDetails: PropTypes.object,
  topPerformingPostsBrandWidgetDetails: PropTypes.object,
  reachOvertimeSocialCompWidgetDetails: PropTypes.object,
  compMentionedWidgetDetails: PropTypes.object,
  brandCompMentionedWidgetDetails: PropTypes.object,
  brandSocialWordCloudWidgetDetails: PropTypes.object,
  brandResultOverTimeWidgetDetails: PropTypes.object,
  mediaBreakdownWidgetDetails: PropTypes.object,
  journalistWidgetDetails: PropTypes.object,
  sourceCompWidgetDetails: PropTypes.object,
  placeholderGraphs: PropTypes.bool,
  searchedQuery: PropTypes.object,
  handleGraphTitleUpdate: PropTypes.func,
  searchFilters: PropTypes.object,
  customData: PropTypes.object,
  storeSelectedItems: PropTypes.object,
  industryVolumeAnalysisWidgetDetails: PropTypes.object,
  industrySentimentWidgetDetails: PropTypes.object,
  industryCoverageOverTimeWidgetDetails: PropTypes.object,
  industryCoverageSocialOverTimeWidgetDetails: PropTypes.object,
  industryCoverageBySourceWidgetDetails: PropTypes.object,
  industryCompaniesWidgetDetails: PropTypes.object,
  industryCoverageByJournalistsWidgetDetails: PropTypes.object,
  industryTopInfluencerWidgetData: PropTypes.object,
  industryPublicationsWidgetDetails: PropTypes.object,
  storyAnalysisResultOverTimeWidgetDetails: PropTypes.object,
  storyAnalysisMediaTypeWidgetDetails: PropTypes.object,
  sentimeWidgetDetails: PropTypes.object,
  topThemeWidgetDetails: PropTypes.object,
  wordCloudWidgetDetails: PropTypes.object,
  topInfluencerWidgetDetails: PropTypes.object,
  topSourceWidgetDetails: PropTypes.object,
  outletMediaTypeWidgetDetails: PropTypes.object,
  geographicalWidgetDetails: PropTypes.object,
  isSavePopup: PropTypes.bool,
  resetTimer: PropTypes.bool,
  notifyOnceComplete: PropTypes.bool,
  setNotifyOnceComplete: PropTypes.func,
  onDownloadChartData: PropTypes.func,
  totalCount: PropTypes.number,
  accessTypeViewUser: PropTypes.bool,
  setLastUpdateStripFlag: PropTypes.func,
  lastUpdateStripFlag: PropTypes.bool,
  stripText: PropTypes.string,
  storyPercentage: PropTypes.string,
  setStripText: PropTypes.func,
  isDocDownloading: PropTypes.bool,
  sentimeOverTimeSocialWidgetDetails: PropTypes.object,
  peopleTopThemesWidgetDetails: PropTypes.object,
  dashboardDataById: PropTypes.object,
  showSaved: PropTypes.bool,
  savedSelectedChart: PropTypes.array,
  filterType: PropTypes.string,
  resultsShown: PropTypes.string,
  handleSelectedChartDropdown: PropTypes.func,
  dashboardKeywords: PropTypes.array,
  selectedChartDropdown: PropTypes.object,
};

export const crossSVG = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.64961 10.2001L6.99961 7.8501L9.34961 10.2001L10.1996 9.3501L7.84961 7.0001L10.1996 4.6501L9.34961 3.8001L6.99961 6.1501L4.64961 3.8001L3.79961 4.6501L6.14961 7.0001L3.79961 9.3501L4.64961 10.2001ZM6.99961 13.4001C6.12183 13.4001 5.29405 13.2334 4.51628 12.9001C3.7385 12.5668 3.05794 12.1084 2.47461 11.5251C1.89128 10.9418 1.43294 10.2612 1.09961 9.48343C0.766276 8.70565 0.599609 7.87788 0.599609 7.0001C0.599609 6.11121 0.766276 5.28065 1.09961 4.50843C1.43294 3.73621 1.89128 3.05843 2.47461 2.4751C3.05794 1.89176 3.7385 1.43343 4.51628 1.1001C5.29405 0.766764 6.12183 0.600098 6.99961 0.600098C7.8885 0.600098 8.71905 0.766764 9.49128 1.1001C10.2635 1.43343 10.9413 1.89176 11.5246 2.4751C12.1079 3.05843 12.5663 3.73621 12.8996 4.50843C13.2329 5.28065 13.3996 6.11121 13.3996 7.0001C13.3996 7.87788 13.2329 8.70565 12.8996 9.48343C12.5663 10.2612 12.1079 10.9418 11.5246 11.5251C10.9413 12.1084 10.2635 12.5668 9.49128 12.9001C8.71905 13.2334 7.8885 13.4001 6.99961 13.4001Z"
      fill="white"
    />
  </svg>
);

export const mailSVG = (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 14C1.0875 14 0.734375 13.8531 0.440625 13.5592C0.146875 13.2653 0 12.9119 0 12.4992V3.49417C0 3.08139 0.146875 2.72917 0.440625 2.4375C0.734375 2.14583 1.0875 2 1.5 2H9.52083C9.49306 2.23611 9.48611 2.48264 9.5 2.73958C9.51389 2.99653 9.55556 3.25 9.625 3.5H1.5L8 7.22917L10.9167 5.54167C11.1111 5.72222 11.3681 5.88889 11.6875 6.04167C12.0069 6.19444 12.2917 6.30556 12.5417 6.375L8 9L1.5 5.27083V12.5H14.5V6.36542C14.7778 6.30236 15.0382 6.20486 15.2812 6.07292C15.5243 5.94097 15.7639 5.79167 16 5.625V12.4952C16 12.9151 15.8507 13.2708 15.5521 13.5625C15.2535 13.8542 14.9028 14 14.5 14H1.5ZM13.5 5C12.8056 5 12.2153 4.75694 11.7292 4.27083C11.2431 3.78472 11 3.19444 11 2.5C11 1.80556 11.2431 1.21528 11.7292 0.729167C12.2153 0.243056 12.8056 0 13.5 0C14.1944 0 14.7847 0.243056 15.2708 0.729167C15.7569 1.21528 16 1.80556 16 2.5C16 3.19444 15.7569 3.78472 15.2708 4.27083C14.7847 4.75694 14.1944 5 13.5 5Z"
      fill="white"
    />
  </svg>
);

export default ArticleGraph;

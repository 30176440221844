import React, { useEffect, useRef, useState } from 'react';
import {
  CommentarySection,
  HalfSlot,
  IconBox,
  SlotBody,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  SlotHeader,
  SlotHeaderLeft,
  SlotOverviewWrapper,
  SlotTitle,
  StyledMarkdown,
} from '../../../components/search-result/index.sc';
import { ConcentricPieChart } from '../../../graphs';
import HelpIcon from '../../../assets/icons/HelpIcon';
import SlotOverview from '../../../components/search-result/slot-details/SlotOverview';
import { SubTitleSmallWrp } from '../../../constants/graph-colors/index.sc';
import ChartToolTip from '../../../components/chart-tool-tip';
import { SlotBodyMain } from '../../../components/advanced-dashboard/author-impact/index.sc';
import Proptypes from 'prop-types';
import PortalTooltip from '../../../components/portal-tooltip';
import SocialTooltip from '../../../components/advanced-dashboard/social-campaign/tooltip';
import Edit2 from '../../../assets/icons/Edit2';
import { Iconwpr } from '../../../components/half-full/index.sc';
import { VerticleDots } from '../../../assets/icons/VerticleDots';
import SimpleReusableDropDown from '../../../components/simple-dropdown';
import { FullSlot } from '../../../components/search-result-v2/index.sc';
import DashboardPopup from '../../../components/dasboard-popup';
import EditGraphPopup from '../../../components/edit-graph-popup';
import { useParams } from 'react-router-dom';
import ThreeDotsLoader from '../../../components/three-dots-loader';
import EnlargedCard from '../../../components/search-result/slot-details/EnlargedCard';
import { useSelector } from 'react-redux';
import { reader } from '../../../constants';
import Prompt from '../../../components/search-result/slot-details/Prompt';

const PopularTopics = ({
  idx,
  selected,
  widget,
  resetSelection,
  handleOnClick,
  type,
  j,
  dashboardType = 'overview',
  editChart,
  downloadFunction,
  graphDownloading,
  onDownloadChartData,
  selectedComponent,
  setSelectedComponent,
  handleUpdatedChart,
  handleGraphTitleUpdate,
  chartNames,
  customClassName,
  insight,
  insightLoading,
  onPromptSubmit,
  widgetClassName,
  promptData,
}) => {
  const { dashboardId } = useParams();
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [chartToolTip, setChartToolTip] = useState();
  const [openActionDropdown, setOpenActionDropDown] = useState(false);

  const titleRef = useRef(null);
  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });
  const [activeChart, setActiveChart] = useState(null);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [editChart2, setEditChart2] = useState({
    chartName: editChart?.chartName || widget?.title,
    chartType: widget?.graphType || '',
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [cardData, setCardData] = useState(null); // Store the card's data
  const role = useSelector((state) => state?.user?.role);

  const downloadRef = useRef(null);
  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (downloadRef.current && !downloadRef.current.contains(event.target)) {
      setOpenActionDropDown(false);
    }
  };
  const graphData = { ...widget, graphType: widget?.data?.graphType };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const actionDropDownOptions = [
    {
      label: 'Download Image',
      type: 'Image',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    }, // Replace <Icon1 /> with your actual icon component
    {
      label: 'Download Pdf',
      type: 'PDF',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Excel',
      type: 'xlsx',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData);
      },
    },
    {
      label: 'Download CSV',
      type: 'csv',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData);
      },
    },
  ];
  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;

      setIconPosition({
        left: titleWidth + 24, // Added width of icon and padding
      });
    }
  }, [widget?.data?.data?.title]);

  const tooltipEnabled = true;

  const handleMouseEnter = (event, d, i, chartId) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setToolTipPos({
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d.data || d;
      setTooltipData({
        data: {
          ...tData,
          value: tData?.articleCount,
        },
        rawData: d?.rawData,
      });
      setActiveChart(chartId);
    }
  };

  const handleMouseMove = (event, d, i, chartId) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };

  const handleMouseLeave = (event, d, i, chartId) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
      setActiveChart(null);
    }
  };
  const handleOptionIcon = (e, componentName) => {
    // e.stopPropagation();
    setSelectedComponent(componentName);
    setOpenActionDropDown((prev) =>
      prev !== componentName ? componentName : false
    );
  };

  const defaultConfig = (chartId) => {
    const config = {
      handleMouseEnter: (event, d, i) => handleMouseEnter(event, d, i, chartId),
      handleMouseMove: (event, d, i) => handleMouseMove(event, d, i, chartId),
      handleMouseLeave: (event, d, i) => handleMouseLeave(event, d, i, chartId),
      handleOnClick: (event, d) => handleOnClick(event, d, chartId),
    };

    if (dashboardType === 'grid-dashboard') {
      delete config.handleOnClick;
    }

    return config;
  };

  const handleGraphEditClick = (e) => {
    setPopupIsOpen(!popupIsOpen);
  };

  const handleUpdateGraph = async (e, data, updatedChartData) => {
    e.stopPropagation();

    handleUpdatedChart(data);
    const chartData = chartNames?.data?.find(
      (chart) => chart?.default_name === graphData?.title
    );
    handleGraphTitleUpdate(
      updatedChartData,
      data,
      chartData,
      customClassName,
      data?.chartType
    );
    handleGraphEditClick(e);
  };

  const handleEditChart = (data) => {
    setEditChart2(data);
  };

  const onOpenPopup = (event, data) => {
    // Get the container using an ID or class
    const container = document.querySelector('#container');
    const containerRect = container.getBoundingClientRect(); // Container bounds

    const cardRect = event.target.closest('.card').getBoundingClientRect(); // Ensure the card is targeted

    // Calculate position relative to the container
    const cardPosition = {
      top: cardRect.top - containerRect.top,
      left: cardRect.right - cardRect.left,
      width: cardRect.width,
      height: cardRect.height,
    };

    // Decide the expansion direction based on the card's position
    const isCloserToLeft =
      cardRect.left + cardRect.width / 2 < containerRect.width / 2;

    setCardData({
      index: 0,
      position: cardPosition,
      direction: isCloserToLeft ? 'right' : 'left',
    });
    setIsPopupOpen((old) => !old);
  };

  const graph = (
    <SlotDetailsWrp
      hideGap={true}
      peopleTheme={widget?.customClassName === 'people_popular_topic_themes'}
      className="card"
    >
      <SlotHeader
        className="hide-download"
        style={{ padding: `1.25rem ${isPopupOpen ? '0' : '1.25rem'}` }}
      >
        <SlotHeaderLeft>
          <div>
            <SlotHeaderLeft>
              {' '}
              <SlotTitle ref={titleRef}>
                {dashboardId
                  ? editChart?.chartName
                  : editChart2?.chartName || 'Popular Topics'}
              </SlotTitle>
              <span
                style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                onMouseEnter={() => setChartToolTip(true)}
                onMouseLeave={() => setChartToolTip(false)}
              >
                <HelpIcon />
              </span>
            </SlotHeaderLeft>

            {chartToolTip && (
              <ChartToolTip
                text={widget?.text}
                dashboardType={true}
                componentTop={8.5}
                componentLeft={iconPosition?.left}
              />
            )}
            <SlotOverviewWrapper>
              <SlotOverview summary={widget?.data?.data?.summary} />
            </SlotOverviewWrapper>
          </div>
        </SlotHeaderLeft>
        <IconBox>
          {dashboardType === 'overview' && (
            <Iconwpr
              width={'1.5rem'}
              height={'1.5rem'}
              onClick={(event) => {
                onOpenPopup(event, widget);
              }}
              className="hide-downloading"
            >
              <AIIcon />
            </Iconwpr>
          )}
          <Iconwpr
            width={'1.5rem'}
            height={'1.5rem'}
            className="hide-downloading"
            onClick={() => handleGraphEditClick(widget)}
          >
            <Edit2 />
          </Iconwpr>
          {role !== reader && isPopupOpen === false && (
            <div style={{ marginLeft: '5px' }}>
              <Iconwpr
                width={'1.5rem'}
                height={'1.5rem'}
                onClick={(e) => {
                  handleOptionIcon(e, graphData.component);
                }}
                ref={downloadRef}
                className="hide-downloading"
              >
                <VerticleDots
                  color={
                    openActionDropdown === selectedComponent
                      ? '#675ef2'
                      : '#5C5E60'
                  }
                />
                <SimpleReusableDropDown
                  isOpen={openActionDropdown === selectedComponent}
                  options={actionDropDownOptions}
                  graphDownloading={graphDownloading}
                  setIsOpen={setOpenActionDropDown}
                />
              </Iconwpr>
            </div>
          )}
        </IconBox>
      </SlotHeader>
      {/* <div style={{ padding: '0 1rem' }}>
            <SlotOverviewWrapper>
              {widget.show ? (
                <SlotOverview summary={widget?.data?.data?.summary} />
              ) : (
                <SubTitleSmallWrp>--</SubTitleSmallWrp>
              )}
            </SlotOverviewWrapper>
          </div> */}
      <ConcentricPieChart
        data={widget?.data?.data?.data}
        config={{
          ...defaultConfig(widget.customClassName),
          dashboardType,
          renderKeyword: 3,
        }}
        resetSelection={resetSelection}
        isFullSlot={false}
      />

      {isPopupOpen && (
        <div style={{ paddingTop: '5rem' }}>
          <Prompt
            onPromptSubmit={(prompt) => {
              onPromptSubmit({
                prompt,
                graphId: widgetClassName,
              });
            }}
            data={promptData[widgetClassName]}
            title={`${widget?.title}`}
          />
        </div>
      )}

      {isPopupOpen === false && (
        <SlotBody
          type={type}
          className="commentary"
          style={{ padding: '1rem' }}
        >
          <SlotBodyMain>
            {dashboardType === 'overview' && (
              <div>
                <CommentarySection className="hide-download">
                  {!insightLoading ? (
                    <StyledMarkdown>{insight?.data?.summary}</StyledMarkdown>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        minHeight: '3rem',
                        alignItems: 'center',
                      }}
                    >
                      <ThreeDotsLoader />
                    </div>
                  )}
                </CommentarySection>
              </div>
            )}

            {enableTooltip && (
              <PortalTooltip
                isOpen={true}
                pos={toolTipPos}
                align={
                  toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'
                }
                vAlign={
                  toolTipPos.top > window.innerHeight / 2 ? 'top' : 'bottom'
                }
              >
                <SocialTooltip tooltipData={tooltipData} widget={widget} />
              </PortalTooltip>
            )}
          </SlotBodyMain>
          {isPopupOpen && (
            <div style={{ padding: '8px' }}>
              <Prompt
                onPromptSubmit={(prompt) => {
                  onPromptSubmit({
                    prompt,
                    graphId: widgetClassName,
                  });
                }}
                data={promptData[widgetClassName]}
                title={`${widget?.title}`}
              />
            </div>
          )}
        </SlotBody>
      )}
    </SlotDetailsWrp>
  );

  const slotData = (
    <SlotDetailsMainWrp
      className={widget.customClassName}
      ref={containerRef}
      // style={{ padding: '10px' }}
    >
      {graph}
      <DashboardPopup
        open={popupIsOpen}
        toggler={handleGraphEditClick}
        width={'40%'}
        popContent={
          <EditGraphPopup
            popupIsOpen={popupIsOpen}
            handleEditClick={handleUpdateGraph}
            handleClose={handleGraphEditClick}
            widgetTitle={editChart?.chartName}
            handleEdit={handleEditChart}
            widget={graphData}
          />
        }
      />
      {isPopupOpen && (
        <EnlargedCard
          top={'20%'}
          left={'30px'}
          width={'800px'}
          height={'548px'}
          setShow={setIsPopupOpen}
          animationDirection={cardData?.direction}
        >
          {graph}
        </EnlargedCard>
      )}
    </SlotDetailsMainWrp>
  );

  return dashboardType === 'grid-dashboard' ? (
    <FullSlot
      className="graph-widget"
      key={`widget-${idx}`}
      selected={idx === selected}
      id={`childDiv-${j}`}
      style={{ height: '27rem' }}
      // onClick={(e) => handleClick(idx, e)}
    >
      {slotData}
    </FullSlot>
  ) : (
    <HalfSlot
      className="graph-widget"
      key={`widget-${idx}`}
      selected={idx === selected}
      id={`childDiv-${j}`}
      // onClick={(e) => handleClick(idx, e)}
    >
      {slotData}
    </HalfSlot>
  );
};

export default PopularTopics;

PopularTopics.defaultProps = {
  type: 'dashboard',
};

PopularTopics.propTypes = {
  idx: Proptypes?.number,
  selected: Proptypes.number,
  widget: Proptypes.object,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  type: Proptypes.string,
  j: Proptypes.number,
  dashboardType: Proptypes.string,
  graphDownloading: Proptypes.string,
  editChart: Proptypes.object,
  downloadFunction: Proptypes.func,
  onDownloadChartData: Proptypes.func,
  selectedComponent: Proptypes.string,
  setSelectedComponent: Proptypes.func,
  handleGraphTitleUpdate: Proptypes.func,
  handleUpdatedChart: Proptypes.func,
  chartNames: Proptypes.object,
  customClassName: Proptypes.string,
  insight: Proptypes.object,
  insightLoading: Proptypes.bool,
  widgetClassName: Proptypes.string,
  onPromptSubmit: Proptypes.func,
  promptData: Proptypes.object,
};

const AIIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18018_10461)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2308 24H-3.23047C-0.350515 19.7745 4.50082 17 10.0002 17C15.4995 17 20.3509 19.7745 23.2308 24Z"
          fill="#878D96"
        />
        <path
          d="M15 1L15.0976 1.39546C15.4028 2.63182 16.3682 3.59716 17.6045 3.90237L18 4L17.6045 4.09763C16.3682 4.40284 15.4028 5.36818 15.0976 6.60454L15 7L14.9024 6.60454C14.5972 5.36818 13.6318 4.40284 12.3955 4.09763L12 4L12.3955 3.90237C13.6318 3.59716 14.5972 2.63182 14.9024 1.39546L15 1Z"
          fill="#878D96"
        />
        <path
          d="M8 3L8.66678 5.70096C8.98726 6.99914 10.0009 8.01274 11.299 8.33322L14 9L11.299 9.66678C10.0009 9.98726 8.98726 11.0009 8.66678 12.299L8 15L7.33322 12.299C7.01274 11.0009 5.99914 9.98726 4.70096 9.66678L2 9L4.70096 8.33322C5.99914 8.01274 7.01274 6.99914 7.33322 5.70096L8 3Z"
          fill="#878D96"
        />
      </g>
      <defs>
        <clipPath id="clip0_18018_10461">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import PropTypes from 'prop-types';
import React from 'react';

const SocialAnalysisIcon = ({ hoverColor = false }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_15755_42947"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_15755_42947)">
        <path
          d="M4.22266 18.5064V16.5624H19.7747V18.5064H4.22266ZM4.22266 14.6184V12.6744H19.7747V14.6184H4.22266ZM4.22266 10.7304V8.46244L10.0547 4.89844L14.9147 8.3647L19.7747 4.89844V7.27984L14.9147 10.7304L9.98176 7.23124L4.22266 10.7304Z"
          fill={hoverColor ? '#fff' : '#4D5358'}
        />
      </g>
    </svg>
  );
};

export default SocialAnalysisIcon;

SocialAnalysisIcon.propTypes = {
  hoverColor: PropTypes.string,
};

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ArticleSection,
  CheckInp,
  IconBoxwpr,
  // IconWrapper,
  Iconwpr,
  Paginatewpr,
  SectionMainWrp,
  // Paginatewpr,
  SectionTitle,
  Sectionwpr,
  TabPopwpr,
  Popover,
  PopoverItem,
  // IconWraper,
} from '../index.sc';
// import IconBox from '../IconBox';
import Tabs from '../../tabs';
import ArticleDetails from '../../articles/article-details';
import Articles from '../../articles';
// import Pagination from '../../pagination';
import {
  useArticleData,
  useDeleteArticleTagBySearchId,
  useGetAllHiddenArticlesBySearchId,
  useGetArticlesThemeData,
  useRenameArticleTags,
} from '../../../hooks/useSearch';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// import Spinner from '../../spinner';
import { TitleBox } from '../../tabs/TabTitle';
import {
  ArticleDetialtsWrp,
  DashboardListwpr,
  HeadingWrp,
  // LinkSpan,
  LoadingWrp,
  NoResultsDesp,
  NoResultsTitle,
  NoResultsWrp,
  TabButton,
  ThemeTabs,
  Themetabtxt,
  ArticleCount,
  CheckBoxWrp,
  ArrowContainer,
  OptionWrapper,
} from './index.sc';
import { theme } from '../../../constants/theme';
import DownIcon from '../../../assets/icons/DownIcon';
import { useSelector } from 'react-redux';
import { axiosPostRequest } from '../../../service';
import { ArticleTheme } from '../../tabs/CardTitle';
import Pagination from '../../pagination';
import IconBox, { dropdownOptions } from '../IconBox';
// import useDebounce from '../../../hooks/useDebounce';
// import XCirlcle from '../../../assets/icons/XCirlcle';
import CircularLoading from '../../../assets/icons/loading/circularLoading';
import { addCountPrefix } from '../../../constants/utils';
import { VerticleDots } from '../../../assets/icons/VerticleDots';
import SimpleReusableDropDown from '../../simple-dropdown';
import SortDropdown from '../SortDropdown';
import { useLocation } from 'react-router-dom';
import { CustomPaginationWrapper } from '../../pagination/index.sc';
import CrossCircle from '../../../assets/icons/CrossCircle';
import CheckboxIcon from '../../../assets/icons/CheckboxIcon';
import ArrowLeft from '../../../assets/icons/ArrowLeft';
import { reader } from '../../../constants';
import DashboardPopup from '../../dasboard-popup';
import CustomConfirmationPopUp from '../../customize-confirmation-popup';
import EditTag from '../EditTag';
import toast from 'react-hot-toast';
import DownloadIcon3 from '../../../assets/icons/DownloadIcon3';
import Search3 from '../../../assets/icons/Search3';
import { coolGrayColorGradients } from '../../../constants/graph-colors';
import StoryAnalysisPopup from '../../story-analysis-popup';
import { useStoryAnalysisArticles } from '../../../hooks/useStoryAnalysisCharts';
import { trackEvent } from '../../../utils/mixPanel';

const ArticleSectionComponent = ({
  activeScreen,
  articleType,
  page,
  setPage,
  type,
  setType,
  id,
  articlePosition,
  floatingPagination,
  setSelected,
  setSelectedGraph,
  setResetSelection,
  showTabs = true,
  showFullTitle = true,
  showHeading = false,
  loader = false,
  articlesInfo,
  articlePagingInfo,
  isLoading,
  searchKey = '',
  setSearchKey = () => {},
  sortOrder,
  setSortOrder = () => {},
  titleTabs,
  setClickedPosition,
  setArticleType,
  onBtnClick,
  setSydicationActive,
  setSydicationArticles,
  sydicationActive,
  sydicationArticles,
  articlesRecentSearchId,
  setTriggerFetchUseEffect,
  setTriggerFetchTags,
  storeArticleCommentsTags,
  storeComments,
  setStoreComments,
  storeTags,
  setStoreTags,
  queryPayload = () => {},
  query = '',
  filters = {},
  setIsCustomPagiNationFlag,
  resetAllChecked,
  toggleResetAllChecked,
  setTags,
  setSyndicationClick,
  name,
  syndicationClickData,
  activeTheme = [],
  setActiveTheme,
  checked = [],
  setChecked = () => {},
  setDownLoadArticleFlag = () => {},
  setDownloadSelected = () => {},
  isActiveScreenSelected,
  bookmarksLocal,
  hiddenArticlesLocal,
  liveArticleCount,
  onClick = () => {},
  isPopup = false,
  summaryData = {},
  eventCycle = false,
  setMultipleTagFlag,
  setAllTags = () => {},
  tagActionType,
  setFetchAiSummary = () => {},
  fetchAiSummary = false,
  setArticles = () => {},
  setHiddenArticleSwitch = () => {},
  hiddenArticleSwitch = false,
  setScrollHeight = () => {},
  setSelectedArticle = () => {},
}) => {
  const [filter, setFilter] = useState('');
  const [hiddenArticle, setHiddenArticle] = useState();
  const limit = 50;

  const role = useSelector((state) => state?.user?.role);

  const {
    // error: articleerror,
    data,
    // isFetching,
  } = useArticleData(
    limit,
    page,
    type,
    activeTheme,
    searchKey,
    sortOrder,
    filter,
    id,
    true,
    articleType.widget,
    articleType.graphSelection,
    sydicationArticles
  );
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState();
  const [showTab, setShowTab] = useState(false);
  const [openActionDropdown, setOpenActionDropDown] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [sortPopUp, setSortPopUp] = useState(false);
  const [hiddenArticleTigger, setHiddenArticleTigger] = useState(false);
  const [hiddenArticleFromQuery, setHiddenArticleFromQuery] = useState('');
  const [customPageNum, setCustomPageNum] = useState('');
  const [isCustomPagiNation, setIsCustomPagiNation] = useState(false);
  const [showPopup, setShowPopup] = useState(null);
  const [confirmationPopUp, setConfirmationPopUp] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [hiddenArticlePage, setHiddenArticlePage] = useState(0);

  const [editTagPopUp, setEditTagPopUp] = useState(false);

  const [storyAnalysisPopup, setStoryAnalysisPopup] = useState(false);
  const [activeButton, setActiveButton] = useState('Story Analysis');
  const [storyAnalysisData, setStoryAnalysisData] = useState(null);
  const [similarStoriesData, setSimilarStoriesData] = useState(null);
  const [similarStoriesArticles, setSimilarStoriesArticles] = useState(null);
  const [similarStoriesIds, setSimilarStoriesIds] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const isSimilarFetching = useRef(false);

  const {
    mutateAsync: getStoryAnalysisData,
    isLoading: storyAnalysisArticleLoader,
  } = useStoryAnalysisArticles();

  const { mutateAsync: renameTag } = useRenameArticleTags();
  const queryClient = useQueryClient();

  const showOptRef = useRef();
  const popupRef = useRef();
  const iconBoxRef = useRef(null);
  const tagsRef = useRef(null);
  const { state } = useLocation();
  const recentSearchId = state?.savedSearchData?.recent_search_id;

  // eslint-disable-next-line no-unused-vars
  // const { data: themeTabs, isLoading: themeTabLoading } = useQuery({
  //   queryKey: ['themeTab', id],
  //   queryFn: () => axiosGet('/article-theme', { id }),
  //   refetchOnWindowFocus: false,
  //   enabled: !!id,
  // });

  // update hidden article state if popup is closed
  useEffect(() => {
    if (!hiddenArticleSwitch) {
      setHiddenArticleTigger(false);
    } else {
      setHiddenArticleTigger(true);
    }
  }, [hiddenArticleSwitch]);

  const { data: articlesThemeData, isLoading: articleThemeLoading } =
    useGetArticlesThemeData(
      articlesRecentSearchId,
      !!recentSearchId || !!articlesRecentSearchId
    );

  const { mutateAsync: deleteTag } = useDeleteArticleTagBySearchId();

  const themetabs = articlesThemeData?.data?.map((item, i) => {
    return {
      ...item,
      title: <ArticleTheme title={item?.name} />,
      tag: item?.name,
      id: i,
      item,
    };
  });

  const handleDeleteTag = async () => {
    try {
      const payload = {
        tags: [selectedTag?.name],
        article_id: [],
        recent_search_id: articlesRecentSearchId,
      };
      const resp = await deleteTag(payload);
      toast.success(resp?.data?.msg);
    } catch (error) {
      console.log(error);
    } finally {
      setConfirmationPopUp(false);
      setActiveTheme && setActiveTheme([]);
      setStoreTags && setStoreTags([]);
      setArticles && setArticles(null);
      setPage && setPage(0);
      setTriggerFetchTags && setTriggerFetchTags((old) => !old);
    }
  };

  useEffect(() => {
    if (resetAllChecked) {
      setAllChecked(false);
      setChecked([]);
      toggleResetAllChecked && toggleResetAllChecked();
    }
  }, [resetAllChecked]);

  useEffect(() => {
    if (articlesThemeData?.data) {
      const allTags = articlesThemeData?.data?.map((x) => x?.name);
      setAllTags(allTags);
      if (activeTheme?.length > 0) {
        setTags &&
          setTags((prevTags) =>
            prevTags?.filter((tag) => allTags.some((y) => y === tag))
          );
      }
    }
  }, [articlesThemeData?.data]);

  const handlePage = (pageNum) => {
    if (hiddenArticleTigger) {
      setHiddenArticlePage(pageNum);
    } else {
      setPage(pageNum);
      setIsCustomPagiNationFlag(false);
    }
  };

  useEffect(() => {
    // if (type !== activeTab?.value) {
    const tab = titleTabs?.find((title) => title?.value === type);
    if (tab) {
      setActiveTab({ ...tab });
    } else {
      setActiveTab(null);
    }
    // }
  }, [type, titleTabs]);

  useEffect(() => {
    if (page && !isLoading && allChecked) {
      const pageIndex = articlesInfo.map((data, i) => {
        return data?.articleId;
      });
      setChecked(pageIndex);
    }
  }, [allChecked, articlesInfo, isLoading, page]);

  const tabs = titleTabs?.map((ele, i) => ({
    ...ele,
    title: <TitleBox title={ele.label} des={ele.count} />,
    id: i,
  }));

  const handleChecked = (option) => {
    const newCategory = [...checked];
    if (checked.includes(option)) {
      newCategory.splice(checked.indexOf(option), 1);
      setAllChecked(false);
    } else {
      newCategory.push(option);
      if (newCategory.length === articlesInfo.length) {
        setAllChecked(true);
      }
    }
    setChecked(newCategory);
    // make a api call in seperate function
  };

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  // console.log(count, 'total count for pagination');

  useEffect(() => {
    setTotal(titleTabs?.find((x) => x.value === type)?.count || 0);
  }, [titleTabs, type]);

  const handleTabs = (index) => {
    setPopoverOpen(false);
    setType(tabs[index]?.value);
    setActiveTheme([]);
    setPage(0);
  };

  const updateBookmark = (payload) => {
    return axiosPostRequest('/articles', {}, payload);
  };

  const { mutate: mutateFunction } = useMutation({
    mutationFn: updateBookmark,
  });

  const bookMarkedArticles = data?.data?.data
    .filter((article) => article.bookmarked)
    .map((item) => item.id);
  const taggedArticles = data?.data?.data
    .filter((article) => article.tags !== '')
    .map((item) => item.id);

  const downLoadFunction = (value) => {
    mutateFunction({
      downloadType: value,
      selectedArticles:
        value === 'selected'
          ? checked
          : value === 'bookmarked'
          ? bookMarkedArticles
          : value === 'tagged'
          ? taggedArticles
          : [],
    });
  };

  const { data: allHiddenArticles, refetch: refetchHiddenArticles } =
    useGetAllHiddenArticlesBySearchId(
      {
        ...queryPayload,
        recent_search_id: articlesRecentSearchId,
        page_number: 1,
        page_size: 250,
        is_hidden: 'True',
      },
      (!!recentSearchId || !!articlesRecentSearchId) && hiddenArticleTigger
    );

  // set hidden articles in state

  useEffect(() => {
    setHiddenArticleFromQuery(allHiddenArticles);
  });

  const actionDropDownOptions = [
    {
      label: activeScreen === 'article' ? 'Collapsed View' : 'Expand View',
      clickFunction: (option) => onBtnClick('article'),
    },
    {
      label: hiddenArticleTigger ? 'All Results' : 'Hidden Results',
      clickFunction: () => {
        setHiddenArticleTigger(!hiddenArticleTigger);
        setHiddenArticleFromQuery();
        setArticles && setArticles(null);
        setPage(0);
        setTags && setTags([]);
        setHiddenArticleSwitch(!hiddenArticleTigger);
        // hiddenArticleTigger
        // ? refetchHiddenArticles()
        id !== 'custom-search'
          ? setTriggerFetchUseEffect((prev) => !prev)
          : setHiddenArticle((prev) => prev);
      },
    },
  ];

  const handleClickOutside = (event) => {
    if (showOptRef.current && !showOptRef.current.contains(event.target)) {
      setOpenActionDropDown(false);
    }
    if (iconBoxRef.current && !iconBoxRef.current.contains(event.target)) {
      setSortPopUp(false);
    }
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setPopoverOpen(false);
    }
    if (tagsRef.current && !tagsRef.current.contains(event.target)) {
      setShowPopup(null);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleOptionIcon = (e, componentName) => {
    e.stopPropagation();
    // setSelectedComponent(componentName);
    setOpenActionDropDown(!openActionDropdown);
  };

  const handleAllcheck = (isChecked) => {
    if (isChecked) {
      // for (let i = 0; i < articlesInfo?.length; i++) {
      //   const page = articlesInfo[i];
      // }
      const pageIndex = articlesInfo.map((data, i) => {
        return data?.articleId;
      });
      setChecked(pageIndex);
      trackEvent('Result - Selected', {
        result_id: pageIndex,
      });
      setAllChecked(true);
    } else {
      setChecked([]);
      setAllChecked(false);
    }
  };

  useEffect(() => {
    if (isPopup) {
      setSortOrder('relevance');
    }
    // setHiddenArticleTigger(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articlePosition]);

  const handleSort = (value) => {
    if (sortOrder === value) {
      setSortPopUp(false);
      return;
    }
    setArticles && setArticles(null);
    setSortOrder(value);
    setSortPopUp(false);
    // mixpanel event
    trackEvent('All Results - Sort Filter Usage', {
      sort_option_selected: name,
      frequency_count: '1',
    });
  };

  useEffect(() => {
    if (Array.isArray(articlesInfo)) {
      setHiddenArticle([...articlesInfo]);
    }
  }, [articlesInfo]);

  const endValue = () => {
    if ((id === 'custom-search' || recentSearchId) && hiddenArticleTigger) {
      return hiddenArticleFromQuery?.data?.data?.length
        ? hiddenArticleFromQuery?.data?.data?.length || 0
        : Math.min(
            hiddenArticleFromQuery?.data?.paged?.pageNumber *
              hiddenArticleFromQuery?.data?.paged?.pageSize,
            hiddenArticleFromQuery?.data?.paged?.total
          ) || 0;
    } else {
      // eslint-disable-next-line no-unused-expressions
      return searchKey
        ? articlesInfo?.length || 0
        : Math.min(
            articlePagingInfo?.pageNumber * articlePagingInfo?.pageSize,
            articlePagingInfo?.unique || articlePagingInfo?.total
          );
    }
  };

  const startValue = () => {
    if ((id === 'custom-search' || recentSearchId) && hiddenArticleTigger) {
      return hiddenArticleFromQuery?.data?.paged?.total > 0
        ? (hiddenArticleFromQuery?.data?.paged?.pageNumber - 1) *
            hiddenArticleFromQuery?.data?.paged?.pageSize +
            1
        : 0;
    } else {
      return (articlePagingInfo?.unique || articlePagingInfo?.total) >= 1
        ? (articlePagingInfo?.pageNumber - 1) * articlePagingInfo?.pageSize + 1
        : 0;
    }
  };

  const cumstomPageHandler = (e) => {
    const regexPattern = /^[0-9]{1,10}$/;
    if (e.target.value.match(regexPattern)) {
      setCustomPageNum(e.target.value);
    } else {
      setCustomPageNum('');
    }
  };

  const onClickCustomPage = () => {
    setPage(parseInt(customPageNum) - parseInt(1));
    setChecked([]);
    setAllChecked(false);
    setIsCustomPagiNationFlag(true);
    setIsCustomPagiNation(false);
  };

  const authorArticles = () => {
    if (
      articleType?.otherInfo?.uniqueId === 'topauthor-graph-download' || // Top Media Contributors
      articleType?.otherInfo?.uniqueId === 'coverage_by_journalist' || // Coverage by Media Contributors
      articleType?.otherInfo?.uniqueId ===
        'people_top_journalist_by_sentiment' || // Media Contributors by Sentiment
      articleType?.otherInfo?.uniqueId === 'authorimpact' || // Author Impact
      articleType?.otherInfo?.uniqueId === 'top_influencers' || // Top Influencers
      articleType?.widget === 'advanced_campaign_monitor_top_author' || // Top Authors
      articleType?.otherInfo?.uniqueId === 'competition_coverage_by_websites' || // Brand coverage by Popular Website
      articleType?.otherInfo?.uniqueId === 'competition_media_contributors' // Coverage by Media contributors (Influencers)
    ) {
      return true;
    }
  };

  const tagDropdown = [
    {
      label: 'Edit',
      clickFunction: (tag) => {
        console.log('tag edit image', tag);
        setSelectedTag(tag);
        setEditTagPopUp(true);
        setShowPopup(false);
      },
    }, // Replace <Icon1 /> with your actual icon component
    {
      label: 'Delete',
      clickFunction: (tag) => {
        setSelectedTag(tag);
        setConfirmationPopUp(true);
        setShowPopup(false);
      },
    },
    // {
    //   label: 'View Articles',
    //   clickFunction: (tag) => {
    //     onClickTag(tag);
    //     setShowPopup(false);
    //   },
    // },
  ];
  const togglePopover = () => {
    setPopoverOpen(!isPopoverOpen);
  };
  const onRenameSubmitHandler = async (data) => {
    try {
      const payload = {
        old_tag_value: data?.oldTag,
        new_tag_value: data?.newTag,
        recent_search_id: articlesRecentSearchId,
      };

      const resp = await renameTag(payload);
      queryClient.invalidateQueries(['articleThemeData']);

      toast.success(resp?.data?.msg);
    } catch (error) {
      console.log({ error });
    } finally {
      setEditTagPopUp(false);
      setArticles && setArticles(null);
      setActiveTheme && setActiveTheme([]);
      setStoreTags && setStoreTags([]);
      setPage && setPage(0);
      setTriggerFetchTags && setTriggerFetchTags((old) => !old);
    }
  };

  const getIndexes = (activeTheme) => {
    const indices = [];
    themetabs?.forEach((x, i) => {
      if (
        activeTheme
          ?.map((y) => y?.toLowerCase())
          .includes(x?.tag?.toLowerCase())
      ) {
        indices.push(i);
      }
    });
    return indices;
  };

  const onClickTag = (item, index) => {
    setArticles && setArticles(null);
    setActiveTheme &&
      setActiveTheme((themes) => {
        if (themes?.includes(item?.name)) {
          return themes?.filter((x) => x !== item?.name);
        }
        return [...themes, item?.name];
      });
    setPage && setPage(0);
    setArticles && setArticles(null);
    setTriggerFetchTags && setTriggerFetchTags((old) => !old);
  };

  const articlesToMap = hiddenArticleTigger
    ? allHiddenArticles?.data?.data
    : id === 'custom-search'
    ? hiddenArticle
    : articlesInfo;

  const getCommonCount = () => {
    const commonCount = articlesToMap?.filter((obj1) =>
      hiddenArticlesLocal?.some((obj2) => obj2?.article_id === obj1?.id)
    ).length;

    return commonCount;
  };

  const getSimilarStoriesData = async () => {
    const dtToPushList = [];
    const failedRequests = [];
    if (isSimilarFetching.current) return;
    isSimilarFetching.current = true;
    const processedIds = new Set(
      similarStoriesArticles?.map((article) => article?.articleId)
    );

    const pendingSimilarIds =
      similarStoriesIds?.filter((id) => !processedIds?.has(id)) ||
      similarStoriesIds;

    for (const similarArticleId of pendingSimilarIds) {
      if (
        !processedIds?.has(similarArticleId) &&
        similarArticleId?.length > 0
      ) {
        const storyAnalysisPayload = {
          recent_search_id: recentSearchId || articlesRecentSearchId,
          article_id: similarArticleId,
        };
        try {
          const storyRes = await getStoryAnalysisData(storyAnalysisPayload);
          const dataToPush = storyRes?.data?.articleData?.length > 0 &&
            storyRes?.data?.graphContent?.[0]?.title !== undefined && {
              ...storyRes?.data,
            };

          if (dataToPush) {
            dtToPushList.push(dataToPush);
            setSimilarStoriesData((prevData) => {
              const filterPrevData = prevData?.filter(
                (prevItem) =>
                  prevItem?.articleData?.articleId !==
                  dtToPushList?.[0]?.articleData?.articleId
              );
              return [...(filterPrevData || []), ...dtToPushList];
            });
            setSimilarStoriesArticles((prevArticles) => {
              const existingArticleIds = new Set(
                (prevArticles || []).map((item) => item?.articleId)
              );

              const newUniqueArticles = dtToPushList
                .flatMap((data) => data.articleData)
                .filter(
                  (article) =>
                    !existingArticleIds.has(article.articleId) &&
                    existingArticleIds.add(article.articleId)
                );
              return [...(prevArticles || []), ...newUniqueArticles];
            });
          }
        } catch (err) {
          console.error('Error fetching data', err);
          failedRequests.push(similarArticleId);
        }
        if (failedRequests.length === pendingSimilarIds.length) {
          setErrorMessage(
            'Failed to fetch similar stories. Please try again later.'
          );
        }
      }
    }
    isSimilarFetching.current = false;
  };

  useEffect(() => {
    const abortController = new AbortController();

    if (similarStoriesIds && storyAnalysisPopup && !isSimilarFetching.current) {
      getSimilarStoriesData();
    }
    return () => {
      // Cleanup on unmount or dependency change
      abortController.abort(); // Abort ongoing API calls
    };
  }, [similarStoriesIds, similarStoriesData, storyAnalysisPopup]);

  return (
    <>
      <ArticleSection
        activeScreen={activeScreen}
        articlePosition={articlePosition}
        className={activeScreen === 'article' ? 'active show' : 'show'}
        style={{ position: 'relative' }}
      >
        {!eventCycle && (
          <>
            <SectionMainWrp>
              <Sectionwpr started={true} ref={popupRef}>
                {showFullTitle === 'Syndication' && (
                  <ArrowContainer
                    onClick={() => {
                      setSyndicationClick({});
                    }}
                  >
                    <ArrowLeft />
                  </ArrowContainer>
                )}
                <SectionTitle
                  onClick={() => {
                    if (!hiddenArticleTigger) {
                      togglePopover();
                    }
                  }}
                >
                  {showHeading && !hiddenArticleTigger && (
                    <>
                      {activeTab?.label
                        ? `${activeTab?.label}(${addCountPrefix(
                            activeTab?.count
                          )})`
                        : 'All Results'}
                      <Iconwpr
                        style={{
                          transform: 'rotate(0deg)',
                          transition: 'transform 0.3s ease',
                        }}
                      >
                        <DownIcon />
                      </Iconwpr>
                    </>
                  )}
                  {hiddenArticleTigger && (
                    <HeadingWrp>All Hidden Results</HeadingWrp>
                  )}

                  {showFullTitle && !hiddenArticleTigger && (
                    <HeadingWrp
                      articlePosition={articlePosition}
                      title={showFullTitle}
                    >
                      {showFullTitle}
                    </HeadingWrp>
                  )}

                  {sydicationActive && !hiddenArticleTigger && (
                    <HeadingWrp>Syndication</HeadingWrp>
                  )}
                </SectionTitle>
                {isPopoverOpen && !hiddenArticleTigger && (
                  <Popover>
                    {titleTabs?.map((tab, i) => {
                      return (
                        <PopoverItem
                          key={tab?.label}
                          onClick={() => {
                            setActiveTab(tab);
                            handleTabs(i);
                            // mixpanel event
                            trackEvent('All Results - Dropdown Filter Usage', {
                              filter_selected: tab?.label,
                            });
                          }}
                        >
                          {tab?.label}{' '}
                          <span style={{ marginLeft: '10px' }}>
                            {addCountPrefix(tab?.count)}
                          </span>
                        </PopoverItem>
                      );
                    })}
                  </Popover>
                )}

                {articlePagingInfo?.total > 0 && isPopup && (
                  <ArticleCount>
                    &nbsp;
                    {addCountPrefix(
                      ((id === 'custom-search' ||
                        recentSearchId ||
                        activeScreen) &&
                      hiddenArticleTigger
                        ? hiddenArticleFromQuery?.data?.paged?.total
                        : liveArticleCount || authorArticles()
                        ? articlePagingInfo?.unique -
                          hiddenArticlesLocal?.length
                        : articlePagingInfo?.total -
                          hiddenArticlesLocal?.length) || 0
                    )}
                    &nbsp;Results
                  </ArticleCount>
                )}
                {isPopup && !hiddenArticleTigger && (
                  <DashboardListwpr
                    ref={iconBoxRef}
                    overflow="none"
                    minWidth="none"
                    width="auto"
                    bgColor="none"
                  >
                    <TabButton
                      onClick={() => setSortPopUp(!sortPopUp)}
                      textColor={theme.light.closeButton}
                      fontSize={articlePosition === '' ? '0.8rem' : '0.75rem'}
                    >
                      Sorted by{' '}
                      {sortOrder === 'date'
                        ? 'Date Published'
                        : sortOrder
                            ?.replace(/_/g, ' ')
                            ?.replace(/(?:^|\s)\S/g, (c) => c.toUpperCase())}
                      <Iconwpr
                        style={{
                          transform: sortPopUp
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                          transition: 'transform 0.3s ease',
                        }}
                      >
                        <DownIcon />
                      </Iconwpr>
                    </TabButton>
                    <SortDropdown
                      left="none"
                      bottom="none"
                      marginBottom="none"
                      Open={sortPopUp}
                      setIsDropdownOpen={handleSort}
                      sortOrder={sortOrder}
                      dropdownOptions={dropdownOptions}
                      position={'bottom'}
                    />
                  </DashboardListwpr>
                )}
                {/* {!isPopup && (
              <TabButton onClick={() => setShowTab(!showTab)}>
                {titleTabs?.find((x) => x?.value === type)?.label || ''}
                <span>
                  (
                  {addCountPrefix(
                    titleTabs?.find((x) => x?.value === type)?.count || 0
                  )}
                  )
                </span>
                <Iconwpr>
                  <DownIcon />
                </Iconwpr>
              </TabButton>
            )} */}
              </Sectionwpr>
              <Sectionwpr>
                {/* <CheckBoxWrp>
              <DownloadIcon3 />
            </CheckBoxWrp>
            <CheckBoxWrp>
              <Search3 />
            </CheckBoxWrp> */}
                {articlePagingInfo?.total > 0 && role !== reader && (
                  <>
                    {!hiddenArticleTigger && (
                      <CheckBoxWrp onClick={() => handleAllcheck(!allChecked)}>
                        <CheckboxIcon
                          width="1.5rem"
                          height="1.5rem"
                          checked={allChecked}
                          color={
                            allChecked
                              ? theme[selectedTheme].primary
                              : theme[selectedTheme].background
                          }
                          borderColor={
                            allChecked
                              ? theme[selectedTheme].primary
                              : theme[selectedTheme].inActiverticalDots
                          }
                        />
                      </CheckBoxWrp>
                    )}
                    {role !== reader && (
                      <IconBoxwpr
                        width={'1.5rem'}
                        height={'1.5rem'}
                        onClick={(e) => handleOptionIcon(e, 'article')}
                        ref={showOptRef}
                        bgColor={'#fff'}
                        className="hide-downloading"
                      >
                        <VerticleDots
                          color={openActionDropdown ? '#675ef2' : '#5C5E60'}
                        />
                        <SimpleReusableDropDown
                          isOpen={openActionDropdown}
                          options={
                            articleType?.graphSelection
                              ? [actionDropDownOptions[1]]
                              : [actionDropDownOptions[1]]
                          }
                          setIsOpen={setOpenActionDropDown}
                        />
                      </IconBoxwpr>
                    )}
                  </>
                )}
                {isPopup && (
                  <div
                    onClick={() => {
                      onClick();
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <CrossCircle width="20" height="20" color="#585858" />
                  </div>
                )}
              </Sectionwpr>
            </SectionMainWrp>
            <div
              style={{
                backgroundColor: coolGrayColorGradients.coolGray20,
                height: '1px',
                margin: '0rem 1rem',
              }}
            ></div>
          </>
        )}

        {/* {showTab && (
          <TabPopwpr showTab={showTab}>
            <Tabs
              items={!tabs ? [{}] : tabs}
              widthItem={activeScreen === 'article' ? '7rem' : '20%'}
              variant="underline"
              activeColor="#675EF2"
              inactiveColor="#000000"
              onChange={handleTabs}
              isContent={false}
              bottomBorderWidth="3px"
              paddingWrapper="0.75rem 1rem"
              defaultActive={false}
              currentTab={activeTab}
            />
          </TabPopwpr>
        )} */}
        {!isPopup && !eventCycle && (
          <ArticleDetialtsWrp>
            <ArticleDetails
              isSyndication={articleType.inSyndication}
              start={startValue()}
              end={endValue()}
              total={
                (id === 'custom-search' || recentSearchId) &&
                hiddenArticleTigger
                  ? hiddenArticleFromQuery?.data?.paged?.total
                  : liveArticleCount ||
                    articlePagingInfo?.unique - getCommonCount() ||
                    articlePagingInfo?.total - getCommonCount()
              }
            />

            {!eventCycle &&
              articlePagingInfo?.total > 0 &&
              !hiddenArticleTigger && (
                <DashboardListwpr
                  ref={iconBoxRef}
                  overflow="none"
                  minWidth="none"
                  width="auto"
                  bgColor="none"
                >
                  <TabButton
                    onClick={() => setSortPopUp(!sortPopUp)}
                    textColor={theme.light.closeButton}
                    fontSize={'0.875rem'}
                  >
                    Sorted by{' '}
                    {sortOrder === 'date'
                      ? 'Date Published'
                      : sortOrder
                          ?.replace(/_/g, ' ')
                          ?.replace(/(?:^|\s)\S/g, (c) => c.toUpperCase())}
                    <Iconwpr
                      style={{
                        transform: sortPopUp
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                        transition: 'transform 0.3s ease',
                      }}
                    >
                      <DownIcon />
                    </Iconwpr>
                  </TabButton>
                  <SortDropdown
                    left="none"
                    bottom="none"
                    marginBottom="none"
                    Open={sortPopUp}
                    setIsDropdownOpen={handleSort}
                    sortOrder={sortOrder}
                    dropdownOptions={dropdownOptions}
                    position={'bottom'}
                  />
                </DashboardListwpr>
              )}
          </ArticleDetialtsWrp>
        )}
        {themetabs?.length > 0 &&
        themetabs?.length > 0 &&
        showHeading &&
        !hiddenArticleTigger &&
        !eventCycle ? (
          <ThemeTabs style={{ flexWrap: 'wrap', padding: '6px 8px 6px 8px' }}>
            <Themetabtxt>Tags</Themetabtxt>
            {themetabs?.map((item, i) => (
              <div
                style={{
                  position: 'relative',
                  width: 'fitContent',
                  maxWidth: '100%',
                }}
                key={item?.name}
              >
                <ArticleTheme
                  title={item?.name}
                  onClickVerticalDots={() => {
                    setShowPopup((value) => {
                      if (value === i) {
                        return null;
                      }
                      if ((value || value === 0) && value !== i) {
                        return i;
                      }
                      if (!value) {
                        return i;
                      }
                      return null;
                    });
                  }}
                  isActive={getIndexes(activeTheme)?.includes(i)}
                  onClickTag={() => {
                    setArticles && setArticles(null);
                    onClickTag(item, i);
                  }}
                />
                {showPopup === i && (
                  <div
                    style={{
                      position: 'absolute',
                      right: '0',
                      backgroundColor: 'white',
                      border: '1px solid #F2F4F8',
                      boxShadow: '0px 14px 34px 0px #0000001F',
                      zIndex: 1000,
                      minWidth: '100px',
                      maxWidth: '100px',
                      borderRadius: '6px',
                      padding: '4px',
                    }}
                    ref={tagsRef}
                  >
                    {tagDropdown?.map((option) => {
                      return (
                        <OptionWrapper
                          key={option?.label}
                          onClick={() =>
                            option.clickFunction && option.clickFunction(item)
                          }
                        >
                          {option?.label}
                        </OptionWrapper>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}

            {/* <Tabs
              // items={!themeTabs?.data?.data ? [{}] : themetabs}
              items={themetabs}
              variant="card"
              activeColor={theme[selectedTheme].background}
              inactiveColor={theme[selectedTheme].tagsBorderColor}
              onChange={handleTheme}
              isContent={false}
              gapitems="0.4rem"
              bottomBorderWidth="0"
              wraperBorderWidth="0"
              activeCardBGColor={theme[selectedTheme].tagsBorderColor}
              inactiveCardBGColor={theme[selectedTheme].background}
              cardBorderRadius=".75rem"
              paddingWrapper="0.25rem 0 0"
              defaultActive={false}
              resetTab={true}
              currentTabList={getIndexes(activeTheme)}
            /> */}
          </ThemeTabs>
        ) : (
          ''
        )}
        {(Array.isArray(articlesInfo) &&
          articlesInfo?.length > 0 &&
          !hiddenArticleTigger) ||
        (hiddenArticleFromQuery?.data?.data?.length > 0 &&
          hiddenArticleTigger) ? (
          <>
            <Articles
              hiddenArticleTigger={hiddenArticleTigger}
              setScrollHeight={setScrollHeight}
              showAiSummaryBtn={articleType?.otherInfo?.triggerAISummary}
              isPopup={isPopup}
              searchKey={searchKey}
              savedSearchId={id}
              summaryData={summaryData}
              articles={articlesInfo}
              articleView={activeScreen === 'article'}
              type={type}
              page={page}
              syndicationClickData={syndicationClickData}
              showTab={showTabs}
              floatingPagination={floatingPagination}
              handleChecked={handleChecked}
              checked={checked}
              allChecked={allChecked}
              setSydicationActive={setSydicationActive}
              setSydicationArticles={setSydicationArticles}
              sydicationActive={sydicationActive}
              sydicationArticles={sydicationArticles}
              allHiddenArticles={hiddenArticleFromQuery}
              articlesRecentSearchId={articlesRecentSearchId}
              setSyndicationClick={setSyndicationClick}
              setClickedPosition={setClickedPosition}
              setTriggerFetchUseEffect={setTriggerFetchUseEffect}
              storeArticleCommentsTags={storeArticleCommentsTags}
              tagActionType={tagActionType}
              storeComments={storeComments}
              setStoreComments={setStoreComments}
              storeTags={storeTags}
              setHiddenArticle={setHiddenArticle}
              hiddenArticle={hiddenArticle}
              setStoreTags={setStoreTags}
              name={name}
              refetchHiddleArticles={refetchHiddenArticles}
              showFullTitle={showFullTitle}
              bookmarksLocal={bookmarksLocal}
              hiddenArticlesLocal={hiddenArticlesLocal}
              handlePage={handlePage}
              setInsearchPageNum={setPage}
              setFetchAiSummary={setFetchAiSummary}
              fetchAiSummary={fetchAiSummary}
              setSelectedArticle={setSelectedArticle}
              articleType={articleType?.otherInfo?.widget}
              articlePagingInfo={articlePagingInfo}
              isLoading={isLoading}
              advanceArticleType={articleType}
              setStoryAnalysisPopup={setStoryAnalysisPopup}
              setStoryAnalysisData={setStoryAnalysisData}
              setSimilarStoriesIds={setSimilarStoriesIds}
              getStoryAnalysisData={getStoryAnalysisData}
              setErrorMessage={setErrorMessage}
            />
            <div style={{ height: '3rem' }}></div>
          </>
        ) : (
          <>
            {((articlesInfo?.length === 0 && !hiddenArticleTigger) ||
              ((hiddenArticleFromQuery?.data?.data?.length === 0 ||
                hiddenArticleFromQuery?.data === null) &&
                hiddenArticleTigger)) &&
            !isLoading &&
            !loader ? (
              <NoResultsWrp>
                <NoResultsTitle>
                  {hiddenArticleTigger
                    ? 'No Hidden Results to Display'
                    : 'No Results to Display'}
                </NoResultsTitle>
                <NoResultsDesp>
                  {hiddenArticleTigger
                    ? 'Please add articles'
                    : 'Please update your search query'}
                </NoResultsDesp>
              </NoResultsWrp>
            ) : (
              <LoadingWrp>
                <CircularLoading
                  bgColor={theme[selectedTheme].primary}
                  size="0.25rem"
                  width="1.875rem"
                  height="1.875rem"
                />
              </LoadingWrp>
            )}
          </>
        )}

        {floatingPagination && (
          <Paginatewpr
            articlePosition={articlePosition}
            fullScreen={activeScreen === 'article'}
            activeScreen={activeScreen}
            isActiveScreenSelected={isActiveScreenSelected}
            style={{
              position: articlePosition ? 'absolute' : 'fixed',
              bottom: '3rem',
              left: '50%',
              transform: 'translate(-50%, 50%)',
              right: 0,
              width: 'maxContent',
            }}
          >
            {/* {articlePagingInfo?.unique || articlePagingInfo?.total ? (
              <Pagination
                page={
                  (id === 'custom-search' || recentSearchId) &&
                  hiddenArticleTigger
                    ? allHiddenArticles?.data?.paged?.pageNumber - 1
                    : articlePagingInfo?.pageNumber - 1
                }
                prevClick={handlePage}
                nextClick={handlePage}
                handlePage={handlePage}
                isCustomPagiNation={isCustomPagiNation}
                setIsCustomPagiNation={(flag) => setIsCustomPagiNation(flag)}
                customPageNum={customPageNum}
                cumstomPageHandler={(e) => cumstomPageHandler(e)}
                onClickCustomPage={() => onClickCustomPage()}
                total={
                  (id === 'custom-search' || recentSearchId) &&
                  hiddenArticleTigger
                    ? allHiddenArticles?.data?.paged?.total
                    : articlePagingInfo?.unique || articlePagingInfo?.total
                }
                align="end"
                limit={
                  (id === 'custom-search' || recentSearchId) &&
                  hiddenArticleTigger
                    ? allHiddenArticles?.data?.paged?.pageSize
                    : articlePagingInfo?.pageSize
                }
              />
            ) : (
              <CustomPaginationWrapper></CustomPaginationWrapper>
            )} */}
            {!hiddenArticleTigger && articlePagingInfo?.total > 0 && (
              <IconBox
                articleType={articleType}
                setClickedPosition={setClickedPosition}
                setArticleType={setArticleType}
                page={page}
                type={type}
                searchQuery={searchKey}
                setSearchQuery={setSearchKey}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                downLoadFunction={downLoadFunction}
                // downloading={downloadingPdf}
                filter={filter}
                setFilter={setFilter}
                checked={checked}
                setDownLoadArticleFlag={setDownLoadArticleFlag}
                setDownloadSelected={setDownloadSelected}
                key={showFullTitle || 'All Results'}
                setMultipleTagFlag={setMultipleTagFlag}
                setChecked={setChecked}
                setAllChecked={setAllChecked}
                setPage={setPage}
              />
            )}
          </Paginatewpr>

          // <DashboardPopup
        )}
      </ArticleSection>
      <DashboardPopup
        open={confirmationPopUp}
        toggler={setConfirmationPopUp}
        popContent={
          <CustomConfirmationPopUp
            Heading={'Delete Tag?'}
            SecondHeading={`You have ${selectedTag?.count} article(s) tagged as "${selectedTag?.name}". Are you sure you want to delete the tag?`}
            toggler={setConfirmationPopUp}
            handleDelete={handleDeleteTag}
            actionLabel="Yes"
            cancelLabel="No"
          />
        }
        padding="0"
        borderRadius="0.625rem"
        width="400px"
      />

      <DashboardPopup
        open={editTagPopUp}
        toggler={setEditTagPopUp}
        popContent={
          <EditTag
            heading={'Edit Tag'}
            handleToggle={() => {
              setEditTagPopUp(false);
            }}
            originalTag={selectedTag?.name}
            articleCount={selectedTag?.count}
            onSubmitHandler={onRenameSubmitHandler}
          />
        }
        padding="0"
        borderRadius="0.625rem"
        width="500px"
      />
      {storyAnalysisPopup && (
        <StoryAnalysisPopup
          setToggle={() => {
            setStoryAnalysisPopup(false);
            setStoryAnalysisData(null);
            setSimilarStoriesData(null);
            setSimilarStoriesArticles(null);
            setSimilarStoriesIds([]);
            setActiveButton('Story Analysis');
            isSimilarFetching.current = false;
          }}
          setSimilarStoriesData={setSimilarStoriesData}
          setStoryAnalysisData={setStoryAnalysisData}
          articles={
            activeButton === 'Story Analysis'
              ? storyAnalysisData
              : similarStoriesData
          }
          setActiveButton={setActiveButton}
          activeButton={activeButton}
          similarAnalysisLength={similarStoriesData?.length}
          articlesRecentSearchId={articlesRecentSearchId}
          storyAnalysisData={storyAnalysisData}
          similarStoriesData={similarStoriesData}
          similarStoriesIdsLength={similarStoriesIds?.length}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};

export default ArticleSectionComponent;

ArticleSectionComponent.propTypes = {
  activeScreen: PropTypes.string,
  articleType: PropTypes.object,
  page: PropTypes.number,
  setPage: PropTypes.func,
  type: PropTypes.string,
  setType: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  articlePosition: PropTypes.string,
  floatingPagination: PropTypes.bool,
  setSelected: PropTypes.func,
  setArticlePosition: PropTypes.func,
  setSelectedGraph: PropTypes.func,
  setResetSelection: PropTypes.func,
  showTabs: PropTypes.bool,
  showFullTitle: PropTypes.string,
  pageWidth: PropTypes.string,
  showHeading: PropTypes.bool,
  loader: PropTypes.bool,
  articlesInfo: PropTypes.array,
  articlePagingInfo: PropTypes.object,
  isLoading: PropTypes.bool,
  searchKey: PropTypes.string,
  setSearchKey: PropTypes.func,
  updateBookmark: PropTypes.func,
  sortOrder: PropTypes.string,
  setSortOrder: PropTypes.func,
  titleTabs: PropTypes.array || undefined,
  setClickedPosition: PropTypes.func,
  setArticleType: PropTypes.func,
  onBtnClick: PropTypes.func,
  setSydicationActive: PropTypes.func,
  setSydicationArticles: PropTypes.func,
  sydicationActive: PropTypes.bool,
  sydicationArticles: PropTypes.array,
  articlesRecentSearchId: PropTypes.string,
  setTriggerFetchUseEffect: PropTypes.func,
  setTriggerFetchTags: PropTypes.func,
  storeArticleCommentsTags: PropTypes.func,
  storeComments: PropTypes.array,
  setStoreComments: PropTypes.func,
  storeTags: PropTypes.array,
  setStoreTags: PropTypes.func,
  queryPayload: PropTypes.object,
  query: PropTypes.string,
  filters: PropTypes.object,
  isCustomPagiNationFlag: PropTypes.bool,
  setIsCustomPagiNationFlag: PropTypes.func,
  resetAllChecked: PropTypes.bool,
  toggleResetAllChecked: PropTypes.func,
  setTags: PropTypes.func,
  setSyndicationClick: PropTypes.func,
  name: PropTypes.string,
  syndicationClickData: PropTypes.object,
  activeTheme: PropTypes.array,
  setActiveTheme: PropTypes.func,
  checked: PropTypes.array,
  setChecked: PropTypes.func,
  setDownLoadArticleFlag: PropTypes.func,
  setDownloadSelected: PropTypes.func,
  isActiveScreenSelected: PropTypes.bool,
  bookmarksLocal: PropTypes.array,
  hiddenArticlesLocal: PropTypes.array,
  liveArticleCount: PropTypes.number,
  onClick: PropTypes.func,
  isPopup: PropTypes.bool,
  summaryData: PropTypes.object,
  eventCycle: PropTypes.bool,
  setMultipleTagFlag: PropTypes.func,
  setAllTags: PropTypes.func,
  tagActionType: PropTypes.string,
  setFetchAiSummary: PropTypes.func,
  fetchAiSummary: PropTypes.bool,
  setArticles: PropTypes.func,
  setHiddenArticleSwitch: PropTypes.func,
  hiddenArticleSwitch: PropTypes.bool,
  setScrollHeight: PropTypes.func,
  setSelectedArticle: PropTypes.func,
};

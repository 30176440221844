import * as d3 from 'd3';
import { colorBox, initialValues } from '../utils/graphConst';
import { setUpEvents } from '../utils/graphEvents';
import peaks from '../../assets/img/widgetsImg/peaks-online.svg';
import peaksPrint from '../../assets/img/widgetsImg/peaks-print.svg';
import { marked } from 'marked';
import {
  magentaColorGradients,
  purpleColorGradients,
} from '../../constants/graph-colors';
import { trackEvent } from '../../utils/mixPanel';

const social = [
  'X (Twitter)',
  'Blogs',
  'Forums',
  'Reviews',
  'Reddit',
  'YouTube',
];
const traditional = ['Online', 'Print'];

const getSelectedTypes = (filters) => {
  if (filters?.mediaType !== null) {
    const mediaTypes = filters?.mediaType?.split(',');
    const isSocial = mediaTypes?.some((x) => social?.includes(x));
    const isTraditional = mediaTypes?.some((x) => traditional?.includes(x));
    if (isSocial && isTraditional) {
      return 'all';
    }
    if (isSocial) {
      return 'social';
    }
    if (isTraditional) {
      return 'traditional';
    }
  }
  return 'all';
};

export const linePath = function linePath() {
  let config = {
    ...initialValues,
    enableCurve: false,
    enableStep: false,
    areaLineStroke: '#8676FF',
    areaLineStrokeWidth: 2,
    r: 5,
    enablePointCircle: false,
    // pointCircleFill: "none",
    pointCircleStroke: '#8676FF',
    pointCircleStrokeWidth: 2,
    enableThreshold: false,
    thresholdStroke: 'red',
    thresholdStrokeWidth: 2,
    enableGradient: false,
    disableCircleLabel: true,
    enablePeaks: false,
    enableTooltipTrendPointer: false,
  };

  // draw the graph here
  function graph(selected) {
    selected.each(function (data) {
      const t = d3.transition().duration(config.duration);

      // selected.selectAll("defs").remove();
      function lg(eleRef) {
        const gradientOffset = eleRef
          // .attr("cx", "25%")
          // .attr("cy", "25%")
          // .attr("r", "65%")
          .attr('x1', '0%')
          .attr('y1', '0%')
          .attr('x2', '0%')
          .attr('y2', '100%')
          .attr('id', function (d, i) {
            return config.graphType === 'line' && config.enableGradient
              ? 'gradOffset'
              : config?.mediaTypeChart
              ? 'gradOffset-' + i + `-${d[0]?.labelText}`
              : 'gradOffset-' + i;
          });

        gradientOffset
          .selectAll('.stop')
          .data((d) => {
            return [d];
          })
          .join(
            (enter) => {
              enter
                .append('stop')
                .attr('class', 'stop')
                .attr('offset', config?.sentimentOverTime ? '90%' : '50%')
                .attr('stop-color', (d) =>
                  config.graphType === 'line' && config.enableGradient
                    ? '#893FFC'
                    : d[0]?.color
                )
                .attr('stop-opacity', (d) => d[0]?.colorOpacity || 0.5);
            },
            (update) => {
              update
                .attr('offset', config?.sentimentOverTime ? '90%' : '50%')
                .attr('stop-color', (d) =>
                  config.graphType === 'line' && config.enableGradient
                    ? '#893FFC'
                    : d[0]?.color
                )
                .attr('stop-opacity', (d) => d[0]?.colorOpacity || 0.5);
            },
            (exit) => exit.remove()
          );

        gradientOffset
          .selectAll('.stop1')
          .data((d) => {
            return [d];
          })
          .join(
            (enter) => {
              enter
                .append('stop')
                .attr('class', 'stop1')
                .attr('offset', '100%')
                .attr('stop-color', (d) => d[0]?.color1 || '#fff')
                .attr('stop-opacity', (d) => d[0]?.colorOpacity1 || 0.5);
            },
            (update) => {
              update
                .attr('offset', '100%')
                .attr('stop-color', (d) => d[0]?.color1 || '#fff')
                .attr('stop-opacity', (d) => d[0]?.colorOpacity1 || 0.5);
            },
            (exit) => exit.remove()
          );
      }

      function createDefs(elementRef) {
        elementRef
          .selectAll('.gradientOffset')
          .data((d) => {
            return d;
          })
          .join(
            (enter) => {
              enter
                .append('linearGradient')
                .attr('class', 'gradientOffset')
                .call(lg);
            },
            (update) => update.call(lg),
            (exit) => exit.remove()
          )
          .call(lg);
      }

      selected
        .selectAll('.defs')
        .data((d) => {
          return [d];
        })
        .join(
          (enter) => {
            enter.append('defs').attr('class', 'defs').call(createDefs);
          },
          (update) => update.call(createDefs),
          (exit) => exit.remove()
        )
        .call(createDefs);

      const lineConst = d3.line().x(function (d, i) {
        return config.xScale(d.label);
      });

      const areaConsts = d3.area().x(function (d, i) {
        return config.xScale(d.label);
      });

      if (config.enableCurve) {
        areaConsts.curve(d3.curveMonotoneX);
        lineConst.curve(d3.curveMonotoneX); // apply smoothing to the line
      } else if (config.enableStep) {
        areaConsts.curve(d3.curveStepAfter);
        lineConst.curve(d3.curveStepAfter); // apply smoothing to the line
      }

      const lineFunc = function (datum, boolean) {
        return lineConst.y(function (d) {
          return config.yScale(boolean ? d.value : 0);
        })(datum);
        // return lineConst .y0(function (d, i) {
        //     return config.yScale(config.minY < 0 ? config.minY : 0);
        //   })
        //   .y1(function (d, i) {
        //     return config.yScale(boolean ? d.value : 0);
        //   })(datum);
      };

      const slineFunc = function (datum, boolean) {
        return lineConst.y(function (d) {
          return config.yScale(boolean ? d.accValue : 0);
        })(datum);
      };

      const area = function (datum, boolean) {
        return areaConsts
          .y0(function (d, i) {
            return config.yScale(config.minY < 0 ? config.minY : 0);
          })
          .y1(function (d, i) {
            return config.yScale(boolean ? d.value : 0);
          })(datum);
      };
      // Construct an stacked area generator.

      const sarea = function (datum, boolean) {
        return areaConsts
          .y0(function (d, i) {
            return boolean
              ? config.yScale(d.accValue - d.value)
              : config.yScale(0);
          })
          .y1(function (d, i) {
            return boolean ? config.yScale(d.accValue) : config.yScale(0);
          })(datum);
      };

      // Construct an range area generator.
      const rband = function () {
        return areaConsts
          .y0(function (d, i) {
            return config.yScale(d.value);
          })
          .y1(function (d) {
            return config.yScale(d.lastLineValue);
          });
      };

      if (config.graphType === 'rband') {
        selected
          .selectAll('.band-bg')
          .data((d, i) => {
            d.map((entry, j) => {
              const temp = entry.map((ele, k) => {
                const t = JSON.parse(JSON.stringify(ele));
                t.lastLineValue = d[d.length - 1][k].value;
                return t;
              });
              return temp;
            });
            return [d[0]];
          })
          .join(
            (enter) => {
              enter
                .append('path')
                .attr('class', 'band-bg')
                .attr('fill', config.bandBGColor || '#f6f6f6')
                .transition(t)
                .attr('d', rband());
            },
            (update) => update,
            (exit) => {
              exit.remove();
            }
          )
          .transition(t)
          .attr('d', rband());
      }

      // Function to render the timeline background
      function renderTimelineBackground(elementRef) {
        // First, define the gradients in the SVG defs
        const defs = elementRef.append('defs');

        // [Previous gradient definitions remain the same...]
        const preGradient = defs
          .append('linearGradient')
          .attr('id', 'preGradient')
          .attr('x1', '0%')
          .attr('x2', '0%')
          .attr('y1', '100%')
          .attr('y2', '0%');

        preGradient
          .append('stop')
          .attr('offset', '0%')
          .attr('stop-color', '#FFAFD2')
          .attr('stop-opacity', 0.4);

        preGradient
          .append('stop')
          .attr('offset', '100%')
          .attr('stop-color', '#FFFFFF')
          .attr('stop-opacity', 0.1);

        const duringGradient = defs
          .append('linearGradient')
          .attr('id', 'duringGradient')
          .attr('x1', '0%')
          .attr('x2', '0%')
          .attr('y1', '100%')
          .attr('y2', '0%');

        duringGradient
          .append('stop')
          .attr('offset', '0%')
          .attr('stop-color', '#F1C21B')
          .attr('stop-opacity', 0.4);

        duringGradient
          .append('stop')
          .attr('offset', '100%')
          .attr('stop-color', '#FFFFFF')
          .attr('stop-opacity', 0.1);

        const postGradient = defs
          .append('linearGradient')
          .attr('id', 'postGradient')
          .attr('x1', '0%')
          .attr('x2', '0%')
          .attr('y1', '100%')
          .attr('y2', '0%');

        postGradient
          .append('stop')
          .attr('offset', '0%')
          .attr('stop-color', '#3DDBD9')
          .attr('stop-opacity', 0.4);

        postGradient
          .append('stop')
          .attr('offset', '100%')
          .attr('stop-color', '#FFFFFF')
          .attr('stop-opacity', 0.1);

        // Group the data by timeline period
        const groupedData = d3.group(data[0].flat(), (d) => d.data.timeline);

        // Calculate the starting position and width for each period
        const timelineSections = Array.from(groupedData).map(
          ([period, items]) => {
            const startIndex = data[0].flat().indexOf(items[0]);
            const width =
              period === 'during'
                ? config.xScale.step() * (items.length - 1)
                : config.xScale.step() * items.length;
            const start =
              period === 'post'
                ? config.xScale.step() * (startIndex - 1)
                : config.xScale.step() * startIndex;

            return {
              period,
              startX: start,
              width,
              endX: start + width,
              borderColor: period === 'pre' ? '#FFAFD2' : '#F1C21B', // Set border color based on period
            };
          }
        );

        // Render background rectangles with gradients
        elementRef
          .selectAll('.timeline-bg-rect')
          .data(timelineSections)
          .join(
            (enter) =>
              enter
                .append('rect')
                .attr('class', 'timeline-bg-rect')
                .attr('x', (d) => d.startX)
                .attr('y', 0)
                .attr('width', (d) => d.width)
                .attr('height', config.graphAreaH)
                .attr('fill', (d) => {
                  switch (d.period) {
                    case 'pre':
                      return 'url(#preGradient)';
                    case 'during':
                      return 'url(#duringGradient)';
                    case 'post':
                      return 'url(#postGradient)';
                    default:
                      return 'none';
                  }
                }),
            (update) =>
              update
                .attr('x', (d) => d.startX)
                .attr('width', (d) => d.width)
                .attr('fill', (d) => {
                  switch (d.period) {
                    case 'pre':
                      return 'url(#preGradient)';
                    case 'during':
                      return 'url(#duringGradient)';
                    case 'post':
                      return 'url(#postGradient)';
                    default:
                      return 'none';
                  }
                }),
            (exit) => exit.remove()
          );

        // Add dashed borders at the end of pre and during sections
        const borderSections = timelineSections
          .filter((d) => d.period === 'pre' || d.period === 'during')
          .map((d) => ({
            x: d.endX,
            height: config.graphAreaH,
            color: d.borderColor,
          }));

        elementRef
          .selectAll('.timeline-border')
          .data(borderSections)
          .join(
            (enter) =>
              enter
                .append('line')
                .attr('class', 'timeline-border')
                .attr('x1', (d) => d.x)
                .attr('y1', 0)
                .attr('x2', (d) => d.x)
                .attr('y2', (d) => d.height)
                .attr('stroke', (d) => d.color) // Use the matching section color
                .attr('stroke-width', 1)
                .attr('stroke-dasharray', '4,4'),
            (update) =>
              update
                .attr('x1', (d) => d.x)
                .attr('x2', (d) => d.x)
                .attr('y2', (d) => d.height)
                .attr('stroke', (d) => d.color),
            (exit) => exit.remove()
          );
      }

      // Predictive Data
      const actualData = data[0].filter((d) => !d?.data?.predictiveLine);
      const forecastData = data[0].filter((d) => d?.data?.predictiveLine);

      function mainBaseFunc(elementRef) {
        if (config.predictiveCheck) {
          selected.selectAll('.line-path').remove();
          elementRef
            .selectAll('.line-path-actual-grad')
            .data((d, i) => {
              d.map((entry) => {
                const temp = entry.map((ele) => {
                  const t = JSON.parse(JSON.stringify(ele));
                  t.lastIndex = entry.length;
                  return t;
                });
                return temp;
              });
              const filterD = [];
              d.forEach((element) => {
                filterD.push(element.filter((ele) => ele.value >= 0));
              });

              return [actualData];
            })
            .join(
              (enter) => {
                const base = enter
                  .append('path')
                  .attr('class', 'line-path-actual-grad');
                base
                  .attr('fill', (d, i) => {
                    return `url(#gradOffset-${i})`;
                  })
                  .attr('d', (d) => area(d, false))

                  .transition(t)
                  .style('opacity', (d, i) => 1 - 0.1 * i)
                  .attr('fill', (d, i) => `url(#gradOffset-${i})`)
                  .attr('d', (d) => area(d, true));
              },
              (update) => {
                update
                  .attr('fill', (d, i) => `url(#gradOffset-${i})`)
                  .transition(t)
                  .style('opacity', (d, i) => 1 - 0.1 * i)
                  .attr('fill', (d, i) => `url(#gradOffset-${i})`)
                  .attr('d', (d) => area(d, true));
              },
              (exit) => {
                exit
                  .transition(t)
                  .attr('d', (d) =>
                    config.graphType === 'area'
                      ? area(d, false)
                      : config.graphType === 'sarea'
                      ? sarea(d, false)
                      : lineFunc(d, false)
                  )
                  .remove();
              }
            );
        } else {
          selected.selectAll('.line-path-actual-grad').remove();
          elementRef
            .selectAll('.line-path')
            .data((d, i) => {
              d.map((entry) => {
                const temp = entry.map((ele) => {
                  const t = JSON.parse(JSON.stringify(ele));
                  t.lastIndex = entry.length;
                  return t;
                });
                return temp;
              });
              const filterD = [];
              d.forEach((element) => {
                filterD.push(element.filter((ele) => ele.value >= 0));
              });
              return filterD;
            })
            .join(
              (enter) => {
                const base = enter.append('path').attr('class', 'line-path');
                if (
                  config.graphType === 'area' ||
                  config.graphType === 'sarea'
                ) {
                  base
                    .attr(
                      'fill',
                      config.graphType === 'line' ||
                        config.graphType === 'rband'
                        ? 'none'
                        : (d, i) => {
                            return config.enableGradient
                              ? config?.mediaTypeChart
                                ? `url(#gradOffset-${i}-${d[0]?.labelText})`
                                : `url(#gradOffset-${i})`
                              : d[0]?.color
                              ? d[0]?.color
                              : colorBox[(i % colorBox.length) - 1];
                          }
                    )
                    .attr('d', (d) =>
                      config.graphType === 'area'
                        ? area(d, false)
                        : sarea(d, false)
                    )

                    .transition(t)
                    .style(
                      'opacity',
                      config.graphType === 'area' ? (d, i) => 1 - 0.1 * i : 1
                    )
                    .attr(
                      'fill',
                      config.graphType === 'line' ||
                        config.graphType === 'rband'
                        ? 'none'
                        : (d, i) =>
                            config.enableGradient
                              ? config?.mediaTypeChart
                                ? `url(#gradOffset-${i}-${d[0]?.labelText})`
                                : `url(#gradOffset-${i})`
                              : d[0]?.color
                              ? d[0]?.color
                              : colorBox[(i % colorBox.length) - 1]
                    )
                    .attr('d', (d) =>
                      config.graphType === 'area'
                        ? area(d, true)
                        : sarea(d, true)
                    );
                } else {
                  base
                    .attr(
                      'fill',
                      config.graphType === 'line' && config.enableGradient
                        ? 'url(#gradOffset)'
                        : (config.graphType === 'line' &&
                            !config.enableGradient) ||
                          config.graphType === 'rband'
                        ? 'none'
                        : (d, i) =>
                            d[0]?.color
                              ? d[0]?.color
                              : colorBox[(i % colorBox.length) - 1]
                    )
                    .attr('stroke', (d, i) => {
                      const colorsArr = d.map((colorEle) => colorEle?.color);
                      const colorVal = colorsArr.filter(function (felement) {
                        return felement !== undefined;
                      });
                      if (colorVal[i + 1]) {
                        return !config.enableGradient && colorVal[i];
                      } else {
                        return (
                          !config.enableGradient &&
                          colorBox[i % colorBox.length]
                        );
                      }
                    })
                    .attr(
                      'stroke-width',
                      (!config.enableGradient && config.lineStrokeWidth) || 2.5
                    )
                    // .attr('d', (d) => lineFunc(d, false))
                    .attr('d', (d) =>
                      config.graphType === 'line' && config.enableGradient
                        ? area(d, false)
                        : lineFunc(d, false)
                    )
                    .transition(t)
                    // .attr('d', (d) => lineFunc(d, true))
                    .attr('d', (d) =>
                      config.graphType === 'line' && config.enableGradient
                        ? area(d, true)
                        : lineFunc(d, true)
                    )
                    .attr('stroke-dashoffset', 0);
                }
              },
              (update) => {
                if (
                  config.graphType === 'area' ||
                  config.graphType === 'sarea'
                ) {
                  update
                    .attr(
                      'fill',
                      config.graphType === 'line' ||
                        config.graphType === 'rband'
                        ? 'none'
                        : (d, i) =>
                            config.enableGradient
                              ? config?.mediaTypeChart
                                ? `url(#gradOffset-${i}-${d[0]?.labelText})`
                                : `url(#gradOffset-${i})`
                              : d[0]?.color
                              ? d[0]?.color
                              : colorBox[(i % colorBox.length) - 1]
                    )
                    .transition(t)
                    .style(
                      'opacity',
                      config.graphType === 'area' ? (d, i) => 1 - 0.1 * i : 1
                    )
                    .attr(
                      'fill',
                      config.graphType === 'line' ||
                        config.graphType === 'rband'
                        ? 'none'
                        : (d, i) =>
                            config.enableGradient
                              ? config?.mediaTypeChart
                                ? `url(#gradOffset-${i}-${d[0]?.labelText})`
                                : `url(#gradOffset-${i})`
                              : d[0]?.color
                              ? d[0]?.color
                              : colorBox[(i % colorBox.length) - 1]
                    )
                    .attr('d', (d) =>
                      config.graphType === 'area'
                        ? area(d, true)
                        : sarea(d, true)
                    );
                } else {
                  update
                    .attr('stroke', (d, i) => {
                      const colorsArr = d.map((colorEle) => colorEle?.color);
                      const colorVal = colorsArr.filter(function (felement) {
                        return felement !== undefined;
                      });
                      if (colorVal[i + 1]) {
                        return !config.enableGradient && colorVal[i];
                      } else {
                        return (
                          !config.enableGradient &&
                          colorBox[i % colorBox.length]
                        );
                      }
                    })
                    .attr(
                      'stroke-width',
                      (!config.enableGradient && config.lineStrokeWidth) || 2.5
                    )
                    // .attr('d', (d) => lineFunc(d, false))
                    .attr('d', (d) =>
                      config.graphType === 'line' && config.enableGradient
                        ? area(d, false)
                        : lineFunc(d, false)
                    )
                    .transition(t)
                    .attr(
                      'fill',
                      config.graphType === 'line' && config.enableGradient
                        ? 'url(#gradOffset)'
                        : (config.graphType === 'line' &&
                            !config.enableGradient) ||
                          config.graphType === 'rband'
                        ? 'none'
                        : (d, i) =>
                            d[0]?.color
                              ? d[0]?.color
                              : colorBox[(i % colorBox.length) - 1]
                    )
                    // .attr('d', (d) => lineFunc(d, true))
                    .attr('d', (d) =>
                      config.graphType === 'line' && config.enableGradient
                        ? area(d, true)
                        : lineFunc(d, true)
                    )
                    .attr('stroke-dashoffset', 0);
                }
              },
              (exit) => {
                exit
                  .transition(t)
                  .attr('d', (d) =>
                    config.graphType === 'area'
                      ? area(d, false)
                      : config.graphType === 'sarea'
                      ? sarea(d, false)
                      : lineFunc(d, false)
                  )
                  .remove();
              }
            );
        }
      }

      selected
        .selectAll('.line-path-group')
        .data((d) => [d])
        .join(
          (enter) => {
            enter
              .append('g')
              .attr('class', 'line-path-group')

              .call(mainBaseFunc)
              .call(renderTimelineBackground);
          },
          (update) => update.call(mainBaseFunc),
          (exit) => exit.remove()
        );

      if (config?.summary?.thresholdArr?.length && config.enableThreshold) {
        selected
          .selectAll('.threshold-line-path')
          .data(config.summary.thresholdArr)
          .join(
            (enter) => {
              enter
                .append('line')
                .attr('class', 'threshold-line-path')
                .attr('stroke', (d) => d?.color || config.thresholdStroke)
                .attr('stroke-width', config.thresholdStrokeWidth)
                .attr('x1', 0)
                .attr('y1', (d) => config.yScale(d.value))
                .attr('x2', config.graphAreaW * 0.95)
                .attr('y2', (d) => config.yScale(d.value))
                .attr('stroke-dasharray', 5)
                .attr('stroke-dashoffset', 0);
            },
            (update) =>
              update
                .attr('y1', (d) => config.yScale(d.value))
                .attr('y2', (d) => config.yScale(d.value)),
            (exit) => {
              exit.remove();
            }
          );

        if (!config.disableThreshouldLabel) {
          selected
            .selectAll('.threshold-line-path-text')
            .data(config.summary.thresholdArr)
            .join(
              (enter) => {
                enter
                  .append('text')
                  .attr('class', 'threshold-line-path-text')
                  .attr('x', config.graphAreaW * 0.94)
                  .attr('y', (d) => config.yScale(d.value) - 5)
                  .attr('text-anchor', 'end')
                  .attr('fill', (d) => d?.color || config.thresholdStroke)
                  .text((d) => `${d.label}-${d.value}`);
              },
              (update) => update,
              (exit) => {
                exit.remove();
              }
            );
        }
      } else {
        selected.selectAll('.threshold-line-path').remove();
        selected.selectAll('.threshold-line-path-text').remove();
      }
      if (config.enableAreaLine) {
        if (config.predictiveCheck) {
          selected.selectAll('.line-path-area-line').remove();

          // Render the actual data line (solid line)
          selected
            .selectAll('.line-path-actual') // Unique class for the actual line
            .data([actualData]) // Bind actual data
            .join(
              (enter) => {
                enter
                  .append('path')
                  .attr('class', 'line-path-actual') // Unique class
                  .attr('fill', 'none')
                  .attr('stroke', (d, i) => {
                    if (config.enableGradient) {
                      return config?.sentimentOverTime || config.mediaTypeChart
                        ? d[0]?.color
                        : d[i]?.dashLineColor
                        ? d[i]?.dashLineColor
                        : config.areaLineStroke;
                    }
                  })
                  .attr(
                    'stroke-width',
                    config.enableGradient && config.areaLineStrokeWidth
                  )
                  .attr('d', (d) =>
                    config.graphType === 'sarea'
                      ? slineFunc(d, false)
                      : lineFunc(d, false)
                  )
                  .transition(t)
                  .attr('d', (d) =>
                    config.graphType === 'sarea'
                      ? slineFunc(d, true)
                      : lineFunc(d, true)
                  )
                  .attr('stroke-dasharray', (d, i) =>
                    d[i]?.enableDash ? '3,3' : '0,0'
                  );
              },
              (update) =>
                update
                  .transition(t)
                  .attr('stroke', (d, i) => {
                    if (config.enableGradient) {
                      return config?.sentimentOverTime || config.mediaTypeChart
                        ? d[0]?.color
                        : d[i]?.dashLineColor
                        ? d[i]?.dashLineColor
                        : config.areaLineStroke;
                    }
                  })
                  .attr(
                    'stroke-width',
                    config.enableGradient && config.areaLineStrokeWidth
                  )
                  .attr('d', (d) =>
                    config.graphType === 'sarea'
                      ? slineFunc(d, true)
                      : lineFunc(d, true)
                  )
                  .attr('stroke-dashoffset', 0)
                  .attr('stroke-dasharray', (d, i) =>
                    d[i]?.enableDash ? '3,3' : '0,0'
                  ),
              (exit) => {
                exit.remove();
              }
            );

          // Extension
          selected
            .selectAll('.line-path-forecast')
            .data([forecastData])
            .join(
              (enter) => {
                enter
                  .append('path')
                  .attr('class', 'line-path-forecast')
                  .attr('fill', 'none')
                  .attr('stroke', (d, i) => {
                    if (config.enableGradient) {
                      return config?.sentimentOverTime || config.mediaTypeChart
                        ? d[0]?.color
                        : d[i]?.dashLineColor
                        ? d[i]?.dashLineColor
                        : config.areaLineStroke;
                    }
                  })
                  .attr(
                    'stroke-width',
                    config.enableGradient && config.areaLineStrokeWidth
                  )
                  .attr('d', (d) =>
                    config.graphType === 'sarea'
                      ? slineFunc(d, false)
                      : lineFunc(d, false)
                  )
                  .transition(t)
                  .attr('d', (d) =>
                    config.graphType === 'sarea'
                      ? slineFunc(d, true)
                      : lineFunc(d, true)
                  )
                  .attr('stroke-dasharray', '3,3');
              },
              (update) =>
                update
                  .transition(t)
                  .attr('stroke', (d, i) => {
                    if (config.enableGradient) {
                      return config?.sentimentOverTime || config.mediaTypeChart
                        ? d[0]?.color
                        : d[i]?.dashLineColor
                        ? d[i]?.dashLineColor
                        : config.areaLineStroke;
                    }
                  })
                  .attr(
                    'stroke-width',
                    config.enableGradient && config.areaLineStrokeWidth
                  )
                  .attr('d', (d) =>
                    config.graphType === 'sarea'
                      ? slineFunc(d, true)
                      : lineFunc(d, true)
                  )
                  .attr('stroke-dashoffset', 0)
                  .attr('stroke-dasharray', '4,4'),
              (exit) => {
                exit.remove();
              }
            );

          const actualEnd = actualData[actualData.length - 1];
          const forecastStart = forecastData[0];

          selected
            .append('path')
            .attr('class', 'line-connector')
            .attr('fill', 'none')
            .attr('stroke', (d, i) => {
              if (config.enableGradient) {
                return config?.sentimentOverTime || config.mediaTypeChart
                  ? d[0]?.color
                  : d[i]?.dashLineColor
                  ? d[i]?.dashLineColor
                  : config.areaLineStroke;
              }
            }) // Match stroke color
            .attr('stroke-width', config.areaLineStrokeWidth)
            .attr(
              'd',
              d3
                .line()
                .x((d) => config.xScale(d.label)) // Use the x-scale to position
                .y((d) => config.yScale(d.value))(
                // Use the y-scale to position
                [actualEnd, forecastStart]
              ) // Draw a path between the two points
            );
        } else {
          selected.selectAll('.line-path-actual').remove();
          selected.selectAll('.line-path-forecast').remove();
          selected.selectAll('.line-connector').remove();
          selected
            .selectAll('.line-path-area-line')
            .data((d, i) => {
              d.map((entry) => {
                const temp = entry.map((ele) => {
                  const t = JSON.parse(JSON.stringify(ele));
                  t.lastIndex = entry.length;
                  return t;
                });
                return temp;
              });
              return d;
            })
            .join(
              (enter) => {
                enter
                  .append('path')
                  .attr('class', 'line-path-area-line')
                  .attr('fill', 'none')
                  .attr('stroke', (d, i) => {
                    if (config.enableGradient) {
                      return config?.sentimentOverTime || config.mediaTypeChart
                        ? d[0]?.color
                        : d[i]?.dashLineColor
                        ? d[i]?.dashLineColor
                        : config.areaLineStroke;
                    }
                  })
                  .attr(
                    'stroke-width',
                    config.enableGradient && config.areaLineStrokeWidth
                  )
                  .attr('d', (d) =>
                    config.graphType === 'sarea'
                      ? slineFunc(d, false)
                      : lineFunc(d, false)
                  )
                  .transition(t)
                  .attr('d', (d) =>
                    config.graphType === 'sarea'
                      ? slineFunc(d, true)
                      : lineFunc(d, true)
                  )
                  .attr('stroke-dasharray', (d, i) =>
                    d[i]?.enableDash ? '3,3' : '0,0'
                  );
              },
              (update) =>
                update

                  .transition(t)
                  .attr('stroke', (d, i) => {
                    if (config.enableGradient) {
                      return config?.sentimentOverTime || config.mediaTypeChart
                        ? d[0]?.color
                        : d[i]?.dashLineColor
                        ? d[i]?.dashLineColor
                        : config.areaLineStroke;
                    }
                  })
                  .attr(
                    'stroke-width',
                    config.enableGradient && config.areaLineStrokeWidth
                  )
                  .attr('d', (d) =>
                    config.graphType === 'sarea'
                      ? slineFunc(d, true)
                      : lineFunc(d, true)
                  )
                  .attr('stroke-dashoffset', 0)
                  .attr('stroke-dasharray', (d, i) =>
                    d[i]?.enableDash ? '3,3' : '0,0'
                  ),
              (exit) => {
                exit.remove();
              }
            );
        }
      }

      if (config.enablePointCircle) {
        const lineCircleGrpMain = selected
          .selectAll('.line-circle-grp')
          .data((d, i) => d.flat(Infinity))
          .join(
            (enter) => {
              const lineCircleGrp = enter
                .append('g')
                .attr('class', 'line-circle-grp')
                .attr('transform', (d, i) => {
                  return `translate(${config.xScale(d.label)},${config.yScale(
                    d.value
                  )})`;
                });

              lineCircleGrp.selectAll('.line-circle').remove();
              lineCircleGrp.selectAll('.line-circle-label').remove();

              lineCircleGrp
                .append('circle')
                .attr('class', 'line-circle')
                .attr('r', (d) => {
                  return d.value > 0 ? config.r : 0;
                })
                .style('stroke', (d, i) => {
                  return (
                    d.dashLineColor ||
                    d?.color1 ||
                    d?.color ||
                    config.pointCircleStroke
                  );
                })
                .style('stroke-width', config.pointCircleStrokeWidth)
                .style('fill', config.pointCircleFill || '#fff');

              if (!config.disableCircleLabel) {
                lineCircleGrp
                  .append('text')
                  .attr('class', 'line-circle-label')
                  .attr('x', config.r * 1.5)
                  .attr('y', (d) => {
                    return (d.labelIndex % 2 === 0 ? -1 : 1) * config.r * 3;
                  })
                  .text((d) => (isNaN(d.value) ? '' : d.value || ''));
              }
            },
            (update) =>
              update.attr('transform', (d, i) => {
                return `translate(${config.xScale(d.label)},${config.yScale(
                  d.value
                )})`;
              }),
            (exit) => {
              exit.remove();
            }
          );

        lineCircleGrpMain.selectAll('.line-circle').remove();
        lineCircleGrpMain.selectAll('.line-circle-label').remove();

        lineCircleGrpMain
          .append('circle')
          .attr('class', 'line-circle')
          .attr('r', (d) => {
            return d.value > 0 ? config.r : 0;
          })
          .style('stroke', (d, i) => {
            return (
              d.dashLineColor ||
              d?.color1 ||
              d?.color ||
              config.pointCircleStroke
            );
          })
          .style('stroke-width', config.pointCircleStrokeWidth)
          .style('fill', config.pointCircleFill || '#fff');
        if (!config.disableCircleLabel) {
          lineCircleGrpMain
            .append('text')
            .attr('class', 'line-circle-label')
            .attr('x', config.r * 1.5)
            .attr('y', (d) => {
              return (d.labelIndex % 2 === 0 ? -1 : 1) * config.r * 3;
            })
            .text((d) => (isNaN(d.value) ? '' : d.value || ''));
        }
      }

      if (config.enableAllPointToolTip) {
        const tooltipWidth = config.xScale.step() * 0.8;
        const tooltipHeight = 60;
        const tooltipArrowLength = 10;
        const ToolTextXAlign = 5;
        const ToolTextYAlign = 18;
        const toolLegR = config.toolLegR || 5;

        const toolLegend = (selectedItem, x, y, dataIndex) => {
          const legGrp = selectedItem
            .append('g')
            .attr('class', 'line-point-tooltip-legend-grp')
            .attr('transform', (d, i) => {
              return `translate(${x},${y})`;
            });
          legGrp
            .append('circle')
            .attr('class', 'line-point-tooltip-legend')
            .attr('cy', -1 * (toolLegR * 0.85))
            .attr('r', toolLegR)
            .style('stroke', (d, i) =>
              data[dataIndex][i].value ? data[dataIndex][i]?.color : '#fff'
            )
            .style('stroke-width', config.pointCircleStrokeWidth)
            .style('fill', config.pointCircleFill);
          legGrp
            .append('text')
            .attr('class', 'line-point-tooltip-v1')
            .attr('x', toolLegR * 1.75)
            .text((d, i) => {
              return data[dataIndex][i].value || '';
            });
        };

        const toolTipFun = (selectedItem) => {
          selectedItem
            .attr('transform', (d, i) => {
              const tempArr = data.map((ele) => {
                return ele[i].value || 0;
              });
              const maxOfList = d3.max(tempArr);

              return `translate(${
                config.xScale(d.label) - tooltipWidth * 0.2
              },${
                config.yScale(maxOfList) -
                (tooltipHeight + tooltipArrowLength + 5)
              })`;
            })
            .style('display', (d, i) => {
              return data[0][i].value || data[1][i].value ? '' : 'none';
            });
          selectedItem.selectAll('.line-point-tooltip-rect').remove();
          selectedItem.selectAll('.line-point-tooltip-title').remove();
          selectedItem.selectAll('.line-point-tooltip-legend-grp').remove();
          selectedItem
            .append('rect')
            .attr('class', 'line-point-tooltip-rect')
            .attr('width', tooltipWidth)
            .attr('height', tooltipHeight)
            .attr('fill', 'white')
            .attr('stroke', '#DCDFF0');

          selectedItem
            .append('text')
            .attr('class', 'line-point-tooltip-title')
            .attr('x', ToolTextXAlign)
            .attr('y', ToolTextYAlign)
            .text((d) => {
              return d.label;
            });

          toolLegend(selectedItem, ToolTextXAlign * 2, ToolTextYAlign * 2, 0);
          toolLegend(selectedItem, ToolTextXAlign * 2, ToolTextYAlign * 3, 1);
        };

        const mainToolTipGrp = selected
          .selectAll('.line-point-tooltip')
          .data((d, i) => d[0] || [])
          .join(
            (enter) => {
              const tooltipGrp = enter
                .append('g')
                .attr('class', 'line-point-tooltip');

              toolTipFun(tooltipGrp);
            },
            (update) => update,
            (exit) => {
              exit.remove();
            }
          );

        toolTipFun(mainToolTipGrp);
      }

      selected.transition(t).attr('width', config.width);

      if (config.enableTooltipTrendPointer) {
        // Find the closest index across all trendlines
        function findClosestIndex(mouseX) {
          let closestIndex = null;
          let minDistance = Infinity;

          // Iterate through all data points across trendlines
          selected.data().forEach((dataArray) => {
            dataArray.flat().forEach((d) => {
              const pointX = config.xScale(d.label);
              const distance = Math.abs(mouseX - pointX);

              if (distance < minDistance) {
                minDistance = distance;
                closestIndex = d.index; // Use index to match points across trendlines
              }
            });
          });

          return closestIndex;
        }

        // Find the closest points from all trendlines that match the closest index
        function findClosestPoints(mouseX) {
          const closestIndex = findClosestIndex(mouseX);
          const closestPoints = [];

          // Extract data points from all trendlines that match the closest index
          selected.data().forEach((dataArray) => {
            dataArray.flat().forEach((d) => {
              if (d.index === closestIndex) {
                closestPoints.push(d);
              }
            });
          });

          return closestPoints;
        }
        // Function to clear hover dots and hide vertical line
        function removeHoverGroup() {
          selected.selectAll('.hover-group').remove();
        }

        if (config.graphAreaW) {
          removeHoverGroup();
        }
        // Create a group for hover dots and vertical line
        const hoverGroup = selected.append('g').attr('class', 'hover-group');

        // Create vertical line
        const verticalLine = hoverGroup
          .append('line')
          .attr('class', 'vertical-line')
          .attr('y1', 0)
          .attr('y2', config.graphAreaH)
          .style('stroke', '#A9ADBE')
          .style('stroke-width', 1)
          .style('stroke-dasharray', '1,1')
          .style('opacity', 0);

        function createHoverDots() {
          hoverGroup
            .selectAll('.hover-dot')
            .data([]) // Clear existing dots
            .join(
              (enter) =>
                enter
                  .append('circle')
                  .attr('class', 'hover-dot')
                  .attr('r', config.r)
                  .style('fill', 'white')
                  .style('opacity', 0)
                  .style('pointer-events', 'none'),
              (update) => update,
              (exit) => exit.remove()
            );
        }

        selected
          .selectAll('.line-bg-rect')
          .data((d, i) => {
            return data.flat();
          })
          .join(
            (enter) =>
              enter
                .append('rect')
                .attr('class', 'line-bg-rect')
                .attr('fill', 'transparent')
                .attr('x', (d, i) =>
                  i === 0
                    ? config.xScale.step() * i
                    : config.xScale.step() / 2 +
                      config.xScale.step() * (i <= data[0]?.length ? i - 1 : 0)
                )
                .attr('width', (d, i) =>
                  i === 0
                    ? config.xScale.step() / 2
                    : data[0].length - 1 === i
                    ? config.xScale.step() / 2
                    : i <= data[0]?.length && config.xScale.step()
                )
                .attr('height', config.graphAreaH)
                .on('mouseover', function (event) {
                  // Clear existing hover dots and hide the vertical line immediately
                  const [mouseX] = d3.pointer(event);
                  const closestPoints = findClosestPoints(mouseX);

                  // Update vertical line
                  verticalLine
                    .attr('x1', config.xScale(closestPoints[0].label))
                    .attr('x2', config.xScale(closestPoints[0].label))
                    .style('opacity', 1);
                  // Find the closest point on the trendline based on the x coordinate
                  createHoverDots();

                  hoverGroup
                    .selectAll('.hover-dot')
                    .data(closestPoints)
                    .join(
                      (enter) =>
                        enter
                          .append('circle')
                          .attr('class', 'hover-dot')
                          .attr('r', config.r)
                          .style('fill', 'white')
                          .attr('stroke', (d) => d.color || 'white')
                          .attr(
                            'stroke-width',
                            config.pointCircleStrokeWidth || 2
                          )
                          .style('opacity', 0)
                          .style('pointer-events', 'none'),
                      (update) => update,
                      (exit) => exit.remove()
                    )
                    .attr('cx', (d) => config.xScale(d.label))
                    .attr('cy', (d) => config.yScale(d.value))
                    .style('opacity', 1);
                })
                .on('mouseout', function () {
                  hoverGroup.selectAll('.hover-dot').style('opacity', 0);
                  verticalLine.style('opacity', 0);
                }),

            (update) =>
              update

                .attr('x', (d, i) =>
                  i === 0
                    ? config.xScale.step() * i
                    : config.xScale.step() / 2 +
                      config.xScale.step() * (i <= data[0]?.length ? i - 1 : 0)
                )
                .attr('width', (d, i) =>
                  i === 0
                    ? config.xScale.step() / 2
                    : data[0].length - 1 === i
                    ? config.xScale.step() / 2
                    : i <= data[0]?.length && config.xScale.step()
                )
                .attr('height', config.graphAreaH)
                .on('mouseover', function (event) {
                  // Clear existing hover dots and hide the vertical line immediately
                  const [mouseX] = d3.pointer(event);
                  const closestPoints = findClosestPoints(mouseX);

                  // Update vertical line
                  verticalLine
                    .attr('x1', config.xScale(closestPoints[0].label))
                    .attr('x2', config.xScale(closestPoints[0].label))
                    .style('opacity', 1);
                  // Find the closest point on the trendline based on the x coordinate
                  createHoverDots();

                  hoverGroup
                    .selectAll('.hover-dot')
                    .data(closestPoints)
                    .join(
                      (enter) =>
                        enter
                          .append('circle')
                          .attr('class', 'hover-dot')
                          .attr('r', config.r)
                          .style('fill', 'white')
                          .attr('stroke', (d) => d.color || 'white')
                          .attr(
                            'stroke-width',
                            config.pointCircleStrokeWidth || 2
                          )
                          .style('opacity', 0)
                          .style('pointer-events', 'none'),
                      (update) => update,
                      (exit) => exit.remove()
                    )
                    .attr('cx', (d) => config.xScale(d.label))
                    .attr('cy', (d) => config.yScale(d.value))
                    .style('opacity', 1);
                })
                .on('mouseout', function () {
                  hoverGroup.selectAll('.hover-dot').style('opacity', 0);
                  verticalLine.style('opacity', 0);
                }),
            (exit) => {
              exit.remove();
            }
          );
      } else {
        selected
          .selectAll('.line-bg-rect')
          .data((d, i) => {
            return d[0].map((ele, ii) => {
              const newEle = { ...ele };
              for (let iii = 0; iii < d.length; iii++) {
                newEle[d[iii][ii].labelText] = d[iii][ii].value;
                newEle[`${d[iii][ii].labelText}Color`] = d[iii][ii]?.color;
              }
              return newEle;
            });
          })
          .join(
            (enter) => {
              enter
                .append('rect')
                .attr('class', 'line-bg-rect')
                .attr('fill', 'transparent')
                .attr('x', (d, i) =>
                  i === 0
                    ? config.xScale.step() * i
                    : config.xScale.step() / 2 + config.xScale.step() * (i - 1)
                )
                .attr('width', (d, i) =>
                  i === 0
                    ? config.xScale.step() / 2
                    : data[0].length - 1 === i
                    ? config.xScale.step() / 2
                    : config.xScale.step()
                )
                .attr('height', config.graphAreaH);
            },
            (update) => update,
            (exit) => {
              exit.remove();
            }
          )
          .attr('x', (d, i) =>
            i === 0
              ? config.xScale.step() * i
              : config.xScale.step() / 2 + config.xScale.step() * (i - 1)
          )
          .attr('width', (d, i) =>
            i === 0
              ? config.xScale.step() / 2
              : data[0].length - 1 === i
              ? config.xScale.step() / 2
              : config.xScale.step()
          )
          .attr('height', config.graphAreaH);
      }

      if (config.rectIndicator) {
        selected
          .selectAll('.line-rect-indicator-rect')
          .data([data[config.selected || 0]])
          .join(
            (enter) => {
              enter
                .append('rect')
                .attr('class', 'line-rect-indicator-rect')
                .style('fill', config.indicatorColor || '#cccccc80')
                .attr(
                  'x',
                  config.selected === 0
                    ? config.xScale.step() * config.selected
                    : config.xScale.step() / 2 +
                        config.xScale.step() * (config.selected - 1)
                )
                .attr('width', (d, i) =>
                  config.selected === 0
                    ? config.xScale.step() / 2
                    : data[0].length - 1 === config.selected
                    ? config.xScale.step() / 2
                    : config.xScale.step()
                )
                .attr('y', 0)
                .transition(t)
                .attr('height', config.graphAreaH);
            },
            (update) => update,
            (exit) => {
              exit.transition(t).attr('height', 0).attr('width', 0).remove();
            }
          )
          .transition(t)
          .style('fill', config.indicatorColor || '#cccccc80')
          .attr(
            'x',
            config.selected === 0
              ? config.xScale.step() * config.selected
              : config.xScale.step() / 2 +
                  config.xScale.step() * (config.selected - 1)
          )
          .attr('width', (d, i) =>
            config.selected === 0
              ? config.xScale.step() / 2
              : data[0].length - 1 === config.selected
              ? config.xScale.step() / 2
              : config.xScale.step()
          )
          .attr('y', 0)
          .attr('height', config.graphAreaH);
      }

      if (config.enablePeaks) {
        // Flatten and sort data to find top peaks

        data?.forEach((trendlineData, index) => {
          const sortedData = trendlineData.filter((trend) =>
            trend?.type === 'social'
              ? 'socialSummary' in trend?.rawData
              : 'traditionalSummary' in trend?.rawData
          );
          const filteredPeaks = sortedData.slice(0, 4);
          const peakGroups = selected
            .selectAll(`.peak-circle-grp-${index}`)
            .data(filteredPeaks, (d) => d.label);

          const enterSelection = peakGroups
            .enter()
            .append('g')
            .attr('class', `peak-circle-grp-${index}`)
            .attr(
              'transform',
              (d) =>
                `translate(${config.xScale(d.label)},${config.yScale(d.value)})`
            );

          // Add peak images and hover behavior
          enterSelection
            .append('image')
            .attr('width', 35)
            .attr('height', 35)
            .attr('x', -17.5)
            .attr('y', -30)
            .attr('xlink:href', (d) => {
              return d?.value === d?.data?.socialCount ? peaksPrint : peaks;
            })
            .on('mouseover', function (event, d) {
              d3.selectAll('.result_tooltip').style('display', 'none');
              d3.selectAll('.peak-circle-grp-0 image, .peak-circle-grp-1 image')
                .filter(function () {
                  return this !== event.target;
                })
                .transition()
                .delay(100)
                .duration(200)
                .style('opacity', 0.1);

              // Append foreignObject on hover
              const foreignObject = d3
                .select(this.parentNode)
                .append('foreignObject')
                .attr('width', 220)
                .attr('height', 330)
                .attr('x', event.x > 700 ? -250 : 30)
                .attr('y', -80)
                .style('opacity', 0);

              foreignObject
                .transition()
                .delay(200)
                .duration(300)
                .style('opacity', 1);

              foreignObject.html(
                () => `
        <div style="box-shadow: 0px 12px 24px -12px #00000052; position: relative; z-index: 12; background-color: white; border: 1px solid #DDE1E6; border-radius: 8px; padding: 11px;">
          <div style="font-size: 12px; font-weight: 600; color: ${
            d?.type === 'traditional'
              ? purpleColorGradients.purple80
              : magentaColorGradients.magenta70
          };">
            <span style="font-size: 12px; background-color: white; font-weight: 600; color: #4D5358;">
              ${d.label}
            </span>
            ${d.value} Results
          </div>
          <div style="font-size: 11px; font-weight: 500; background-color: white; line-height: 16px; color: ${
            d?.type === 'traditional'
              ? purpleColorGradients.purple80
              : magentaColorGradients.magenta70
          }; margin-top: 2px;">
            <style>
              ul {
                padding-inline-start: 20px; 
              }
            </style>
            ${
              d?.type === 'social'
                ? marked(d?.data?.socialSummary)
                : d?.data?.traditionalSummary
                ? marked(d?.data?.traditionalSummary)
                : ''
            }
          </div>
          <div style="font-size: 11px; background-color: white; font-weight: 500; color: #697077;">
            Powered by AI
          </div>
        </div>
      `
              );

              // mixpanel event
              trackEvent('Results Over Time - Insights Hover', {});
            })
            .on('mousemove', function () {
              // Keep the tooltip hidden while moving over the image
              d3.selectAll('.result_tooltip').style('display', 'none');
            })
            .on('mouseout', function () {
              d3.selectAll('.result_tooltip').style('display', 'block');
              d3.select(this.parentNode).selectAll('foreignObject').remove();
              d3.selectAll('.peak-circle-grp-0 image, .peak-circle-grp-1 image')
                .transition()
                .delay(100)
                .duration(500)
                .style('opacity', 1);
            });

          // Update phase: Update existing peak circle groups
          peakGroups.attr(
            'transform',
            (d) =>
              `translate(${config.xScale(d.label)},${config.yScale(d.value)})`
          );

          setUpEvents(config, selected, `peak-circle-grp-${index}`);

          // Exit phase: Remove old peak circle groups
          peakGroups.exit().remove();
        });
        // const sortedData = data
        //   .flat(Infinity)
        //   .sort((a, b) => b.value - a.value);
        // console.log({ data });
        // const topFourPeaks = sortedData.slice(0, 4);
        // const filteredPeaks = topFourPeaks.filter(
        //   (d) => d.data && d.data.summary
        // );

        // console.log({ topFourPeaks, filteredPeaks });

        // // Select peak circle groups and bind data
        // const peakGroups = selected
        //   .selectAll('.peak-circle-grp')
        //   .data(filteredPeaks, (d) => d.label);

        // console.log({ peakGroups });

        // Enter phase: Append new peak circle groups
        // const enterSelection = peakGroups
        //   .enter()
        //   .append('g')
        //   .attr('class', 'peak-circle-grp')
        //   .attr(
        //     'transform',
        //     (d) =>
        //       `translate(${config.xScale(d.label)},${config.yScale(d.value)})`
        //   );

        // enterSelection
        //   .append('image')
        //   .attr('width', 35)
        //   .attr('height', 35)
        //   .attr('x', -17.5)
        //   .attr('y', -30)
        //   .attr('xlink:href', (d) => {
        //     const mediaType = getSelectedTypes(d?.rawData);
        //     return mediaType === 'social' ? peaksPrint : peaks;
        //   })
      }

      setUpEvents(config, selected, 'line-bg-rect');
      setUpEvents(config, selected, 'peak-circle-grp');
    });

    return selected;
  }

  graph.config = function graphConfig(val) {
    if (!arguments.length) {
      return config;
    }
    config = Object.assign(config, val);
    return graph;
  };

  return graph;
};

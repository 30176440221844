import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import {
  CampNoDatatxtWrp,
  FullSlot,
  IconBox,
  Iconwpr,
  LegendBox,
  LegendCon,
  LegendItem,
  LegendLabel,
  SlotBody,
  SlotBodyHeader,
  SlotLeftWrp,
  SlotBodyHeaderRight,
  SlotBodyMain,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  SlotFooter,
  // SlotFooter,
  SlotHeader,
  SlotHeaderLeft,
  SlotTitle,
  SlotOverviewWrapper,
  HalfSlot,
  SlotWrp,
  StageContainer,
  StageItem,
  StageDot,
  Label,
  Divider,
  EngagementContainer,
  EngagementLabel,
  GradientBar,
  TimeLineLabel,
  SubTitleSmallWrp,
  SlotHeaderRight,
  FilterError,
} from './index.sc';
// import Edit2 from '../../../assets/icons/Edit2';
// import { VerticleDots } from '../../../assets/icons/VerticleDots';
import Loader from '../../loader';
import {
  HotHeatMap,
  BestBar,
  LinearLine,
  ConcentricPieChart,
} from '../../../graphs';
import PortalTooltip from '../../portal-tooltip';
import {
  CommentaryLabel,
  CommentarySection,
  // CommentaryLabel,
  // CommentarySection,
} from '../../search-result/index.sc';
import SlotOverview from '../../search-result/slot-details/SlotOverview';
import ChartToolTip from '../../chart-tool-tip';
import HelpIcon from '../../../assets/icons/HelpIcon';
import SocialCampaignTable from '../../../graphs/social-campaign-table';
import { theme } from '../../../constants/theme';
import SocialTooltip from './tooltip';
import {
  coolGrayColorGradients,
  greenColorGradients,
  magentaColorGradients,
  orangeColorGradients,
  redColorGradients,
  tealColorGradients,
} from '../../../constants/graph-colors';
import { useSelector } from 'react-redux';
import ResultsInFigure from '../../results-in-figure';
import SimpleReusableDropDown from '../../simple-dropdown';
import { VerticleDots } from '../../../assets/icons/VerticleDots';

// Edit icon imports
import DashboardPopup from '../../dasboard-popup';
import Edit2 from '../../../assets/icons/Edit2';
import EditGraphPopup from '../../edit-graph-popup';
import AiIcon from '../../../assets/icons/AI';

const stages = [
  { label: 'Pre', color: 'linear-gradient(0deg, #FFAFD2 0%, #FFFFFF 100.08%)' },
  {
    label: 'During',
    color: 'linear-gradient(0deg, #F1C21B 0%, #FFFFFF 100.08%)',
  },
  { label: 'Post', color: 'linear-gradient(360deg, #3DDBD9 0%, #FFFFFF 100%)' },
];

const sentiment = [
  {
    label: 'Positive',
    color: greenColorGradients.green50,
  },
  {
    label: 'Negative',
    color: redColorGradients.red50,
  },
  {
    label: 'Neutral',
    color: coolGrayColorGradients.coolGray40,
  },
];

const SocialCampaignMonitor = ({
  filterType,
  widget,
  loader,
  type = 'dashboard',
  resetSelection = false,
  handleOnClick = () => {},
  helperText = '',
  setArticleType,
  setClickedPosition,
  setSelectedComponent,
  selectedComponent,
  graphDownloading,
  onDownloadChartData,
  downloadFunction,
  savedSelectedChart,
  handleGraphTitleUpdate,
}) => {
  const { tabsData, activeTab } = useSelector(
    (state) => state.advancedDashboardTab
  );
  // chart data
  const advocates = {
    ...widget.advocates,
  };
  const detractors = {
    ...widget.detractors,
  };
  const contentChart = {
    ...widget.advanced_campaign_content_type,
  };
  const sentimentOverTime = {
    ...widget.advanced_campaign_sentiment_over_time,
  };
  const resultOverTime = {
    ...widget.advanced_result_over_time,
  };

  const topThemesChart = {
    ...widget.advanced_top_themes,
  };
  const topThemesPreChart = {
    ...widget.advanced_top_themes_pre,
  };
  const topThemesDuringChart = {
    ...widget.advanced_top_themes_during,
  };
  const topThemesPostChart = {
    ...widget.advanced_top_themes_post,
  };
  const weektimeEngagement = {
    ...widget.advanced_campaign_engagement,
  };
  const socialOverview = {
    ...widget.advanced_social_overview,
  };

  const showFilterError = filterType === 'traditional';

  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [chartToolTip, setChartToolTip] = useState();
  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });
  const [activeChart, setActiveChart] = useState(null);
  const [openActionDropdown, setOpenActionDropDown] = useState(false);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [selectedChartId, setSelectedChartId] = useState('');
  const [selectedGraphEdit, setSelectedGraphEdit] = useState('');

  const [editChart2, setEditChart2] = useState({
    chartName: '',
    chartType: widget?.graphType || '',
  });

  const titleRefs = useRef({});
  const downloadRef = useRef(null);
  const containerRef = useRef(null);

  const tooltipEnabled = true;

  const handleMouseEnter = (event, d, i, chartId) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setToolTipPos({
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d.data || d;
      setTooltipData({
        data: {
          ...tData,
          value: tData?.articleCount,
        },
        rawData: d?.rawData,
      });
      setActiveChart(chartId);
    }
  };

  const handleMouseMove = (event, d, i, chartId) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };

  const handleMouseLeave = (event, d, i, chartId) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
      setActiveChart(null);
    }
  };

  const defaultConfig = (chartId) => {
    return {
      handleMouseEnter: (event, d, i) => handleMouseEnter(event, d, i, chartId),
      handleMouseMove: (event, d, i) => handleMouseMove(event, d, i, chartId),
      handleMouseLeave: (event, d, i) => handleMouseLeave(event, d, i, chartId),
      handleOnClick: (event, d) => handleOnClick(event, d, chartId),
    };
  };

  const handleClickOutside = (event) => {
    if (downloadRef.current && !downloadRef.current.contains(event.target)) {
      setOpenActionDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleOptionIcon = (e, componentName) => {
    e.stopPropagation();
    setSelectedComponent(componentName);
    setOpenActionDropDown((prev) =>
      prev === componentName ? false : componentName
    );
  };

  const handleGraphEditClick = (widget) => {
    // e.preventDefault();
    setSelectedGraphEdit(widget?.data?.title);
    setSelectedChartId(widget?.customClassName);
    setPopupIsOpen(!popupIsOpen);
  };

  useEffect(() => {
    if (titleRefs.current) {
      const titleWidth = titleRefs?.current[chartToolTip]?.offsetWidth;

      setIconPosition((prevPositions) => ({
        ...prevPositions,
        [chartToolTip]: titleWidth + 24, // Added width of icon and padding
      }));
    }
  }, [widget, chartToolTip]);

  const setTitleRef = (id, el) => {
    if (el) {
      titleRefs.current[id] = el;
    }
  };

  const handleEditChart = (data) => {
    setEditChart2({ ...editChart2, [selectedChartId]: { ...data } });
  };

  const renderEditIcon = (widget) => {
    if (widget?.show) {
      return (
        // role !== reader &&
        <Iconwpr
          width="1.5rem"
          height="1.5rem"
          onClick={() => handleGraphEditClick(widget)}
          className="hide-downloading"
        >
          <Edit2 />
        </Iconwpr>
      );
    }
    return null;
  };

  const renderDownloadOptions = (widget) => {
    return (
      <SlotHeaderRight>
        {renderEditIcon(widget)}
        <Iconwpr
          width={'1.5rem'}
          height={'1.5rem'}
          onClick={(e) => {
            handleOptionIcon(e, widget.customClassName);
          }}
          ref={downloadRef}
          className="hide-downloading"
        >
          <VerticleDots
            color={
              openActionDropdown === widget?.customClassName
                ? '#675ef2'
                : '#5C5E60'
            }
          />
          <SimpleReusableDropDown
            isOpen={openActionDropdown === widget.customClassName}
            options={actionDropDownOptions}
            graphDownloading={graphDownloading}
            setIsOpen={setOpenActionDropDown}
          />
        </Iconwpr>
      </SlotHeaderRight>
    );
  };

  const handleUpdateGraph = async (e, data, updatedChartData) => {
    e.stopPropagation();
    // console.log(data, 'edit data');
    // handleUpdatedChart(data);
    const chartData = savedSelectedChart?.data?.find(
      (chart) => chart?.default_name === graphData?.title
    );
    handleGraphTitleUpdate(
      updatedChartData,
      data,
      chartData,
      selectedChartId,
      data?.chartType || ''
    );
    handleGraphEditClick(e);
  };
  const graphData = widget;

  const actionDropDownOptions = [
    {
      label: 'Download Image',
      type: 'Image',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData[selectedComponent]);
        setOpenActionDropDown(false);
      },
    }, // Replace <Icon1 /> with your actual icon component
    {
      label: 'Download Pdf',
      type: 'PDF',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData[selectedComponent]);
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Excel',
      type: 'xlsx',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData[selectedComponent]);
      },
    },
    {
      label: 'Download CSV',
      type: 'csv',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData[selectedComponent]);
      },
    },
  ];

  return (
    <>
      {socialOverview?.data && (
        <div style={{ marginBottom: '0.75rem' }}>
          <ResultsInFigure
            tileDetails={socialOverview?.data}
            selectedMedia={['social']}
          />
        </div>
      )}
      <FullSlot className="graph-widget override-padding">
        <SlotDetailsMainWrp
          className={resultOverTime.customClassName}
          ref={
            selectedComponent === resultOverTime.customClassName
              ? containerRef
              : null
          }
        >
          <SlotDetailsWrp>
            <SlotHeader className="hide-download">
              <SlotHeaderLeft>
                <SlotLeftWrp>
                  <SlotTitle
                    ref={(el) =>
                      setTitleRef(resultOverTime?.customClassName, el)
                    }
                  >
                    {savedSelectedChart?.find(
                      (chart) =>
                        chart.chartId === resultOverTime?.customClassName
                    )?.chartName ||
                      editChart2[resultOverTime?.customClassName]?.chartName ||
                      'Result Over Time'}
                  </SlotTitle>
                  <span
                    style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                    onMouseEnter={() =>
                      setChartToolTip(resultOverTime?.customClassName)
                    }
                    onMouseLeave={() => setChartToolTip(null)}
                  >
                    <HelpIcon />
                  </span>
                </SlotLeftWrp>

                <SlotOverviewWrapper>
                  {resultOverTime.show ? (
                    <SlotOverview
                      summary={resultOverTime?.data?.data?.summary}
                    />
                  ) : (
                    <SubTitleSmallWrp>--</SubTitleSmallWrp>
                  )}
                </SlotOverviewWrapper>
              </SlotHeaderLeft>
              <SlotHeaderRight>
                <AiIcon
                  color={coolGrayColorGradients.coolGray50}
                  fill={coolGrayColorGradients.coolGray50}
                />
                {renderEditIcon(resultOverTime)}
                <Iconwpr
                  width={'1.5rem'}
                  height={'1.5rem'}
                  onClick={(e) => {
                    handleOptionIcon(e, resultOverTime?.customClassName);
                  }}
                  ref={downloadRef}
                  className="hide-downloading"
                >
                  <VerticleDots
                    color={
                      openActionDropdown === resultOverTime?.customClassName
                        ? '#675ef2'
                        : '#5C5E60'
                    }
                  />
                  <SimpleReusableDropDown
                    isOpen={
                      openActionDropdown === resultOverTime?.customClassName
                    }
                    options={actionDropDownOptions}
                    graphDownloading={graphDownloading}
                    setIsOpen={setOpenActionDropDown}
                  />
                </Iconwpr>
              </SlotHeaderRight>

              {chartToolTip === resultOverTime?.customClassName && (
                <ChartToolTip
                  text={resultOverTime?.text}
                  iconTop={-9}
                  componentTop={8}
                  componentLeft={iconPosition?.[chartToolTip]}
                />
              )}
            </SlotHeader>

            {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
            <SlotBody type={type} className="commentary">
              {showFilterError ? (
                <FilterError>
                  This chart requires Social Media Data Please check the Media
                  type filter
                </FilterError>
              ) : (
                <SlotBodyMain>
                  {resultOverTime.isLoading ? (
                    <Loader />
                  ) : !resultOverTime.show ? null : resultOverTime?.data?.data
                      ?.data?.length > 0 ? (
                    <LinearLine
                      data={resultOverTime?.data?.data}
                      config={{
                        graphType: 'area',
                        yAxisType: 'number',
                        yDomainMultiplayer: 0.001,
                        gridYTicks: 5,
                        yLabelAlignment: 40,
                        yAxisTicksFormat: true,
                        graphAreaWMultiplayer: 1,
                        enableTooltipTrendPointer: true,
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: 11,
                        fontColor: '#697077',
                        enableGradient: true,
                        enableAreaLine: true,
                        enableGridXLine: false,
                        enableGridYLine: true,
                        gridLineStrokeWidth: 1,
                        gridLineXStroke: '#d9dbde',
                        type: 'dashboard',
                        summaryContainerHeight: 0,
                        singleLineWrp: false,
                        showAllLabels: true,
                        ...defaultConfig(resultOverTime.customClassName),
                      }}
                      resetSelection={resetSelection}
                    />
                  ) : (
                    <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                  )}
                  {enableTooltip &&
                    activeChart === resultOverTime.customClassName && (
                      <PortalTooltip
                        isOpen={true}
                        pos={toolTipPos}
                        align={
                          toolTipPos.left > window.innerWidth / 2
                            ? 'left'
                            : 'right'
                        }
                        vAlign={
                          toolTipPos.top > window.innerHeight / 2
                            ? 'top'
                            : 'bottom'
                        }
                      >
                        <SocialTooltip
                          tooltipData={tooltipData}
                          widget={resultOverTime}
                        />
                      </PortalTooltip>
                    )}
                </SlotBodyMain>
              )}
            </SlotBody>
            <SlotFooter>
              <LegendCon>
                {tabsData[activeTab]?.launch_timeline?.pre_start_date && (
                  <StageContainer>
                    {stages.map((stage, index) => (
                      <StageItem key={stage.label}>
                        <StageDot color={stage.color} />
                        <Label>{stage.label}</Label>
                        {index < stages.length - 1 && <Divider>|</Divider>}
                      </StageItem>
                    ))}
                  </StageContainer>
                )}
              </LegendCon>

              <SlotBodyHeaderRight>
                {' '}
                <LegendCon>
                  {widget?.data?.map((ele, i) => {
                    return (
                      <LegendItem key={i}>
                        <LegendBox legendColor={ele.color}></LegendBox>
                        <LegendLabel>{ele?.label}</LegendLabel>
                      </LegendItem>
                    );
                  })}
                </LegendCon>
              </SlotBodyHeaderRight>
            </SlotFooter>
          </SlotDetailsWrp>
        </SlotDetailsMainWrp>
      </FullSlot>
      <FullSlot className="graph-widget override-padding">
        <SlotDetailsMainWrp
          className={topThemesChart.customClassName}
          ref={
            selectedComponent === topThemesChart.customClassName
              ? containerRef
              : null
          }
        >
          <SlotDetailsWrp hideGap={true}>
            <SlotHeader className="hide-download">
              <SlotHeaderLeft>
                <SlotLeftWrp>
                  <SlotTitle
                    ref={(el) =>
                      setTitleRef(topThemesChart?.customClassName, el)
                    }
                  >
                    {savedSelectedChart?.find(
                      (chart) =>
                        chart.chartId === topThemesChart?.customClassName
                    )?.chartName ||
                      editChart2[resultOverTime?.customClassName]?.chartName ||
                      'Top Themes'}
                  </SlotTitle>
                  <span
                    style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                    onMouseEnter={() =>
                      setChartToolTip(topThemesChart?.customClassName)
                    }
                    onMouseLeave={() => setChartToolTip(null)}
                  >
                    <HelpIcon />
                  </span>
                </SlotLeftWrp>

                <SlotOverviewWrapper>
                  {topThemesChart.show ? (
                    <SlotOverview
                      summary={topThemesChart?.data?.data?.summary}
                    />
                  ) : (
                    <SubTitleSmallWrp>--</SubTitleSmallWrp>
                  )}
                </SlotOverviewWrapper>
              </SlotHeaderLeft>
              <SlotHeaderRight>
                <AiIcon
                  color={coolGrayColorGradients.coolGray50}
                  fill={coolGrayColorGradients.coolGray50}
                />
                {renderEditIcon(topThemesChart)}
                <Iconwpr
                  width={'1.5rem'}
                  height={'1.5rem'}
                  onClick={(e) => {
                    handleOptionIcon(e, topThemesChart.customClassName);
                  }}
                  ref={downloadRef}
                  className="hide-downloading"
                >
                  <VerticleDots
                    color={
                      openActionDropdown === topThemesChart.customClassName
                        ? '#675ef2'
                        : '#5C5E60'
                    }
                  />
                  <SimpleReusableDropDown
                    isOpen={
                      openActionDropdown === topThemesChart.customClassName
                    }
                    options={actionDropDownOptions}
                    graphDownloading={graphDownloading}
                    setIsOpen={setOpenActionDropDown}
                  />
                </Iconwpr>
              </SlotHeaderRight>

              {chartToolTip === topThemesChart.customClassName && (
                <ChartToolTip
                  text={topThemesChart?.text}
                  iconTop={-9}
                  componentTop={8}
                  componentLeft={iconPosition?.[chartToolTip]}
                />
              )}
            </SlotHeader>
            {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
            <SlotBody type={type} className="commentary">
              {showFilterError ? (
                <FilterError>
                  This chart requires Social Media Data Please check the Media
                  type filter
                </FilterError>
              ) : (
                <SlotBodyMain>
                  {topThemesChart.isLoading ||
                  topThemesPreChart.isLoading ||
                  topThemesPostChart.isLoading ||
                  topThemesDuringChart.isLoading ? (
                    <Loader />
                  ) : !topThemesChart.show ? null : topThemesChart?.data?.data
                      ?.data?.length > 0 ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {' '}
                      {!tabsData[activeTab]?.launch_timeline
                        ?.pre_start_date && (
                        <ConcentricPieChart
                          data={topThemesChart?.data?.data?.data}
                          config={{
                            ...defaultConfig(topThemesChart.customClassName),
                            renderKeyword: 4,
                          }}
                          resetSelection={resetSelection}
                        />
                      )}
                      {tabsData[activeTab]?.launch_timeline?.pre_start_date &&
                        tabsData[activeTab]?.launch_timeline
                          ?.during_start_date &&
                        tabsData[activeTab]?.launch_timeline
                          ?.post_start_date && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-evenly',
                              width: '100%',
                            }}
                          >
                            {' '}
                            <ConcentricPieChart
                              data={topThemesPreChart?.data?.data?.data}
                              config={{
                                ...defaultConfig(
                                  topThemesPreChart.customClassName
                                ),
                                renderKeyword: 4,
                              }}
                              resetSelection={resetSelection}
                            />
                            <ConcentricPieChart
                              data={topThemesDuringChart?.data?.data?.data}
                              config={{
                                ...defaultConfig(
                                  topThemesDuringChart.customClassName
                                ),
                                renderKeyword: 4,
                              }}
                              resetSelection={resetSelection}
                            />
                            <ConcentricPieChart
                              data={topThemesPostChart?.data?.data?.data}
                              config={{
                                ...defaultConfig(
                                  topThemesPostChart.customClassName
                                ),
                                renderKeyword: 4,
                              }}
                              resetSelection={resetSelection}
                            />
                          </div>
                        )}
                    </div>
                  ) : (
                    <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                  )}
                  {enableTooltip &&
                    (activeChart === topThemesChart.customClassName ||
                      activeChart === topThemesPreChart.customClassName ||
                      activeChart === topThemesDuringChart.customClassName ||
                      activeChart === topThemesPostChart.customClassName) && (
                      <PortalTooltip
                        isOpen={true}
                        pos={toolTipPos}
                        align={
                          toolTipPos.left > window.innerWidth / 2
                            ? 'left'
                            : 'right'
                        }
                        vAlign={
                          toolTipPos.top > window.innerHeight / 2
                            ? 'top'
                            : 'bottom'
                        }
                      >
                        <SocialTooltip
                          tooltipData={tooltipData}
                          widget={topThemesChart}
                        />
                      </PortalTooltip>
                    )}
                </SlotBodyMain>
              )}
            </SlotBody>
            <SlotFooter>
              {tabsData[activeTab]?.launch_timeline?.pre_start_date &&
                tabsData[activeTab]?.launch_timeline?.during_start_date &&
                tabsData[activeTab]?.launch_timeline?.post_start_date && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      width: '100%',
                    }}
                  >
                    <TimeLineLabel color={magentaColorGradients.magenta60}>
                      Pre
                    </TimeLineLabel>
                    <TimeLineLabel color={orangeColorGradients.orange60}>
                      During
                    </TimeLineLabel>
                    <TimeLineLabel color={tealColorGradients.teal60}>
                      Post
                    </TimeLineLabel>
                  </div>
                )}
            </SlotFooter>
          </SlotDetailsWrp>
        </SlotDetailsMainWrp>
      </FullSlot>
      <FullSlot className="graph-widget override-padding">
        <SlotDetailsMainWrp
          className={sentimentOverTime.customClassName}
          ref={
            selectedComponent === sentimentOverTime.customClassName
              ? containerRef
              : null
          }
        >
          <SlotDetailsWrp>
            <SlotHeader className="hide-download">
              <SlotHeaderLeft>
                <SlotLeftWrp>
                  <SlotTitle
                    ref={(el) =>
                      setTitleRef(sentimentOverTime?.customClassName, el)
                    }
                  >
                    {savedSelectedChart?.find(
                      (chart) =>
                        chart.chartId === sentimentOverTime?.customClassName
                    )?.chartName ||
                      editChart2[sentimentOverTime?.customClassName]
                        ?.chartName ||
                      'Sentiment Over Time'}
                  </SlotTitle>
                  <span
                    style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                    onMouseEnter={() =>
                      setChartToolTip(sentimentOverTime?.customClassName)
                    }
                    onMouseLeave={() => setChartToolTip(null)}
                  >
                    <HelpIcon />
                  </span>
                </SlotLeftWrp>

                <SlotOverviewWrapper>
                  {sentimentOverTime.show ? (
                    <SlotOverview
                      summary={sentimentOverTime?.data?.data?.summary}
                    />
                  ) : (
                    <SubTitleSmallWrp>--</SubTitleSmallWrp>
                  )}
                </SlotOverviewWrapper>
              </SlotHeaderLeft>
              <SlotHeaderRight>
                <AiIcon
                  color={coolGrayColorGradients.coolGray50}
                  fill={coolGrayColorGradients.coolGray50}
                />
                {renderEditIcon(sentimentOverTime)}
                <Iconwpr
                  width={'1.5rem'}
                  height={'1.5rem'}
                  onClick={(e) => {
                    handleOptionIcon(e, sentimentOverTime?.customClassName);
                  }}
                  ref={downloadRef}
                  className="hide-downloading"
                >
                  <VerticleDots
                    color={
                      openActionDropdown === sentimentOverTime?.customClassName
                        ? '#675ef2'
                        : '#5C5E60'
                    }
                  />
                  <SimpleReusableDropDown
                    isOpen={
                      openActionDropdown === sentimentOverTime?.customClassName
                    }
                    options={actionDropDownOptions}
                    graphDownloading={graphDownloading}
                    setIsOpen={setOpenActionDropDown}
                  />
                </Iconwpr>
              </SlotHeaderRight>

              {chartToolTip === sentimentOverTime?.customClassName && (
                <ChartToolTip
                  text={sentimentOverTime.text}
                  iconTop={-9}
                  componentTop={8}
                  componentLeft={iconPosition?.[chartToolTip]}
                />
              )}
            </SlotHeader>
            {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
            <SlotBody type={type} className="commentary">
              {showFilterError ? (
                <FilterError>
                  This chart requires Social Media Data Please check the Media
                  type filter
                </FilterError>
              ) : (
                <SlotBodyMain>
                  {sentimentOverTime.isLoading ? (
                    <Loader />
                  ) : !sentimentOverTime?.show ? null : sentimentOverTime?.data
                      ?.data?.data?.length > 0 ? (
                    <LinearLine
                      data={sentimentOverTime?.data?.data}
                      config={{
                        yAxisType: 'number',
                        yDomainMultiplayer: 0.001,
                        gridYTicks: 5,
                        yLabelAlignment: 40,
                        yAxisTicksFormat: true,
                        graphAreaWMultiplayer: 1,
                        enableTooltipTrendPointer: true,
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: 11,
                        fontColor: '#697077',
                        enableGridXLine: false,
                        enableGridYLine: true,
                        gridLineStrokeWidth: 1,
                        gridLineXStroke: '#d9dbde',
                        type: 'dashboard',
                        summaryContainerHeight: 0,
                        singleLineWrp: false,
                        showAllLabels: true,
                        ...defaultConfig(sentimentOverTime.customClassName),
                      }}
                      resetSelection={resetSelection}
                    />
                  ) : (
                    <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                  )}
                  {enableTooltip &&
                    activeChart === sentimentOverTime.customClassName && (
                      <PortalTooltip
                        isOpen={true}
                        pos={toolTipPos}
                        align={
                          toolTipPos.left > window.innerWidth / 2
                            ? 'left'
                            : 'right'
                        }
                        vAlign={
                          toolTipPos.top > window.innerHeight / 2
                            ? 'top'
                            : 'bottom'
                        }
                      >
                        <SocialTooltip
                          tooltipData={tooltipData}
                          widget={sentimentOverTime}
                        />
                      </PortalTooltip>
                    )}
                </SlotBodyMain>
              )}
            </SlotBody>
            <SlotFooter>
              <LegendCon>
                {tabsData[activeTab]?.launch_timeline?.pre_start_date && (
                  <StageContainer>
                    {stages.map((stage, index) => (
                      <StageItem key={stage.label}>
                        <StageDot color={stage.color} />
                        <Label>{stage.label}</Label>
                        {index < stages.length - 1 && <Divider>|</Divider>}
                      </StageItem>
                    ))}
                  </StageContainer>
                )}
              </LegendCon>

              <SlotBodyHeaderRight>
                {' '}
                {sentimentOverTime.show && (
                  <StageContainer>
                    {sentiment.map((sentiment, index) => (
                      <StageItem key={sentiment.label}>
                        <StageDot color={sentiment.color} />
                        <Label>{sentiment.label}</Label>
                        {index < sentiment.length - 1 && <Divider>|</Divider>}
                      </StageItem>
                    ))}
                  </StageContainer>
                )}
              </SlotBodyHeaderRight>
            </SlotFooter>
          </SlotDetailsWrp>
        </SlotDetailsMainWrp>
      </FullSlot>
      <FullSlot height={'35rem'} className="graph-widget override-padding">
        <SlotDetailsMainWrp
          className={weektimeEngagement.customClassName}
          ref={
            selectedComponent === weektimeEngagement.customClassName
              ? containerRef
              : null
          }
        >
          <SlotDetailsWrp>
            <SlotHeader className="hide-download">
              <SlotHeaderLeft>
                <SlotLeftWrp>
                  <SlotTitle
                    ref={(el) =>
                      setTitleRef(weektimeEngagement?.customClassName, el)
                    }
                  >
                    {savedSelectedChart?.find(
                      (chart) =>
                        chart.chartId === weektimeEngagement?.customClassName
                    )?.chartName ||
                      editChart2[weektimeEngagement?.customClassName]
                        ?.chartName ||
                      'Engagement by Time and Weekday'}
                  </SlotTitle>
                  <span
                    style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                    onMouseEnter={() =>
                      setChartToolTip(weektimeEngagement?.customClassName)
                    }
                    onMouseLeave={() => setChartToolTip(null)}
                  >
                    <HelpIcon />
                  </span>
                </SlotLeftWrp>
                <SlotOverviewWrapper>
                  {weektimeEngagement.show ? (
                    <SlotOverview summary={weektimeEngagement?.data?.summary} />
                  ) : (
                    <SubTitleSmallWrp>--</SubTitleSmallWrp>
                  )}
                </SlotOverviewWrapper>
              </SlotHeaderLeft>
              <SlotHeaderRight>
                <AiIcon
                  color={coolGrayColorGradients.coolGray50}
                  fill={coolGrayColorGradients.coolGray50}
                />
                {renderEditIcon(weektimeEngagement)}
                <Iconwpr
                  width={'1.5rem'}
                  height={'1.5rem'}
                  onClick={(e) => {
                    handleOptionIcon(e, weektimeEngagement?.customClassName);
                  }}
                  ref={downloadRef}
                  className="hide-downloading"
                >
                  <VerticleDots
                    color={
                      openActionDropdown === weektimeEngagement?.customClassName
                        ? '#675ef2'
                        : '#5C5E60'
                    }
                  />
                  <SimpleReusableDropDown
                    isOpen={
                      openActionDropdown === weektimeEngagement?.customClassName
                    }
                    options={actionDropDownOptions}
                    graphDownloading={graphDownloading}
                    setIsOpen={setOpenActionDropDown}
                  />
                </Iconwpr>
              </SlotHeaderRight>

              {chartToolTip === weektimeEngagement?.customClassName && (
                <ChartToolTip
                  text={weektimeEngagement.text}
                  iconTop={-9}
                  componentTop={8}
                  componentLeft={iconPosition?.[chartToolTip]}
                />
              )}
            </SlotHeader>
            {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
            <SlotBody type={type} className="commentary">
              {showFilterError ? (
                <FilterError>
                  This chart requires Social Media Data Please check the Media
                  type filter
                </FilterError>
              ) : (
                <SlotBodyMain>
                  {weektimeEngagement.isLoading ? (
                    <Loader />
                  ) : !weektimeEngagement.show ? null : weektimeEngagement?.data
                      ?.data?.data?.length > 0 ? (
                    <HotHeatMap
                      data={{
                        ...weektimeEngagement?.data?.data,
                        keyword: weektimeEngagement?.data?.keyword,
                      }}
                      config={{
                        xAxisType: 'text',
                        yLabelAlignment: 80,
                        hideYAxisLine: true,
                        hideXAxisLine: true,
                        hideYAxis: false,
                        enableGridXLine: false,
                        enableGridYLine: false,
                        ...defaultConfig(weektimeEngagement.customClassName),
                      }}
                      resetSelection={resetSelection}
                    />
                  ) : (
                    <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                  )}
                  {enableTooltip &&
                    activeChart === weektimeEngagement.customClassName && (
                      <PortalTooltip
                        isOpen={true}
                        pos={toolTipPos}
                        align={
                          toolTipPos.left > window.innerWidth / 2
                            ? 'left'
                            : 'right'
                        }
                        vAlign={
                          toolTipPos.top > window.innerHeight / 2
                            ? 'top'
                            : 'bottom'
                        }
                      >
                        <SocialTooltip
                          tooltipData={tooltipData}
                          widget={weektimeEngagement}
                        />
                      </PortalTooltip>
                    )}
                </SlotBodyMain>
              )}
            </SlotBody>
            <SlotFooter>
              <SlotFooter>
                <LegendCon></LegendCon>

                <SlotBodyHeaderRight>
                  {weektimeEngagement.show && (
                    <StageContainer>
                      <EngagementContainer>
                        <EngagementLabel>Low Engagement</EngagementLabel>
                        <GradientBar />
                        <EngagementLabel>High Engagement</EngagementLabel>
                      </EngagementContainer>
                    </StageContainer>
                  )}
                </SlotBodyHeaderRight>
              </SlotFooter>
            </SlotFooter>
          </SlotDetailsWrp>
        </SlotDetailsMainWrp>
      </FullSlot>
      <SlotWrp>
        <HalfSlot className="graph-widget override-padding">
          {' '}
          <SlotDetailsMainWrp
            className={detractors?.customClassName}
            ref={
              selectedComponent === detractors?.customClassName
                ? containerRef
                : null
            }
          >
            <SocialCampaignTable
              widget={detractors?.data}
              show={detractors.show}
              title={
                savedSelectedChart?.find(
                  (chart) => chart.chartId === detractors?.customClassName
                )?.chartName ||
                editChart2[detractors?.customClassName]?.chartName ||
                'Top Detractors'
              }
              filterError={showFilterError}
              handleDrillDown={setArticleType}
              setArticlePosition={setClickedPosition}
              helperText={detractors.text}
              graph={detractors}
              renderRightIcons={renderDownloadOptions}
            />
          </SlotDetailsMainWrp>
        </HalfSlot>
        <HalfSlot className="graph-widget override-padding">
          <SlotDetailsMainWrp
            className={advocates?.customClassName}
            ref={
              selectedComponent === advocates?.customClassName
                ? containerRef
                : null
            }
          >
            <SocialCampaignTable
              widget={advocates?.data}
              show={advocates.show}
              title={
                savedSelectedChart?.find(
                  (chart) => chart.chartId === advocates?.customClassName
                )?.chartName ||
                editChart2[advocates?.customClassName]?.chartName ||
                'Top Advocates'
              }
              helperText={advocates.text}
              filterError={showFilterError}
              handleDrillDown={setArticleType}
              setArticlePosition={setClickedPosition}
              graph={advocates}
              renderRightIcons={renderDownloadOptions}
            />
          </SlotDetailsMainWrp>
        </HalfSlot>
        <HalfSlot className="graph-widget override-padding">
          <SlotDetailsMainWrp
            className={contentChart?.customClassName}
            ref={
              selectedComponent === contentChart?.customClassName
                ? containerRef
                : null
            }
          >
            <SlotDetailsWrp>
              <SlotHeader className="hide-download">
                <SlotHeaderLeft>
                  <SlotLeftWrp>
                    <SlotTitle
                      ref={(el) =>
                        setTitleRef(contentChart?.customClassName, el)
                      }
                    >
                      {savedSelectedChart?.find(
                        (chart) =>
                          chart.chartId === contentChart?.customClassName
                      )?.chartName ||
                        editChart2[contentChart?.customClassName]?.chartName ||
                        'Content Type'}
                    </SlotTitle>
                    <span
                      style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                      onMouseEnter={() =>
                        setChartToolTip(contentChart?.customClassName)
                      }
                      onMouseLeave={() => setChartToolTip(null)}
                    >
                      <HelpIcon />
                    </span>
                  </SlotLeftWrp>
                  <SlotOverviewWrapper>
                    {contentChart.show ? (
                      <SlotOverview
                        summary={contentChart?.data?.data?.summary}
                      />
                    ) : (
                      <SubTitleSmallWrp>--</SubTitleSmallWrp>
                    )}
                  </SlotOverviewWrapper>
                </SlotHeaderLeft>
                <SlotHeaderRight>
                  <AiIcon
                    color={coolGrayColorGradients.coolGray50}
                    fill={coolGrayColorGradients.coolGray50}
                  />
                  {renderEditIcon(contentChart)}
                  <Iconwpr
                    width={'1.5rem'}
                    height={'1.5rem'}
                    onClick={(e) => {
                      handleOptionIcon(e, contentChart?.customClassName);
                    }}
                    ref={downloadRef}
                    className="hide-downloading"
                  >
                    <VerticleDots
                      color={
                        openActionDropdown === contentChart?.customClassName
                          ? '#675ef2'
                          : '#5C5E60'
                      }
                    />
                    <SimpleReusableDropDown
                      isOpen={
                        openActionDropdown === contentChart?.customClassName
                      }
                      options={actionDropDownOptions}
                      graphDownloading={graphDownloading}
                      setIsOpen={setOpenActionDropDown}
                    />
                  </Iconwpr>
                </SlotHeaderRight>

                {chartToolTip === contentChart?.customClassName && (
                  <ChartToolTip
                    text={contentChart.text}
                    iconTop={-9}
                    componentTop={8}
                    componentLeft={iconPosition?.[chartToolTip]}
                  />
                )}
              </SlotHeader>
              {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
              <SlotBody type={type} className="commentary">
                {showFilterError ? (
                  <FilterError>
                    This chart requires Social Media Data Please check the Media
                    type filter
                  </FilterError>
                ) : (
                  <SlotBodyMain>
                    {contentChart.isLoading ? (
                      <Loader />
                    ) : !contentChart?.show ? null : contentChart?.data?.data
                        ?.data?.length > 0 ? (
                      <BestBar
                        data={contentChart?.data?.data}
                        config={{
                          yLabelAlignment: 130,
                          yAxisTicksFormat: true,
                          yAxisType: 'text',
                          xAxisType: 'number',
                          xAxisTicksFormat: true,
                          wrapLength: 20,
                          gridLineXStroke: '#d9dbde',
                          gridLineStrokeWidth: 1,
                          enableGridXLine: true,
                          enableGridYLine: false,
                          graphXYLabelFontSize: 11,
                          fontFamily: 'Inter',
                          fontWeight: 500,
                          colorGradientDark: theme?.light?.graphColors?.blue50,
                          colorGradientLight: theme?.light?.graphColors?.cyan30,
                          dropShadowColor: theme?.light?.graphColors?.cyan40,
                          authorDataLength:
                            contentChart?.data?.data?.length * 10,
                          graphTopPadding: 0,
                          ...defaultConfig(contentChart.customClassName),
                        }}
                        resetSelection={resetSelection}
                      />
                    ) : (
                      <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                    )}
                    {enableTooltip &&
                      activeChart === contentChart.customClassName && (
                        <PortalTooltip
                          isOpen={true}
                          pos={toolTipPos}
                          align={
                            toolTipPos.left > window.innerWidth / 2
                              ? 'left'
                              : 'right'
                          }
                          vAlign={
                            toolTipPos.top > window.innerHeight / 2
                              ? 'top'
                              : 'bottom'
                          }
                        >
                          <SocialTooltip
                            tooltipData={tooltipData}
                            widget={contentChart}
                          />
                        </PortalTooltip>
                      )}
                  </SlotBodyMain>
                )}
              </SlotBody>
              <SlotFooter></SlotFooter>
            </SlotDetailsWrp>
          </SlotDetailsMainWrp>
        </HalfSlot>
      </SlotWrp>

      <DashboardPopup
        open={popupIsOpen}
        toggler={handleGraphEditClick}
        width={'40%'}
        popContent={
          <EditGraphPopup
            popupIsOpen={popupIsOpen}
            handleEditClick={handleUpdateGraph}
            handleClose={handleGraphEditClick}
            widgetTitle={
              savedSelectedChart?.find(
                (chart) => chart.chartId === selectedChartId
              )?.chartName || selectedGraphEdit
            }
            handleEdit={handleEditChart}
            widget={graphData}
          />
        }
      />
    </>
  );
};

export default SocialCampaignMonitor;

SocialCampaignMonitor.defaultProps = {
  type: 'dashboard',
};

SocialCampaignMonitor.propTypes = {
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  canvas: Proptypes.bool,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  customClassName: Proptypes.string,
  helperText: Proptypes.string,
  setArticleType: Proptypes.object,
  setClickedPosition: Proptypes.object,
  setSelectedComponent: Proptypes.func,
  selectedComponent: Proptypes.string,
  graphDownloading: Proptypes.bool,
  onDownloadChartData: Proptypes.func,
  downloadFunction: Proptypes.func,
  savedSelectedChart: Proptypes.object,
  handleGraphTitleUpdate: Proptypes.func,
  filterType: Proptypes.string,
};

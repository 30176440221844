import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import {
  AIIconWrp,
  AIText,
  IconWrp,
  AiSection,
  CommentaryLabel,
  CommentarySection,
  GraphTypeBtn,
  GraphTypeBtnWrapper,
  IconBox,
  Iconwpr,
  LegendSection,
  LineShow,
  // SlotBody,
  SlotSubTitle,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  SlotFooter,
  SlotHeader,
  SlotHeaderLeft,
  SlotTitle,
  LegendBox,
  LegendLabel,
} from './index.sc';
import { GraphNoDataText, SlotBody } from '../../index.sc';
import { graphTypes, widgetMapping } from '../../../../constants/widgets';
import { VerticleDots } from '../../../../assets/icons/VerticleDots';
// import Loader from '../../../loader';
import PortalTooltip from '../../../portal-tooltip';
import GraphTooltip from '../../../graph-tooltip';
import GraphLegend from '../../../graph-legend';
import CircularLoading from '../../../../assets/icons/loading/circularLoading';
import DownloadIcon from '../../../../assets/icons/DownloadIcon';
import SimpleReusableDropDown from '../../../simple-dropdown';
import HelpIcon from '../../../../assets/icons/HelpIcon';
import AiIcon from '../../../../assets/icons/AiIcon';
import Tooltip from '../../../icon-tooltip';
import ChartToolTip from '../../../chart-tool-tip';
import {
  blueColorGradients,
  coolGrayColorGradients,
  cyanColorGradients,
  magentaColorGradients,
  purpleColorGradients,
  tealColorGradients,
  yellowColorGradients,
} from '../../../../constants/graph-colors';
import { formatNumber } from '../../../../graphs/utils/graphGrid';
import ThreeDotsLoader from '../../../three-dots-loader';
import Prompt from '../../slot-details/Prompt';
import EnlargedCard from '../../slot-details/EnlargedCard';
import { reader } from '../../../../constants';
import { useSelector } from 'react-redux';
import { trackEvent } from '../../../../utils/mixPanel';

const gridXTicksCount = 6;

const tabChartConfig = {
  trendline: 'area',
  bar: 'column',
};

const social = [
  'X (Twitter)',
  'Blogs',
  'Forums',
  'Reviews',
  'Reddit',
  'YouTube',
];
const traditional = ['Online', 'Print'];

const getSelectedTypes = (filters) => {
  if (filters?.mediaType !== null) {
    const mediaTypes = filters?.mediaType?.split(',');
    const isSocial = mediaTypes?.some((x) => social?.includes(x));
    const isTraditional = mediaTypes?.some((x) => traditional?.includes(x));
    if (isSocial && isTraditional) {
      return 'all';
    }
    if (isSocial) {
      return 'social';
    }
    if (isTraditional) {
      return 'traditional';
    }
  }
  return 'all';
};

const generateGraphComponent = (
  widget,
  defaultConfig,
  type,
  dashboardType,
  canvas,
  resetSelection = false
) => {
  // console.log(widgetMapping, dashboardType, widget.component);

  const widgetDetails = {
    dashboardType,
    type,
    component: widget?.component,
  };

  const { bentoView } =
    (widgetMapping[dashboardType] &&
      widgetMapping[dashboardType][widget?.component]) ||
    {};

  const GraphComponent =
    type === 'dashboard' || type === 'l2'
      ? graphTypes[widget?.graphType]?.component
      : bentoView[type]?.component;

  const dataCount = widget?.data?.data?.length;
  const xTicksCount = dataCount < gridXTicksCount ? dataCount : gridXTicksCount;

  const canvasConfig = { gridXTicks: xTicksCount };

  const finalConfig = {
    ...(type === 'dashboard' || type === 'l2'
      ? graphTypes[widget?.graphType]?.config
      : bentoView[type]?.config),
    ...defaultConfig,
    ...widgetDetails,
    ...(canvas && canvasConfig),
    xAxisROTStartEndTicks: true,
  };

  return widget?.shouldShowGraph ? (
    <GraphComponent
      data={widget.data}
      config={finalConfig}
      resetSelection={resetSelection}
    />
  ) : (
    <GraphNoDataText>No Data</GraphNoDataText>
  );
};

const ResultOverTime = ({
  widget,
  loader,
  type = 'dashboard',
  dashboardType = 'overview',
  canvas = false,
  legend = false,
  commentary = false,
  resetSelection = false,
  handleOnClick = () => {},
  actionOption,
  showChangeGraphOptions = true,
  downloadFunction,
  setSelectedComponent,
  selectedComponent,
  graphDownloading,
  widgetClassName,
  helperText = '',
  onDownloadChartData,
  promptData,
  onPromptSubmit,
}) => {
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [tooltipEvent, setTooltipEvent] = useState({});
  const containerRef = useRef(null);

  const titleRef = useRef(null);
  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });

  const [chartTooltip, setChartTooltip] = useState(false);

  const [activeBtn, setActiveBtn] = useState('trendline');
  const [selectedWidget, setSelectedWidget] = useState(() => {
    if (widget && tabChartConfig && tabChartConfig[activeBtn]) {
      return widget[tabChartConfig[activeBtn]];
    } else {
      return null;
    }
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [cardData, setCardData] = useState(null); // Store the card's data
  const [openActionDropdown, setOpenActionDropDown] = useState(false);

  // Refs
  const downloadRef = useRef(null);

  const role = useSelector((state) => state?.user?.role);

  const onOpenPopup = (event, data) => {
    // Get the container using an ID or class
    const container = document.querySelector('#container');
    const containerRect = container.getBoundingClientRect(); // Container bounds

    const cardRect = event.target.closest('.card').getBoundingClientRect(); // Ensure the card is targeted

    // Calculate position relative to the container
    const cardPosition = {
      top: cardRect.top - containerRect.top,
      left: cardRect.right - cardRect.left,
      width: cardRect.width,
      height: cardRect.height,
    };

    // Decide the expansion direction based on the card's position
    const isCloserToLeft =
      cardRect.left + cardRect.width / 2 < containerRect.width / 2;

    setCardData({
      index: 0,
      position: cardPosition,
      direction: isCloserToLeft ? 'right' : 'left',
    });
    setIsPopupOpen((old) => !old);
  };

  const handleClickOutside = (event) => {
    if (downloadRef.current && !downloadRef.current.contains(event.target)) {
      setOpenActionDropDown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (widget && tabChartConfig && tabChartConfig[activeBtn]) {
      setSelectedWidget(
        JSON.parse(JSON.stringify(widget[tabChartConfig[activeBtn]]))
      );
    }
  }, [activeBtn, widget, tabChartConfig]);

  const tooltipEnabled = true;

  const handleMouseEnter = (event, d, i) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setTooltipEvent({
        event,
        d,
        i,
      });
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d.data || d;
      setTooltipData({ data: tData, rawData: d?.rawData });
    }
  };

  const handleMouseMove = (event, d, i) => {
    if (tooltipEnabled) {
      setTooltipEvent({
        event,
        d,
        i,
      });
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };
  const handleMouseLeave = (event, d, i) => {
    if (tooltipEnabled) {
      setTooltipEvent({});
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
    }
  };

  const customHandleClick = (event, d) => {
    handleOnClick(event, d, selectedWidget);
  };

  const defaultConfig = {
    handleMouseEnter,
    handleMouseMove,
    handleMouseLeave,
    handleOnClick: customHandleClick,
  };

  const actionDropDownOptions = [
    {
      label: 'Download Image',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, widget, selectedWidget?.title);
        setOpenActionDropDown(false);
      },
      type: 'Image',
    },
    {
      label: 'Download Pdf',
      type: 'PDF',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, widget, selectedWidget?.title);
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Excel',
      type: 'xlsx',
      clickFunction: (option) => {
        onDownloadChartData(option, widget);
      },
    },
    {
      label: 'Download CSV',
      type: 'csv',
      clickFunction: (option) => {
        onDownloadChartData(option, widget);
      },
    },
  ];

  let legendData = [];
  const twoD = selectedWidget?.data?.labels?.length > 1;

  if (twoD) {
    legendData = selectedWidget?.data?.labels.map((ele) => ({
      label: ele?.label,
      value: ele?.label?.replaceAll(' ', '').toLowerCase(),
      color: ele?.color,
    }));
  } else {
    legendData = selectedWidget?.data?.data?.map((ele) => ({
      label: ele?.label,
      value: ele?.value,
      color: ele?.color,
    }));
  }

  const handleTabBtnClick = (e, tab) => {
    e.stopPropagation();

    // mixpanel event
    trackEvent('Results Over Time - Chart View Switch', {
      from_view: activeBtn,
      to_view: tab,
    });

    setActiveBtn(tab);

    // handleUpdatedChart({
    //   chartName: selectedWidget.title,
    //   chartType: tabChartConfig[tab],
    //   customWidget: true,
    // });
  };
  const handleOptionIcon = (e, componentName) => {
    e.stopPropagation();
    setSelectedComponent(componentName);
    setOpenActionDropDown((prev) =>
      prev !== componentName ? componentName : false
    );
  };

  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;

      setIconPosition({
        left: titleWidth + 48, // Added width of icon and padding
      });
    }
  }, [helperText]);

  const traditional = {
    type: 'Traditional',
    color: purpleColorGradients.purple60,
    dataKey: 'totalTraditionCount',
  };

  const social = {
    type: 'Social',
    color: magentaColorGradients.magenta50,
    dataKey: 'totalSocialCount',
  };

  const mediaMappings = {
    social: [
      {
        ...social,
        type: 'Results',
      },
    ],
    traditional: [
      {
        ...traditional,
        type: 'Results',
      },
    ],
    all: [social, traditional],
  };

  const summary = selectedWidget?.data?.summary;
  const mediaType = summary?.mediaType;
  const mediaTypeArray = mediaType
    ? mediaType.split(',').map((type) => type.trim().toLowerCase())
    : null;

  const selectedMedia = getSelectedTypes(summary);

  const graph = (
    <>
      <IconBox
        type={type}
        style={{
          marginRight: '1.5rem',
          paddingTop: isPopupOpen ? '1.5rem' : 0,
        }}
        className="hide-download"
      >
        {dashboardType === 'overview' && (
          <Iconwpr
            width={'1.5rem'}
            height={'1.5rem'}
            onClick={(event) => {
              onOpenPopup(event, widget);
            }}
            className="hide-downloading"
          >
            <AIIcon />
          </Iconwpr>
        )}
        {/* <Iconwpr width={'1.5rem'} height={'1.5rem'} onClick={handleClick}>
          <ExpandIcon />
        </Iconwpr> */}
        {showChangeGraphOptions && (
          <GraphTypeBtnWrapper className="set-opacity-downloading hide-download">
            <GraphTypeBtn
              className={activeBtn === 'trendline' ? 'active' : ''}
              onClick={(e) => handleTabBtnClick(e, 'trendline')}
            >
              Trendline
            </GraphTypeBtn>
            <GraphTypeBtn
              className={activeBtn === 'bar' ? 'active' : ''}
              onClick={(e) => handleTabBtnClick(e, 'bar')}
            >
              Bar
            </GraphTypeBtn>
          </GraphTypeBtnWrapper>
        )}
        {selectedWidget?.data?.data &&
          actionOption &&
          isPopupOpen === false &&
          role !== reader && (
            <Iconwpr
              width={'1.5rem'}
              height={'1.5rem'}
              onClick={(e) => handleOptionIcon(e, 'result_over_time')}
              ref={downloadRef}
              className="hide-downloading"
            >
              <VerticleDots
                color={
                  openActionDropdown === selectedComponent
                    ? '#675ef2'
                    : '#5C5E60'
                }
              />
              <SimpleReusableDropDown
                isOpen={openActionDropdown === selectedComponent}
                options={actionDropDownOptions}
                graphDownloading={graphDownloading}
                setIsOpen={setOpenActionDropDown}
              />
            </Iconwpr>
          )}
      </IconBox>
      <SlotDetailsWrp>
        <SlotHeader className="hide-download">
          <SlotHeaderLeft>
            <SlotTitle ref={titleRef} style={{ marginLeft: '1.5rem' }}>
              {selectedWidget?.title}
            </SlotTitle>
            {/* <Tooltip content="Result over time Graph"> */}
            <span
              style={{ marginTop: '0.25rem', cursor: 'pointer' }}
              onMouseEnter={() => setChartTooltip(true)}
              onMouseLeave={() => setChartTooltip(false)}
            >
              <HelpIcon />
            </span>
            {/* </Tooltip> */}
          </SlotHeaderLeft>
          {chartTooltip && (
            <ChartToolTip
              text={helperText}
              componentTop={10}
              componentLeft={iconPosition?.left}
            />
          )}
        </SlotHeader>
        <SlotSubTitle
          style={{ marginLeft: '1.5rem' }}
          className="hide-download"
        >
          {selectedWidget?.data?.summary?.value} Results
        </SlotSubTitle>
        <SlotBody
          type={type}
          className={`${type === 'dashboard' && legend ? 'legend' : ''} ${
            type === 'dashboard' && commentary ? 'commentary' : ''
          } `}
          style={{ marginLeft: activeBtn === 'bar' ? '0rem' : '1.5rem' }}
        >
          {loader ? (
            // <Loader />
            <CircularLoading width="1.8rem" height="1.8rem" size="0.3rem" />
          ) : (
            generateGraphComponent(
              selectedWidget,
              defaultConfig,
              type,
              dashboardType,
              canvas,
              resetSelection
            )
          )}
          {/* {tabChartConfig[activeBtn] === 'area' &&
            Object.keys(tooltipEvent).length > 0 && (
              <div className="result_tooltip">
                <LineShow xAxis={tooltipEvent?.event?.offsetX + 2}></LineShow>
              </div>
            )} */}
          {tooltipEnabled && enableTooltip && (
            <PortalTooltip
              isOpen={true}
              pos={toolTipPos}
              align={toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'}
              vAlign={
                toolTipPos.top > window.innerHeight / 2 ? 'top' : 'bottom'
              }
              boxShadow="0px 8px 20px 0px rgba(0, 0, 0, 0.12)"
            >
              {' '}
              <div className="result_tooltip">
                <GraphTooltip
                  tooltipData={tooltipData}
                  type="two-d"
                  widget={selectedWidget}
                />
              </div>
            </PortalTooltip>
          )}
        </SlotBody>
        {legend && (
          <SlotFooter>
            {' '}
            <div>
              {/* <AiSection>
              <AiPurpleIcon />
            </AiSection>
            <CommentarySection>
              {!insightLoading ? (
                <CommentaryLabel>{insight?.data?.summary}</CommentaryLabel>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    minHeight: '3rem',
                    alignItems: 'center',
                  }}
                >
                  <ThreeDotsLoader />
                </div>
              )}
            </CommentarySection> */}
            </div>
            <div style={{ display: 'flex' }}>
              {selectedMedia === 'all' &&
                mediaMappings.all.map((item) => {
                  return (
                    <React.Fragment key={item.type}>
                      <LegendBox bgColor={item.color} />
                      &nbsp;
                      <LegendLabel>
                        {item.type} :{' '}
                        {summary?.[item.dataKey]
                          ? formatNumber(summary[item.dataKey])
                          : 0}
                      </LegendLabel>
                      <LegendLabel> &nbsp; | &nbsp;</LegendLabel>
                    </React.Fragment>
                  );
                })}
              {selectedMedia === 'traditional' && (
                <p
                  style={{
                    color: coolGrayColorGradients.coolGray60,
                    fontSize: '11px',
                    margin: 0,
                    padding: 0,
                    fontWeight: 500,
                  }}
                >
                  Trendline for traditional channels
                </p>
              )}
              {selectedMedia === 'social' && (
                <p
                  style={{
                    color: coolGrayColorGradients.coolGray60,
                    fontSize: '11px',
                    margin: 0,
                    padding: 0,
                    fontWeight: 500,
                  }}
                >
                  Trendline for social channels
                </p>
              )}
            </div>
          </SlotFooter>
        )}
        {isPopupOpen && (
          <div style={{ paddingTop: '8px' }}>
            <Prompt
              onPromptSubmit={(prompt) => {
                onPromptSubmit({
                  prompt,
                  graphId: widgetClassName,
                });
              }}
              data={promptData[widgetClassName]}
              title={'Results Over Time'}
            />
          </div>
        )}
      </SlotDetailsWrp>
    </>
  );

  return (
    <SlotDetailsMainWrp
      ref={containerRef}
      className={widgetClassName + ' card'}
    >
      {graph}
      {isPopupOpen && (
        <EnlargedCard
          top={'20%'}
          left={'30px'}
          width={'95%'}
          height={'548px'}
          setShow={setIsPopupOpen}
          animationDirection={cardData?.direction}
        >
          {graph}
        </EnlargedCard>
      )}
    </SlotDetailsMainWrp>
  );
};

ResultOverTime.defaultProps = {
  type: 'dashboard',
};

ResultOverTime.propTypes = {
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  dashboardType: Proptypes.string,
  canvas: Proptypes.bool,
  legend: Proptypes.bool,
  commentary: Proptypes.bool,
  actionOption: Proptypes.bool,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  handleUpdatedChart: Proptypes.func,
  showChangeGraphOptions: Proptypes.bool,
  downloadFunction: Proptypes.func,
  setSelectedComponent: Proptypes.func,
  graphDownloading: Proptypes.bool,
  widgetClassName: Proptypes.string,
  helperText: Proptypes.string,
  onDownloadChartData: Proptypes.func,
  selectedComponent: Proptypes.string,
  onPromptSubmit: Proptypes.func,
  promptData: Proptypes.object,
};

const AIIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18018_10461)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2308 24H-3.23047C-0.350515 19.7745 4.50082 17 10.0002 17C15.4995 17 20.3509 19.7745 23.2308 24Z"
          fill="#878D96"
        />
        <path
          d="M15 1L15.0976 1.39546C15.4028 2.63182 16.3682 3.59716 17.6045 3.90237L18 4L17.6045 4.09763C16.3682 4.40284 15.4028 5.36818 15.0976 6.60454L15 7L14.9024 6.60454C14.5972 5.36818 13.6318 4.40284 12.3955 4.09763L12 4L12.3955 3.90237C13.6318 3.59716 14.5972 2.63182 14.9024 1.39546L15 1Z"
          fill="#878D96"
        />
        <path
          d="M8 3L8.66678 5.70096C8.98726 6.99914 10.0009 8.01274 11.299 8.33322L14 9L11.299 9.66678C10.0009 9.98726 8.98726 11.0009 8.66678 12.299L8 15L7.33322 12.299C7.01274 11.0009 5.99914 9.98726 4.70096 9.66678L2 9L4.70096 8.33322C5.99914 8.01274 7.01274 6.99914 7.33322 5.70096L8 3Z"
          fill="#878D96"
        />
      </g>
      <defs>
        <clipPath id="clip0_18018_10461">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ResultOverTime;
